import "./Profile.css"
import Sidebar from "./components/Sidebar";
import Headings from "./components/Headings";
import ActionCard from "./components/ActionCard";
import Footer from "../../components/Footer";
import person from "./../../assets/icons/person-gold.svg";
import { useAuth0 } from "@auth0/auth0-react";
import postLoadFetchData from "../../utils/postLoadFetchData";
import { useState, useEffect, createContext, useContext } from "react";
import postLoadModifyData from "../../utils/postLoadModifyData";
import { Link, useNavigate } from "react-router-dom"
const cake = require('../../assets/icons/profile/cake.svg').default;
const envelope = require('../../assets/icons/profile/envelope.svg').default;
const location = require('../../assets/icons/profile/location.svg').default;
const people = require('../../assets/icons/profile/people.svg').default;
const phone = require('../../assets/icons/profile/phone.svg').default;
const employment = require('../../assets/icons/profile/employment.svg').default;
const gig = require('../../assets/icons/profile/gig.svg').default;
const experience = require('../../assets/icons/profile/experience.svg').default;
const property = require('../../assets/icons/profile/property.svg').default;
const services = require('../../assets/icons/profile/services.svg').default;
const checkbox_false = require('../../assets/icons/profile/checkbox-false.png');
const checkbox_true = require('../../assets/icons/profile/checkbox-true.png');
const spinner = require('../../assets/icons/spinner.png');

const UserContext = createContext()

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
    apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
    apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local') {
    apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

const LoadProfile = () => {
    const email = useContext(UserContext);
    const { user, isAuthenticated } = useAuth0();

    // Post data on button click END
    if (!isAuthenticated) return <p>Loading profile...</p>
    // Check that state is not null
    if (isAuthenticated) {
        return (
            isAuthenticated && <>
                <div>
                    <Sidebar user={user}></Sidebar>
                    <div className="app-container">
                        <div className="lg:w-[1020px] w-full mx-auto">
                            <Headings title='Profile ' icon={person} />
                            <Content email={email}></Content>
                        </div>

                    </div>
                </div>
                <Footer></Footer>
            </>

        )
    }

}

const Content = () => {
    const email = useContext(UserContext);
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const baseURL = apiBaseURL;
    const [isLoading, setIsLoading] = useState(true);
    const [financialData, setFinancialData] = useState();
    const [addressData, setAddressData] = useState();
    const [notificationsData, setNotificationsData] = useState();
    const [editProfile, setEditProfile] = useState(false);
    const putUrl = `${baseURL}client_extras/client_settings/?email=${email}`;
    const profileUrl = `${baseURL}user_profiles/user_and_address/`
    const stripeUrl = `${baseURL}subscription/billing_settings?email=${email}&mode=payment_method_update`
    const cancelUrl = `${baseURL}subscription/billing_settings?email=${email}&mode=subscription_cancel`
    const navigate = useNavigate();
    //Profile Form
    const [phoneNumber, setPhoneNumber] = useState();
    const [streetAddress, setStreetAddress] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();
    const [zip, setZip] = useState();
    const [kidCount, setKidCount] = useState(0);
    const [stripePromise, setStripePromise] = useState(null);
    // Start the process by getting the token
    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently();
                const urlProfile = `${baseURL}financial_discovery/financial_profile/?email=${email}&mode=profile`;
                const urlUserAddress = `${baseURL}user_profiles/user_and_address/?email=${email}`;
                const urlNotifications = `${baseURL}client_extras/client_settings/?email=${email}`;
                // console.log("Got new token for function: " + token)
                // Execute the api method by calling the function
                const userProfile = await postLoadFetchData(urlProfile, token);
                const userAddress = await postLoadFetchData(urlUserAddress, token);
                const userNotifications = await postLoadFetchData(urlNotifications, token);
                setFinancialData(userProfile);
                setAddressData(userAddress);
                setPhoneNumber(userAddress.user.phone_number);
                setStreetAddress(userAddress.user_address.street);
                setCity(userAddress.user_address.city);
                setState(userAddress.user_address.state);
                setZip(userAddress.user_address.zip_code);
                setNotificationsData(userNotifications);
                setKidCount(userAddress.user.kids)
                console.log('data: ', financialData);
                console.log('user', user);
                console.log('userAddress', userAddress)
                console.log('notifs', userNotifications);
            } catch (err) {
                console.log(err);
            }
        })();
    }, []);
    // Post data on button click
    const putOnClickNotif = async (target) => {

        let payload = {
            "user_id": notificationsData.user_id,
            "notification_new_resources": notificationsData.notification_new_resources,
            "notification_account_activity": notificationsData.notification_account_activity,
            "notification_offers_and_marketing": notificationsData.notification_offers_and_marketing,
            "receive_notification_method": "text"
        }
        console.log(payload);
        switch (target) {
            case 'notification_new_resources':
                payload.notification_new_resources = !payload.notification_new_resources;
                break;
            case 'notification_account_activity':
                payload.notification_account_activity = !payload.notification_account_activity;
                break;
            case 'notification_offers_and_marketing':
                payload.notification_offers_and_marketing = !payload.notification_offers_and_marketing;
                break;
            case 'email':
                payload.receive_notification_method = 'email';
                break;
            case 'text':
                payload.receive_notification_method = 'text';
                break;
        }
        console.log(payload, target);
        setNotificationsData(payload);
        console.log(notificationsData);
        try {
            const token = await getAccessTokenSilently();
            // console.log("Got new token for function: " + token)
            // Execute the api method by calling the function
            const response = await postLoadModifyData(putUrl, payload, token);
            console.log(response);
        } catch (err) {
            console.log(err);
        }

    }

    // Post data on button click
    const putOnClickProfile = async () => {
        let payload = {
            "name": addressData.user.name,
            "lastname": addressData.user.lastname,
            "email": addressData.user.email,
            "birth_date": addressData.user.birth_date,
            "phone_number": phoneNumber,
            "kids": kidCount,
            "street": streetAddress,
            "city": city,
            "state": state,
            "country": addressData.user_address.country,
            "zip_code": zip,
            "userID": addressData.user_address.userID,
        }
        console.log(payload);
        try {
            const token = await getAccessTokenSilently();
            // console.log("Got new token for function: " + token)
            // Execute the api method by calling the function
            const response = await postLoadModifyData(profileUrl, payload, token);
            console.log(response);
        } catch (err) {
            console.log(err);
        }
        setEditProfile(!editProfile);
    }

    // Edit Stripe

    async function handleStripe() {
        const token = await getAccessTokenSilently();
        let options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        };
        fetch(stripeUrl, options)
            .then((response) => response.json())
            .then((json) => window.location.href = json.response);
        //window.location.href = response.response
    }

    async function handleCancel() {
        const token = await getAccessTokenSilently();
        let options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        };
        fetch(cancelUrl, options)
            .then((response) => response.json())
            .then((json) => window.location.href = json.response);
        //window.location.href = response.response
    }


    // Post data on button click END

    if (!financialData | !addressData) return <>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <img src={spinner} className="animate-spin mx-auto" alt="Loading"></img>
    </>

    // Check that state is not null
    if (financialData && addressData) {
        return (
            isAuthenticated && <>
                <div className="flex lg:flex-row flex-col lg:mx-0 mx-[21px]">
                    {editProfile ?
                        <ActionCard internalMargin="mt-[10px]" displayCustomNeutral="hidden" displayNeutral="hidden" title='Personal Information' width='lg:w-[388px] w-full' addClass='lg:mt-[60px] mt-[21px]' displayBrand='hidden' onClickEventNeutral={() => setEditProfile(!editProfile)}>
                            <button className="small-gold-button ml-[15px] mb-[24px]" onClick={() => putOnClickProfile()}>Save Edits</button>
                            <form>
                                <div className="flex flex-col">
                                    <div className="self-center mb-[15px]">
                                        <img src={user.picture || person} alt='Profile Picture' className='inline w-[104px] h-[104px] rounded-full' />
                                        <div className="inline-block pl-[30px] max-w-[199px]">
                                            <p className="accent-6 overflow-hidden whitespace-nowrap">{user.name}</p>
                                            <p className="paragraph-1-mobile neutral-600">Member since {new Date(addressData.user.created_at).toLocaleDateString('en-us', { year: "numeric" })}</p>
                                        </div>
                                    </div>
                                    <div className="flex items-center my-[15px] max-w-[328px]">
                                        <img src={phone} className='w-[50px] h-[50px] inline' alt='Phone'></img>
                                        <input className="paragraph-1 pl-[15px] profile-input" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}></input>
                                    </div>
                                    <div className="flex items-center my-[15px] max-w-[328px]">
                                        <img src={envelope} className='w-[50px] h-[50px] inline' alt='Email'></img>
                                        <p className="inline pl-[15px] paragraph-1 overflow-hidden whitespace-wrap neutral-500">{user.email}</p>
                                    </div>
                                    <div className="flex items-center my-[15px] max-w-[328px]">
                                        <img src={location} className='w-[50px] h-[50px] inline' alt='Location'></img>
                                        <p className="inline pl-[15px] paragraph-1 overflow-hidden whitespace-wrap">
                                            <input className="paragraph-1 pl-[15px] profile-input" value={streetAddress} onChange={(e) => setStreetAddress(e.target.value)}></input>
                                            <br></br>
                                            <input className="paragraph-1 pl-[15px] profile-input" value={city} onChange={(e) => setCity(e.target.value)} />
                                            <input className="paragraph-1 pl-[15px] profile-input" value={state} onChange={(e) => setState(e.target.value)} />
                                            <input className="paragraph-1 pl-[15px] profile-input" value={zip} onChange={(e) => setZip(e.target.value)} />
                                        </p>
                                    </div>
                                    <div className="flex items-center my-[15px] max-w-[328px]">
                                        <img src={cake} className='w-[50px] h-[50px] inline' alt='Birthday'></img>
                                        <p className="inline pl-[15px] paragraph-1 overflow-hidden whitespace-wrap neutral-500">{new Date(addressData.user.birth_date.replace(/-/g, '\/')).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}</p>
                                    </div>
                                    <div className="flex items-center my-[15px] max-w-[328px]">
                                        <img src={people} className='w-[50px] h-[50px] inline' alt='Children'></img>
                                        <button disabled={!(kidCount >= 1)} type='button' className='ml-[15px] function-button inline' onClick={() => setKidCount(kidCount - 1)}>-</button>
                                        <p className="inline px-[15px] paragraph-1 overflow-hidden whitespace-wrap">{kidCount}</p>
                                        <button type='button' className='function-button inline' onClick={() => setKidCount(kidCount + 1)}>+</button>
                                    </div>

                                </div>
                            </form>
                        </ActionCard> :
                        <ActionCard displayNeutral="hidden" title='Personal Information' width='lg:w-[388px] w-full' addClass='lg:mt-[60px] mt-[21px]' customNeutralText='Edit' displayBrand='hidden' onClickEventNeutral={() => setEditProfile(!editProfile)}>
                            <div className="flex flex-col">
                                <div className="self-center mb-[15px]">
                                    <img src={user.picture || person} alt='Profile Picture' className='inline w-[104px] h-[104px] rounded-full' />
                                    <div className="inline-block pl-[30px] max-w-[199px]">
                                        <p className="accent-6 overflow-hidden whitespace-nowrap">{user.name}</p>
                                        <p className="paragraph-1-mobile neutral-600">Member since {new Date(addressData.user.created_at).toLocaleDateString('en-us', { year: "numeric" })}</p>
                                    </div>
                                </div>
                                <div className="flex items-center my-[15px] max-w-[328px]">
                                    <img src={phone} className='w-[50px] h-[50px] inline' alt='Phone'></img>
                                    <p className="inline pl-[15px] paragraph-1 overflow-hidden whitespace-wrap">{phoneNumber}</p>
                                </div>
                                <div className="flex items-center my-[15px] max-w-[328px]">
                                    <img src={envelope} className='w-[50px] h-[50px] inline' alt='Email'></img>
                                    <p className="inline pl-[15px] paragraph-1 overflow-hidden whitespace-wrap">{user.email}</p>
                                </div>
                                <div className="flex items-center my-[15px] max-w-[328px]">
                                    <img src={location} className='w-[50px] h-[50px] inline' alt='Location'></img>
                                    <p className="inline pl-[15px] paragraph-1 overflow-hidden whitespace-wrap">{streetAddress}<br></br>{city}, {state} {zip}</p>
                                </div>
                                <div className="flex items-center my-[15px] max-w-[328px]">
                                    <img src={cake} className='w-[50px] h-[50px] inline' alt='Birthday'></img>
                                    <p className="inline pl-[15px] paragraph-1 overflow-hidden whitespace-wrap">{new Date(addressData.user.birth_date.replace(/-/g, '\/')).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}</p>
                                </div>
                                <div className="flex items-center my-[15px] max-w-[328px]">
                                    <img src={people} className='w-[50px] h-[50px] inline' alt='Children'></img>
                                    <p className="inline pl-[15px] paragraph-1 overflow-hidden whitespace-wrap">{`${kidCount>0?kidCount:"No"} child${kidCount===1?"":"ren"}`}</p>
                                </div>

                            </div>
                        </ActionCard>}
                    <ActionCard displayCustomNeutral="hidden" title='Settings' width='lg:w-[542px] w-full' addClass='lg:mt-[60px] lg:ml-[30px] mt-[30px] flex flex-col' displayNeutral='hidden' displayBrand='hidden'>
                        <p className="paragraph-1-bold lg:ml-[30px]">Account</p>
                        <div className="lg:mt-[15px] mb-[40px] lg:ml-[26px]">
                            <a className="inline text-right-button ml-[34px]" href="mailto:hello@kairos.financial">Delete My Account</a>
                        </div>
                        <p className="paragraph-1-bold mb-[30px] lg:ml-[30px]">Notifications</p>
                        <div className="mb-[30px] lg:ml-[60px]">
                            <img src={notificationsData.notification_new_resources ? checkbox_true : checkbox_false} className="inline mr-[15px] cursor-pointer" onClick={() => putOnClickNotif('notification_new_resources')} /><p className="inline paragraph-1">New resources available</p>
                        </div>
                        <div className="mb-[30px] lg:ml-[60px]">
                            <img src={notificationsData.notification_account_activity ? checkbox_true : checkbox_false} className="inline mr-[15px] cursor-pointer" onClick={() => putOnClickNotif('notification_account_activity')} /><p className="inline paragraph-1">Account activity</p>
                        </div>
                        <div className="lg:ml-[60px]">
                            <img src={notificationsData.notification_offers_and_marketing ? checkbox_true : checkbox_false} className="inline mr-[15px] cursor-pointer" onClick={() => putOnClickNotif('notification_offers_and_marketing')} /><p className="inline paragraph-1">Offers and marketing</p>
                        </div>
                        <p className="paragraph-1-bold mt-[45px] mb-[30px] lg:ml-[30px]">Receive via:</p>
                        <img src={(notificationsData.receive_notification_method === 'email') ? checkbox_true : checkbox_false} className="inline ml-[60px] mr-[15px] cursor-pointer" onClick={() => putOnClickNotif('email')} /><p className="inline paragraph-1">Email</p>
                        <img src={(notificationsData.receive_notification_method === 'text') ? checkbox_true : checkbox_false} className="inline ml-[45px] mr-[15px] cursor-pointer" onClick={() => putOnClickNotif('text')} /><p className="inline paragraph-1">Text</p>
                    </ActionCard>
                </div>
                <div className="my-[60px] text-center">
                    <p className="accent-1-to-mobile px-[25px] lg:px-0 max-w-[850px] mx-auto mb-[30px]">We’ll always check in with you every three to six months to make sure we have the most current information about your financial situation. If anything changes in the meantime, click here to let us know more! </p>
                    <Link className="callout-brand-button mx-auto" to="/onboarding/personal-info">Update My Financial Information</Link>
                </div>
                {/*                 <ActionCard displayCustomNeutral="hidden" title='Financial Situation Information' width='w-[898px]' addClass='mt-[30px]' neutralText='Edit' neutralLink='personal-info' brandText='View My Goals' brandLink='your-goals'>
                    <div className="flex flex-col pl-[10px]">
                        <div className="flex items-center mt-[15px] max-w-[828px]">
                            <img src={employment} className='w-[40px] h-[40px] inline' alt='Employment Status'></img>
                            <p className="paragraph-1-bold inline pl-[15px]">Employment Status:</p><p className="inline pl-[10px] paragraph-1-mobile overflow-hidden whitespace-wrap">{financialData.financial_profile.financial_summary.employee_status}</p>
                        </div>
                        <div className="flex items-center mt-[15px] max-w-[828px]">
                            <img src={gig} className='w-[40px] h-[40px] inline' alt='Gig Work Type'></img>
                            <p className="paragraph-1-bold inline pl-[15px]">Gig Work Type:</p><p className="inline pl-[10px] paragraph-1-mobile overflow-hidden whitespace-wrap">{financialData.financial_profile.financial_summary.gig_work}</p>
                        </div>
                        <div className="flex items-center mt-[15px] max-w-[828px]">
                            <img src={experience} className='w-[40px] h-[40px] inline' alt='Experience Managing Finances'></img>
                            <p className="paragraph-1-bold inline pl-[15px]">Experience Managing Finances:</p><p className="inline pl-[10px] paragraph-1-mobile overflow-hidden whitespace-wrap">{financialData.financial_profile.financial_summary.self_assessment}</p>
                        </div>
                        <div className="flex items-center mt-[15px] max-w-[828px]">
                            <img src={property} className='w-[40px] h-[40px] inline' alt='Property'></img>
                            <p className="paragraph-1-bold inline pl-[15px]">Property:</p><p className="inline pl-[10px] paragraph-1-mobile overflow-hidden whitespace-wrap">{financialData.financial_profile.financial_summary.owner_status}</p>
                        </div>
                        <div className="flex items-center mt-[15px] max-w-[828px]">
                            <img src={services} className='w-[40px] h-[40px] inline' alt='Current Financial Services'></img>
                            <p className="paragraph-1-bold inline pl-[15px]">Current Financial Services:</p><p className="inline pl-[10px] paragraph-1-mobile overflow-hidden whitespace-wrap">{financialData.financial_profile.financial_services.toString().replaceAll(',', ', ')}</p>
                        </div>
                    </div>
                </ActionCard> */}
            </>

        )
    }

    // End process
    isLoading && <div>Loading...</div>

}

const Profile = () => {
    const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
    // Check if authenticated
    if (isAuthenticated) {
        // Show the launch button if Plaid Link is ready and public token was fetched
        return <UserContext.Provider value={user.email}><LoadProfile /></UserContext.Provider>
    }
    else {
        return "Loading...";
    }
}

export default Profile