import React from 'react';
import savings from './../assets/lineart/savings.png';
import insurance from './../assets/lineart/insurance.png';
import investment from './../assets/lineart/investment.png';
import loan from './../assets/lineart/loan.png';
import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useState, createContext } from "react"
import postLoadFetchData from '../utils/postLoadFetchData';
import postLoadDeleteData from '../utils/postLoadDeleteData';
import PlaidUpdateRemove from './PlaidUpdateRemove';
import { useNavigate } from 'react-router-dom';
const spinner = require('../assets/icons/spinner.png');
const trashcan = require('../assets/icons/trashcan.svg').default;

const UserContext = createContext()


function showAsCurrency(amount) {
  return amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
}

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local') {
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

function LoadConnectAccounts() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const email = useContext(UserContext);
  const [allConnectedAccounts, setAllConnectedAccounts] = useState(null);
  const baseURL = apiBaseURL;
  const url = `${baseURL}connections/accounts?email=${email}`;
  const deleteurl = `${baseURL}connections/plaid_token_delete`
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        // console.log("Got new token for function: " + token)
        // Execute the api method by calling the function
        const accounts = await postLoadFetchData(url, token);
        setAllConnectedAccounts(accounts);
        console.log('account from connected accounts: ', accounts)
        console.log('user', user)
      } catch (err) {
        console.log(err)
      }
    })();
  }, []);

  // Delete Token
  const deleteToken = async (item_id) => {
    let payload = {
      "email": email,
      "item_id": item_id
    }
    console.log(payload);
    try {
      const token = await getAccessTokenSilently();
      // console.log("Got new token for function: " + token)
      // Execute the api method by calling the function
      const response = await postLoadDeleteData(deleteurl, payload, token);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  }

  // Open confirmation modal
  const [confirmationIsOpen, setconfirmationIsOpen] = React.useState(false);

  function confirmDelete() {
    setconfirmationIsOpen(true);
  }

  function closeConfirmation() {
    setconfirmationIsOpen(false);
  }


  if (!allConnectedAccounts) return <div><img src={spinner} className="animate-spin mx-auto" alt="Loading"></img></div>; // Todo: Add a loading component

  if (allConnectedAccounts.status === 500 || allConnectedAccounts.status === 400) { // Todo: Add more details about the error
    return (
      <div>
        <p className='accent-5'>Connected Accounts</p>
        <button className='text-right-button ml-[10px] mb-[45px]' onClick={() => navigate('/onboarding/connect-accounts')}>Add Another Account</button>
        <div className='text-center'>An error has occurred in Plaid while retrieving financial data.
          We'll gather more details to fix this ASAP.</div>
      </div>
    )
    } else if (!!allConnectedAccounts.message) {
      return (
        // loop through props.data.accounts and display each item using map
        <div className='bg-gold-20 mx-auto flex flex-col items-center justify-center py-[60px] text-center'>
    
          <p className='h3-bold max-w-[644px]'>Connect your existing financial accounts to see insights into your net worth here!</p>
          <p className='paragraph-1-mobile mt-[30px] max-w-[303px]'>Connect as many financial accounts as you’re comfortable with <span className='brand-gold cursor-pointer' onClick={()=>{navigate("/onboarding/connect-accounts")}}>here</span>.</p>
        </div>
    
      );
    }
  


}

const ConnectAccounts = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  isAuthenticated && console.log(user);
  if (isLoading) return <div>Loading...</div>; // Todo: Add a loading component

  // Redirect to home if not authenticated
  !isLoading && !isAuthenticated && loginWithRedirect()

  return (
    // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
    isAuthenticated && <UserContext.Provider value={user.email}><LoadConnectAccounts email={user.email} /></UserContext.Provider>
  )
}


export default ConnectAccounts;