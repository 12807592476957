import Sidebar from "./components/Sidebar"
import Headings from "./components/Headings"
import UserCard from "./components/UserCard"
import Footer from "../../components/Footer"
import people from "./../../assets/icons/people-gold.svg"
import { useAuth0 } from "@auth0/auth0-react";
import { createContext, useContext, useEffect, useState } from "react"
import postLoadFetchData from "../../utils/postLoadFetchData"
import postLoadModifyData from "../../utils/postLoadModifyData"
import postLoadSaveData from "../../utils/postLoadSaveData"
const spinner = require('../../assets/icons/spinner.png');

const UserContext = createContext()

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
    apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
    apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local') {
    apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

const LoadAdvising = () => {
    const email = useContext(UserContext);
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

    // Post data on button click END

    if (!isAuthenticated) return <p>Loading 1-on-1 Consulting...</p>

    // Check that state is not null
    if (isAuthenticated) {
        return (
            isAuthenticated && <>
                <div>
                    <Sidebar user={user}></Sidebar>
                    <UserCard user={user} />
                    <div className="app-container">
                        <div className="lg:w-[1020px] lg:mx-auto w-full px-[20px] lg:px-0">
                            <Headings title='1-on-1 Consulting' icon={people} />
                            <p className="ml-[105px] mt-[35px] lg:mr-[60px] mb-[45px] paragraph-1">
                                Kairos offers complimentary 1-on-1 advising to all of our members, regardless of age, income, or financial status. Use the button below to book your free advising session<span className="inline lg:hidden"> and keep your notes here for easy access!</span>
                                <span className="hidden lg:inline">.<br></br><br></br>The notes section below can hold questions you want to ask your advisor, notes from your session, or anything else you’d like to write down. We’ll save anything you write here for you to come back and reference any time.</span>
                            </p>
                            <div className="lg:w-full w-4/5 mx-auto flex mb-[60px]">
                                    <a className="callout-brand-button mx-auto" href="https://calendly.com/jw-bayfront-ventures/15min?month=2023-01" target="_blank">Book My Free Call</a>


                            </div>
                            <div className={`p-[50px] shadow-[0px_3px_12px_rgba(0,0,0,0.08)] rounded-[20px] w-full`}>
                                <p className="accent-5">My Notes</p>
                                <Notes email={email}></Notes>
                            </div>
                        </div>

                    </div>
                </div>
                <Footer></Footer>
            </>

        )
    }

}

const Notes = () => {
    const email = useContext(UserContext);
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const baseURL = apiBaseURL;
    const [isLoading, setIsLoading] = useState(true);
    const [notesData, setNotesData] = useState();
    const [done, setDone] = useState();
    const putUrl = `${baseURL}client_extras/advising/?email=${email}`;
    // Start the process by getting the token
    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently();
                const url = `${baseURL}client_extras/advising/?email=${email}`;
                // console.log("Got new token for function: " + token)
                // Execute the api method by calling the function
                const notes = await postLoadFetchData(url, token);
                setNotesData(notes ? notes.notes : undefined);
                console.log('notes', notes);
                setDone(true);
            } catch (err) {
                console.log(err);
                setDone(true);
            }
        })();
    }, []);
    // Post data on button click
    const putOnClick = async (input) => {
        if (input == '') {
            input = ' ';
        }
        console.log(notesData)
        setNotesData(input);
        let payload = {
            "email": email,
            "notes": input,
            "date": "2023-02-28"
        }
        console.log(payload);
        try {
            const token = await getAccessTokenSilently();
            // console.log("Got new token for function: " + token)
            // Execute the api method by calling the function
            const response = await postLoadModifyData(putUrl, payload, token);
            console.log(response);
        } catch (err) {
            console.log(err);
        }

    }

    // Post data on button click END

    // Post data on button click
    const postOnClick = async (input) => {
        setNotesData(input);
        const payload = {
            "email": email,
            "notes": input,
            "date": "2023-02-28"
        };
        try {
            const token = await getAccessTokenSilently();
            // console.log("Got new token for function: " + token)
            // Execute the api method by calling the function
            const response = await postLoadSaveData(putUrl, payload, token);
            console.log(response);
        } catch (err) {
            console.log(err);
        }

    }

    // Post data on button click END

    if (!done) return <div className="w-[920px] h-[265px]"><img src={spinner} className="animate-spin mx-auto" alt="Loading"></img></div>

    // Check that state is not null
    if (done) {
        console.log('NOTES', notesData)
        return (
            isAuthenticated && <>
                <textarea id="notes" name="notes" rows="10" className="blank-textarea w-full pt-[25px] paragraph-1" placeholder="Start adding in notes" value={`${notesData ? notesData : ''}`} onChange={(event) => { (!notesData) ? postOnClick(event.target.value) : putOnClick(event.target.value) }}></textarea>
            </>

        )
    }

    // End process
    isLoading && <div>Loading...</div>

}

const Advising = () => {
    const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
    // Check if authenticated
    if (isAuthenticated) {
        // Show the launch button if Plaid Link is ready and public token was fetched
        return <UserContext.Provider value={user.email}><LoadAdvising /></UserContext.Provider>
    }
    else {
        return "Loading...";
    }
}

export default Advising