//const placeholder600 = require('../../assets/rectangle2604093-f1uo-600h.png')
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from 'react'

const LoggingOut = () => {
const navigate = useNavigate();
const { loginWithRedirect, logout} = useAuth0();

useEffect(() => {
  setTimeout(() => {
    navigate('/')
  }, 3000)
}, [])

  return (
    <>
    <p></p>
      <div className='large-container bg-white'>
        <div className="text-center py-4 flex flex-col items-center">

            <h1 className="big-header mt-[97px]">See ya!</h1>
            <h2 className="h2-thinner mt-[15px]">Logging you out...</h2>
            <p className="paragraph-4 neutral-600 max-w-[1063px] mt-[30px]">You’ll be redirected to the home page. Thanks for hanging out with us!</p>
            <button className="callout-brand-button mt-[60px]" onClick={() => loginWithRedirect()}>Oops... I want to log back in</button>
          

        </div>
      </div>

    </>
  )
}

export default LoggingOut