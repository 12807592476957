import "./navbar.css"
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
const logo = require('../assets/logo-color.png');
const menu = require('../assets/common/menu.svg').default;
const close = require('../assets/common/close.svg').default;

const running_env = process.env.REACT_APP_RUNNING_ENV;

const domain = running_env === 'development' || running_env === 'local' ? process.env.REACT_APP_AUTH0_DOMAIN_DEV : process.env.REACT_APP_AUTH0_DOMAIN_PROD;
//const isProduction = running_env === 'development' || running_env === 'local' ? false : true;

const Navbar = () => {
    const navigate = useNavigate();
    const { loginWithRedirect, logout, isAuthenticated } = useAuth0();


    // Check if access code was used. Remove when the feature is not required
    const wasAccessCodeFound = localStorage.getItem('accessCodeIsValid');

    const [navbar, setNavbar] = useState(false);

    return (
        <>
            <nav className="main-header w-full">
                <div className="justify-between lg:max-w-7xl lg:items-center lg:flex lg:px-8 mx-auto">
                    <div className="relative z-40 shadow-[0_4px_6px_0px_rgba(0,0,0,0.04)] lg:shadow-none">
                        <div className="flex items-center justify-between py-[10px] pr-[30px] pl-[20px] lg:py-0 lg:block">
                            <img src={logo} className="to-link lg:h-[76px] h-[33px]" alt="KairosLogo" onClick={() => navigate("/home")} />

                            <div className="flex flex-row space-x-[22px]">
                                {isAuthenticated ? <>
                                    <button className="lg:hidden inline nav-gold-button relative" onClick={() => navigate("/dashboard")}>Dashboard</button>
                                </> : <>
                                    <button className="lg:hidden inline nav-gold-button relative" onClick={() => loginWithRedirect({
                                        screen_hint: 'signup',
                                        audience: `https://${domain}/api/v2/`,
                                        scope: "profile", // new
                                    })}>Sign Up</button>

                                </>}
                                <div className="lg:hidden">
                                    <button
                                        className="p-2 text-gray-700 rounded-md outline-none"
                                        onClick={() => setNavbar(!navbar)}
                                    >
                                        {navbar ? (
                                            <img src={close} className="w-6 h-6 text-white" alt="Close"></img>
                                        ) : (
                                            <img src={menu} className="w-6 h-6 text-white" alt="Menu"></img>
                                        )}
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="relative z-0">
                        <div
                            className={`flex-1 justify-self-center pb-3 lg:block lg:pb-0 ${navbar ? "block bg-white-to-gold-solid" : "hidden"}`}>
                            <ul className="nav-links pt-8 lg:pt-0 items-center justify-center space-y-8 lg:flex lg:space-y-0 mb-3 lg:mb-0 pr-[60px] lg:pr-0">
                                <li className="inline-block lg:hidden"><button className="to-link" onClick={() => {navigate("/");setNavbar(!navbar)}}>Home</button></li>
                                <li><button className="to-link lg:mr-[78px]" onClick={() => {navigate("/company");setNavbar(!navbar)}}>Company</button></li>
                                <li><button className="to-link" onClick={() => {navigate("/faq");setNavbar(!navbar)}}>FAQ</button></li>
                                {isAuthenticated ? <>
                                    <li className="inline-block lg:hidden"><button className="text-white" onClick={() => {setNavbar(!navbar);logout({ returnTo: window.location.origin + '/loggedout' })}}>Log Out</button></li>
                                </> : <>
                                    <li className="inline-block lg:hidden"><button className="text-white" onClick={() => {loginWithRedirect();setNavbar(!navbar)}}>Log In</button></li>
                                </>}

                            </ul>
                        </div>
                    </div>
                    <div className="hidden space-x-2 lg:inline-block">
                        {isAuthenticated ? <>
                            <button className="px-4 py-2 nav-gold-button" onClick={() => {setNavbar(!navbar);navigate("/dashboard")}}>Dashboard</button>
                        </> : <>
                            <button className="py-2 link-button mr-6" onClick={() => {setNavbar(!navbar);loginWithRedirect()}}>Log In</button>
                            <button className="px-4 py-2 nav-gold-button" onClick={() => {setNavbar(!navbar);loginWithRedirect({
                                screen_hint: 'signup',
                                audience: `https://${domain}/api/v2/`,
                                scope: "profile", // new
                            })}}>Sign Up</button>
                        </>}

                    </div>
                </div>
            </nav>
        </>
    );
};
export default Navbar;