import Sidebar from "./components/Sidebar"
import Headings from "./components/Headings"
import UserCard from "./components/UserCard"
import Footer from "../../components/Footer"
import accounts from "./../../assets/icons/accounts-gold.svg"
import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useState, createContext } from "react"
import postLoadFetchData from "../../utils/postLoadFetchData"
import { oembed } from "@loomhq/loom-embed"
import { useLocation, useNavigate, useParams } from "react-router-dom"
const spinner = require('../../assets/icons/spinner.png');

const UserContext = createContext()

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local') {
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}


function Video(props) {
  const style = {
    position: "absolute",
    top: "0",
    left: "0",
    width: ""
  }
  return (<>
    <iframe src={props.videoUrl + "?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen className={props.iframeClass}></iframe>
  </>)
}

function LoadProductMatches() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const email = useContext(UserContext);
  const location = useLocation();
  let bucket = location.state;
  const navigate = useNavigate();
  const [productMatches, setProductMatches] = useState(null);
  const baseURL = apiBaseURL;
  const urlproduct = `${baseURL}product_catalog/matching_products/?email=${email}`;

  // If no specific bucket was selected, redirect to hierarchy page
  useEffect(() => {
    if (!bucket) {
      navigate("/finstack");
    } else {
      (async () => {
        try {
          const token = await getAccessTokenSilently();
          // console.log("Got new token for function: " + token)
          // Execute the api method by calling the function
          const products = await postLoadFetchData(urlproduct, token);
          setProductMatches(products);
          console.log('data: ', products)
          console.log('url', products?.hierarchy.find(x => bucket === x.bucket_name).loom_video_url)
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [])

  if (!isAuthenticated) return <div>Loading product matches...</div>; // Todo: Add a loading component

  return (
    (user && productMatches && isAuthenticated) && <>
      <div>
        <Sidebar user={user}></Sidebar>
        <UserCard user={user} />
        <div className="app-container">
          <div className="lg:w-[1020px] lg:mx-auto w-full px-[20px] lg:px-0">
            <div className="mt-[30px] flex items-center">
              <div className="pl-[25px] relative">
                <h2>{productMatches?.hierarchy.find(x => bucket === x.bucket_name).display_name ? productMatches?.hierarchy.find(x => bucket === x.bucket_name).display_name : "UNKNOWN"}</h2>
                <div className="absolute bottom-[-15px] w-[150px] h-[2px] bg-gold"></div>
              </div>
            </div>
            <p className="ml-[25px] mt-[35px] lg:mr-[60px] mb-[45px] paragraph-1">
              {productMatches.hierarchy.find(x => bucket === x.bucket_name).description}
            </p>
            <div className="mx-auto text-center">
              <div className="overflow-hidden rounded-[10px] bg-neutral-25 mb-[60px] inline-block">
                {productMatches?.hierarchy.find(x => bucket === x.bucket_name).loom_video_url ?
                  <>
                    <Video iframeClass="bucketsIframe" videoUrl={productMatches?.hierarchy.find(x => bucket === x.bucket_name).loom_video_url} ></Video>
                  </> : <></>}

              </div>
            </div>
            <p className="accent-5 mb-[45px]">Based on everything you told us, we recommend:</p>
            <Recommendations email={email} products={productMatches.product_matches[bucket]}></Recommendations>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  )

}

function Recommendations(props) {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const baseURL = apiBaseURL;

  if (!props.products) return <div><img src={spinner} className="animate-spin mx-auto" alt="Loading"></img></div>; // Todo: Add a loading component

  return (
    (user && props.products && isAuthenticated) && <>
      <div className="flex lg:flex-row flex-col gap-[27px] items-center lg:items-start">
        {props.products.slice(0, 2).map((product, index) => (
          <div>
            <div className='pt-[30px] pb-[163px] px-[41px] mb-[25px] shadow-[0px_3px_12px_rgba(0,0,0,0.08)] rounded-[8px] lg:w-[465px] w-[390px] flex flex-col items-center relative'>
              <p className="accent-callout brand-gold">{product.tags[0].toUpperCase()}</p>
              <p className="h3-bold mt-[15px] mb-[10px]">{product.name}</p>
              <p className="paragraph-1 mb-[45px]">{product.product_name}</p>
              <img src={`${baseURL.slice(0, -1)}${product.image}`} className='w-[180px] h-[180px] rounded-[10px] inline' alt='image'></img>
              <p className="paragraph-1 my-[45px]">{product.description}</p>
              <div className="overflow-hidden rounded-[10px] inline-block bg-neutral-25">
                {product.loom_video_url ?
                  <>
                    <Video iframeClass="productsIframe" videoUrl={product.loom_video_url} ></Video>
                  </> : <></>}
              </div>
              <a className="nav-gold-button absolute bottom-[61px]" href={product.product_url} target="_blank">Learn More</a>
              <p className="inline pl-[30px] paragraph-1-mobile neutral-600 absolute bottom-[30px]">You'll be redirected to {product.name}'s website.</p>
            </div>
          </div>

        ))}
      </div>
    </>
  )

}

const ProductMatches = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  isAuthenticated && console.log(user);
  if (isLoading) return <div>Loading...</div>; // Todo: Add a loading component

  // Redirect to home if not authenticated
  !isAuthenticated && loginWithRedirect()

  return (
    // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
    isAuthenticated && <UserContext.Provider value={user.email}><LoadProductMatches email={user.email} /></UserContext.Provider>
  )
}


export default ProductMatches
