import Sidebar from "./components/Sidebar"
import Headings from "./components/Headings"
import UserCard from "./components/UserCard"
import Footer from "../../components/Footer"
import retirement from "./../../assets/icons/retirement-gold.svg"
import { useAuth0 } from "@auth0/auth0-react";

const Help = () => {
    const { user, isAuthenticated } = useAuth0();
    return (
        isAuthenticated && <>
            <div>
                <Sidebar user={user}></Sidebar>
                <UserCard username={user.name} pfp={user.picture}/>
                <div className="app-container">
                    <Headings title='Help and Support' icon={retirement} />
                </div>
            </div>
            <Footer></Footer>
        </>

    )
}

export default Help