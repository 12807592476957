import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm'
import Footer from '../../pages/Onboarding/Footer';
import postLoadPostData from '../../utils/postLoadPostData';
import { useAuth0 } from '@auth0/auth0-react';
import Modal from 'react-modal';
import postLoadSaveData from '../../utils/postLoadSaveData';
const logo = require('../../assets/logo-color.png');
const down = require('../../assets/gray-arrow-down.svg').default;
const right = require('../../assets/gray-arrow-right.svg').default;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    background: 'white',
    'border-radius': '10px',
    padding: '0px'
  },
};

const Payment = (props) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState(null);
  const [collapse, setCollapse] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  // Actions for Modal confirm
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() { setIsOpen(true) }
  function closeModal() { setIsOpen(false) }
  // Actions for Modal error
  const [errorIsOpen, setErrorIsOpen] = useState(false);
  function openModalError() { setIsOpen(true) }
  function closeModalError() { setIsOpen(false) }
  // env
  const running_env = process.env.REACT_APP_RUNNING_ENV;
  var apiBaseURL = '';
  const isProduction = running_env === 'development' || running_env === 'local' ? false : true;

  if (running_env === 'development') {
    apiBaseURL = process.env.REACT_APP_API_URL_DEV;
  } else if (running_env === 'production') {
    apiBaseURL = process.env.REACT_APP_API_URL_PROD;
  } else if (running_env === 'local') {
    apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
  }

  const baseURL = apiBaseURL;
  const checkoutSessionUrl = `${baseURL}subscription/create-payment-intent/`;
  const urlsubmit = `${baseURL}client_extras/promocode/`;
  const urlsubscribe = `${baseURL}subscription/redeem_subscription/`;

  const stripePromise = props.stripePromise;
  const [clientSecret, setClientSecret] = useState('');
  const [stripeReady, setStripeReady] = useState(false);
  const [discount, setDiscount] = useState(null);

  useEffect(() => {

    let options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };

    // Create PaymentIntent as soon as the page loads
    fetch(checkoutSessionUrl, options)
      .then((res) => res.json())
      .then(({ clientSecret }) => {
        setClientSecret(clientSecret);
        setStripeReady(true);
      })

  }, []);

  const handleOnChange = () => {
    setCollapse(!collapse);
  }

  // Verify promo code
  const postOnClick = async () => {
    setDiscount(null);
    let payload = {
      "code": promoCode
    }
    console.log(payload);
    try {
      const token = await getAccessTokenSilently();
      // Execute the api method by calling the function
      const response = await postLoadSaveData(urlsubmit, payload, token);
      const responseContent = await postLoadPostData(urlsubmit, payload, token);

      if (response === 404) {
        document.getElementById('promo-code').style.border = '2px solid red';
        document.getElementById('promo-code').style.color = 'red';
        console.log(response)
      } else if (response === 200 ) {
        document.getElementById('promo-code').style.border = '2px solid green';
        document.getElementById('promo-code').style.color = 'green';
        postOnSub();
        if (responseContent?.discount!== null) {
          setDiscount(responseContent.discount)
          console.log('discount',responseContent?.discount)
          console.log(collapse)
          console.log(discount)
        }
      } else {
        alert('Something went wrong')
        console.log(response)
      }

    } catch (err) {
      console.log(err);
      console.log(err.status)
    } finally {
      console.log('Final action')
    }

  }

  //Act on valid promo code

  const postOnSub = async () => {
    let payload = {
      "promo_code": promoCode,
      "email": props.email
  }
    console.log(payload);
    try {
      const token = await getAccessTokenSilently();
      // Execute the api method by calling the function
      const response = await postLoadPostData(urlsubscribe, payload, token);
      console.log(response)
      if (response.discount_type === 'full') {
        setDiscount(null);
        setDiscount(100);
        openModal();
        setTimeout(() => {
          navigate("/dashboard", { state: true })
        }, "3000");
        console.log(response)
      } else if(response.discount_type === 'partial'){
        setStripeReady(false);
        setClientSecret(response.clientSecret);
        setTimeout(() => {
          setStripeReady(true);
        }, "1000");
        
      } else if (response === "User is already subscribed to a plan"){
        openModal();
        setTimeout(() => {
          navigate("/dashboard", { state: true })
        }, "3000");
      } else{
        alert('Something went wrong creating the subscription')
        console.log(response)
      }

    } catch (err) {
      console.log(err);
      console.log(err.status)
    } finally {
      console.log('Final action')
    }

  }


  return (
    <>

      <div className='w-screen m-0'>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Ok!"
        >
          <div className='bg-fade-gold py-[30px] px-[46px]'>
            <p className='basic-black max-w-[405px] text-center paragraph-1'>Great! You've got a full price discount.</p>
            <p className='basic-black max-w-[405px] text-center paragraph-1'>We're now redirecting you. Please, wait.</p>
            <p className='basic-black max-w-[405px] text-center paragraph-1'></p>
          </div>
        </Modal>
        <div className='flex flex-row min-h-[85vh]'>
          <nav className="hidden lg:flex flex-col flex-nowrap bg-white basis-[312px] shrink-0 grow-0">
            <img src={logo} className="w-[264.15px] h-[65px] mt-[41px] self-center" alt="KairosLogo" />
            <div className="onboarding-bar w-[30px] h-[266px] rounded-full mt-[75px] ml-[59px]">
              <div className="bar-progress w-[30px] h-[266px] rounded-full"><div className="bar-dot w-[10px] h-[10px] rounded-full top-[10px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Tell Us About Yourself</p></div></div>
              <div className="bar-dot w-[10px] h-[10px] rounded-full top-[123px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Set Your Goals</p></div>
              <div className="bar-dot w-[10px] h-[10px] rounded-full top-[240px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Connect Your Accounts</p></div>
            </div>
          </nav>
          <div className='bg-neutral-25 pb-[100px] w-[100%] min-h-[500px]'>
            <div className='mt-[50px] lg:mt-[106px] mb-[50px] lg:mb-[100px] mx-[30px] lg:mx-[109px]'>
              <h1 className='text-center'>We have finished your Finstack!</h1>
              <p className='paragraph-1-to-mobile text-center'>Access your Finstack for a one-time payment of $9.99<br />
                Link your existing accounts, calculate your net worth, and access proven ways to build your wealth<br />
                No surprises, no subscriptions, just stacks</p>
            </div>
            <div className={`${collapse === true ? 'neutral-300' : 'neutral-500'} cursor-pointer pl-[20px] paragraph-1-to-mobile max-w-full lg:max-w-[600px] lg:mx-auto lg:pb-[30px] pb-[20px] mx-[10px]`} onClick={() => { handleOnChange() }}>
              <img src={collapse === true ? right : down} className={`inline ${collapse === true ? 'pr-[9.75px]' : 'pr-[7px]'}`} alt="arrow" />
              <p className='inline'>Have a promo code?</p>
            </div>
            <div className={`${collapse === true ? 'block' : 'hidden'} pl-[20px] paragraph-1-to-mobile max-w-full lg:max-w-[600px] lg:mx-auto lg:pb-[30px] pb-[20px] mx-[10px]`}>
              <input className='promo-input' type="text" id="promo-code" maxlenght="64" value={promoCode} onChange={(e) => setPromoCode(e.target.value.toUpperCase())} />
              <button className='small-border-gold-button ml-[20px]' onClick={() => { postOnClick() }}>Submit</button>
              <p className={`${(collapse && !!discount) === true ? 'block' : 'hidden'} neutral-500 paragraph-1-to-mobile pl-[35px] pt-[10px]`}>{`${discount}% discount applied`}</p>
            </div>
            <div className='max-w-full lg:max-w-[600px] lg:mx-auto bg-white lg:p-[30px] p-[20px] shadow-[0px_6px_24px_rgba(0,0,0,0.08)] rounded-[10px] lg:rounded-[20px] mx-[10px]'>
              {clientSecret && stripePromise && stripeReady && (
                <Elements stripe={stripePromise} options={{ clientSecret, }}>
                  <CheckoutForm email={props.email} name={props.name} phone={props.phone} zip={props.zip} city={props.city} line1={props.line1} state={props.state} />
                </Elements>
              )}
            </div>






          </div>
        </div>

        <div className='flex flex-col-reverse lg:flex-row justify-between align-middle py-[37px] lg:px-[119px] px-[28px] top-shadow'>
          <button className='text-only-button mt-[15px] lg:mt-0 hidden lg:inline' type='button' onClick={() => navigate(-1)}>Go Back</button>
          <div className='flex flex-col-reverse lg:flex-row lg:gap-0 gap-[15px]'>



          </div>

        </div>


        <Footer></Footer>
      </div>
    </>
  );
}

export default Payment;