import React, { useState, useEffect, useRef, createContext, useContext } from 'react';
import InternalFooter from './Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import postLoadSaveData from '../../utils/postLoadSaveData';
import postLoadFetchData from '../../utils/postLoadFetchData';
import postLoadDeleteData from '../../utils/postLoadDeleteData';
import { useAuth0 } from '@auth0/auth0-react';
const logo = require('../../assets/logo-color.png');
const leaf = require('../../assets/icons/leaf-gold.svg').default;
const branch = require('../../assets/icons/branch-gold.svg').default;
const plant = require('../../assets/icons/plant-gold.svg').default;
const crypto = require('../../assets/icons/crypto-gold.svg').default;
const tree = require('../../assets/icons/tree-gold.svg').default;
const grow = require('../../assets/icons/grow-gold.svg').default;
const leaf_white = require('../../assets/icons/leaf-white.svg').default;
const branch_white = require('../../assets/icons/branch-white.svg').default;
const plant_white = require('../../assets/icons/plant-white.svg').default;
const crypto_white = require('../../assets/icons/crypto-white.svg').default;
const tree_white = require('../../assets/icons/tree-white.svg').default;
const grow_white = require('../../assets/icons/grow-white.svg').default;

const cards = [{
  label: "I am new to investing",
  icon: leaf,
  icon_white: leaf_white,
  action1: "#"
},
{
  label: "I invest in real estate",
  icon: branch,
  icon_white: branch_white,
  action1: "#"
},
{
  label: "I invest in the stock market",
  icon: plant,
  icon_white: plant_white,
  action1: "#"
},
{
  label: "I invest in cryptocurrency",
  icon: crypto,
  icon_white: crypto_white,
  action1: "#"
},
{
  label: "I don't invest",
  icon: tree,
  icon_white: tree_white,
  action1: "#"
},
{
  label: "Other",
  icon: grow,
  icon_white: grow_white,
  action1: "#"
}
]
const UserContext = createContext()
const LoadInvesting = () => {
  const email = useContext(UserContext);

  return (
    <>
      <div className='w-screen m-0'>

        <Fields email={email}></Fields>
        <InternalFooter></InternalFooter>
      </div>



    </>
  )
}

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local'){
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

function Fields(props) {
  const email = props.email;
  const ref = useRef(null);
  // Get token function, urls
  const { getAccessTokenSilently, user } = useAuth0();
  const [profileData, setProfileData] = useState(null);
  const baseURL = apiBaseURL;
  const url = `${baseURL}financial_discovery/financial_profile/?email=${email}&mode=profile`
  const urlsubmit = `${baseURL}financial_discovery/investment_status/`;

  const navigate = useNavigate();
  const [cardSelected, setCardSelected] = useState(null);
  const [selection, setSelection] = useState("");
  const location = useLocation();
  const [cardActive, setCardActive] = useState(null);

  // Example: post data on load
  useEffect(() => {
    // Start the process by getting the token
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const consolidate = await postLoadFetchData(url, token);
        setProfileData(consolidate.status?null:consolidate);
        console.log('data: ', consolidate)
        console.log('user', user)
        setSelection(()=>{
          if (consolidate.status||consolidate?.financial_profile.investment_status.length===0) {
            return ""
          } else {
            console.log(consolidate.financial_profile.investment_status[0][0])
            return consolidate.financial_profile.investment_status[0][0];
          }
        })
        setCardSelected(()=>{
          if (consolidate.status||consolidate?.financial_profile.investment_status.length===0) {
            return null;
          } else {
            console.log('SELECTED',cards.findIndex(element=> element.label === consolidate.financial_profile.investment_status[0][0]))
            return cards.findIndex(element=> element.label === consolidate.financial_profile.investment_status[0][0])
            
          }
        })
        // console.log("Got new token: " + token)
        // Execute the api method by calling the function
        // Uncomment the line below to post data on load
        // const response = await postLoadSaveData(url, payload, token);
      } catch (err) {
        console.log(err);
      }
    })();
    // End process
  }, [])

  // Post data on button click
  const postOnClick = async () => {

    const payload = [{
      email: user.email,
      status: selection
    }]
    try {
      const token = await getAccessTokenSilently();
      // console.log("Got new token for function: " + token)
      // Execute the api method by calling the function
      const response = await postLoadSaveData(urlsubmit, payload, token);
      console.log(response);
    } catch (err) {
      console.log(err);
    } finally {
      navigate("/loans")
    }

  }

  const putOnClick = async () => {

    const payload = [{
      email: user.email,
      status: selection
    }]
    try {
      const token = await getAccessTokenSilently();
      // console.log("Got new token for function: " + token)
      // Execute the api method by calling the function
      const deleted = await postLoadDeleteData(urlsubmit, {email:email}, token);
      const response = await postLoadSaveData(urlsubmit, payload, token);
      console.log(response);
    } catch (err) {
      console.log(err);
    } finally {
      navigate("/loans")
    }

  }

  // Post data on button click END

  const autoScroll = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
    ref.current?.focus();
  };

  return (<>
    <form onSubmit={profileData?.financial_profile.investment_status?putOnClick:postOnClick}>
      <div className='flex flex-row min-h-[85vh]'>
        <nav className="hidden lg:flex flex-col flex-nowrap bg-white basis-[312px] shrink-0 grow-0">
          <img src={logo} className="w-[264.15px] h-[65px] mt-[41px] self-center" alt="KairosLogo" />
          <div className="onboarding-bar w-[30px] h-[266px] rounded-full mt-[75px] ml-[59px]">
            <div className="bar-progress w-[30px] h-[149px] rounded-full"><div className="bar-dot w-[10px] h-[10px] rounded-full top-[10px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Tell Us About Yourself</p></div></div>
            <div className="bar-dot w-[10px] h-[10px] rounded-full top-[123px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Set Your Goals</p></div>
            <div className="bar-dot w-[10px] h-[10px] rounded-full top-[240px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Connect Your Accounts</p></div>
          </div>
        </nav>
        <div className='bg-neutral-25 pb-[70px] w-[100%]'>
          <div className='mt-[50px] lg:mt-[106px] mb-[50px] lg:mb-[100px] mx-[30px] lg:mx-[109px]'>
            <h1 className='text-center'>Are you already investing?</h1>
            <p className='paragraph-1 text-center'>Are you into crypto? The stock market? Real estate? Not even sure where to begin? We’ll get you where you need to go!
              Please select the statement(s) that best describe you.</p>
          </div>
          <div className='max-[429px]:grid-cols-1 grid grid-cols-2 md:grid-cols-3 lg:gap-8 mx-auto lg:w-[798px] w-full lg:px-0 px-[46px] gap-y-[25px] gap-x-[25px]'>
            {cards.map((card, index) => (
              <div key={index} onMouseEnter={() => { setCardActive(index); console.log(cardActive) }} onMouseLeave={() => setCardActive(null)} >
                <input id={index} type="radio" value={card.label} name='selection' className='hidden' onChange={(e) => { setSelection(e.target.value); console.log(e.target.value) }} />
                <label htmlFor={index}>
                  <div className='onboarding-card' onClick={() => setCardSelected(index)} style={{ backgroundColor: cardSelected === index ? "rgba(247, 189, 132, 0.8)" : "" }}>
                    <img src={((cardSelected === index) || (cardActive === index)) ? card.icon_white : card.icon} className="w-[60px] h-[60px] lg:w-[80px] lg:h-[80px] mx-auto" alt={card.label} />
                    <p className='card-label mx-auto'>{card.label}</p>
                  </div>
                </label>
              </div>

            ))}
          </div>




        </div>
      </div>

      <div className='flex flex-col-reverse lg:flex-row justify-between align-middle py-[37px] px-[119px] top-shadow'>
        <button className='text-only-button mt-[15px] lg:mt-0' type='button' onClick={() => navigate(-1)}>Go Back</button>
        <button ref={ref} className='gold-button' disabled={selection === ""} type='button' onClick={() => profileData?.financial_profile.investment_status?putOnClick():postOnClick()}>Next</button>
      </div>
    </form>
  </>)
}

const Investing = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  isAuthenticated && console.log(user);

  // Redirect to home if not authenticated
  !isLoading && !isAuthenticated && loginWithRedirect()

  return (
    // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
    isAuthenticated && <UserContext.Provider value={user.email}><LoadInvesting email={user.email} /></UserContext.Provider>
  )
}

export default Investing