//const placeholder600 = require('../../assets/rectangle2604093-f1uo-600h.png')
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from 'react'

const Expired = () => {
  const navigate = useNavigate();
  const { loginWithRedirect, logout } = useAuth0();

  useEffect(() => {
    setTimeout(() => {
      navigate('/payment')
    }, 3000)
  }, [])

  return (
    <>
      <p></p>
      <div className='large-container bg-white'>
        <div className="text-center py-4 flex flex-col items-center">

          <h1 className="big-header mt-[97px]">Uh oh...</h1>
          <h2 className="h2-thinner mt-[15px]">Looks like your subscription expired.</h2>
          <p className="paragraph-4 neutral-600 max-w-[1063px] mt-[15px]">We’ll redirect you to Stripe to update your payment information so you can continue using Kairos!</p>
          <button className="callout-brand-button mt-[45px]" onClick={() => navigate("/payment")}>Go to Stripe</button>
          <button className="text-button mt-[15px]" onClick={() => navigate("/")}>Go Back to the Homepage</button>

        </div>
      </div>

    </>
  )
}

export default Expired