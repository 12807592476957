const Headings = (props) => {
    return (
        <>
            <div className="lg:mt-[30px] mt-[2px] flex items-center ml-[30px] lg:ml-0">
                <img src={props.icon} className='w-[80px] h-[80px]'></img>
                <div className="pl-[25px] relative">
                    <h2>{props.title}</h2>
                    <div className="absolute bottom-[-15px] w-[150px] h-[2px] bg-gold"></div>
                </div>
            </div>
        </>
    )
}

export default Headings