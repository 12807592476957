//const placeholder600 = require('../../assets/rectangle2604093-f1uo-600h.png')
import { useNavigate } from "react-router-dom";
const NotFound = () => {
const navigate = useNavigate();
  return (
    <>
    <p></p>
      <div className='large-container bg-white'>
        <div className="text-center py-4 flex flex-col items-center">

            <h1 className="big-header mt-[97px]">404 Error</h1>
            <h2 className="h2-thinner mt-[15px]">Page Not Found</h2>
            <p className="paragraph-4 neutral-600 max-w-[1063px] mt-[30px]">Uh oh... the page you are looking for does not exist or has been moved.</p>
            <button className="callout-brand-button mt-[60px]" onClick={()=>navigate("/")}>Go Back Home</button>
          

        </div>
      </div>

    </>
  )
}

export default NotFound