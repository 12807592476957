import Sidebar from "./components/Sidebar"
import React, { createContext, useContext, useState, useEffect } from 'react';
import Headings from "./components/Headings"
import UserCard from "./components/UserCard"
import Footer from "../../components/Footer"
import books from "./../../assets/icons/books-gold.svg"
import { useAuth0 } from "@auth0/auth0-react";
import postLoadFetchData from '../../utils/postLoadFetchData';
import postLoadSaveData from "../../utils/postLoadSaveData";
import postLoadDeleteData from "../../utils/postLoadDeleteData";
import { useNavigate } from "react-router-dom";
const bookmark_on = require('../../assets/icons/bookmark-on.svg').default;
const bookmark_off = require('../../assets/icons/bookmark-off.svg').default;
const arrow = require('../../assets/down-arrow.svg').default;
const spinner = require('../../assets/icons/spinner.png');


const UserContext = createContext()

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
    apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
    apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local') {
    apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

function LoadResources() {

    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const email = useContext(UserContext);

    if (!isAuthenticated) return <div>Loading Resources...</div>; // Todo: Add a loading component

    return (
        (user && isAuthenticated) && <>
            <div>
                <Sidebar user={user}></Sidebar>
                <UserCard user={user} />
                <div className="app-container">
                    <div className="lg:w-[1020px] lg:mx-auto w-full px-[20px] lg:px-0">
                        <Headings title='Resources' icon={books} />
                        <p className="ml-[105px] mt-[35px] lg:mr-[60px] mb-[45px] paragraph-1">
                            We believe that a vital part of taking control of your finances is understanding them, so we’ve curated the following collection of articles to help you gain clarity and confidence about the world of personal finance.
                        </p>
                        <p className="accent-5 pl-[28px]">Recommended for You</p>
                        <p className="paragraph-1-mobile pl-[28px] mt-[10px]">We think these articles will help you reach your goals!</p>
                        <Articles email={email}></Articles>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>

    )
}

function Articles() {

    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const email = useContext(UserContext);
    const [articles, setArticles] = useState(null);
    const [originalArticles, setOriginalArticles] = useState(null);
    const [recommended, setRecommended] = useState(null);
    const [sort, setSort] = useState("");
    const [filter, setFilter] = useState("");
    const baseURL = apiBaseURL;
    const url = `${baseURL}client_extras/articles/?email=${email}&mode=all&sort=newest`;
    //const urlRec = `${baseURL}client_extras/articles/?email=${email}&mode=by_tag&sort=newest`;
    const urlRec = `${baseURL}client_extras/articles/?email=${email}&mode=all&sort=newest`;
    const bookmarkUrl = `${baseURL}client_extras/bookmarks/`;
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently();
                // console.log("Got new token for function: " + token)
                // Execute the api method by calling the function
                const newestBlogArticles = await postLoadFetchData(url, token);
                const recommendedBlogArticles = await postLoadFetchData(urlRec, token);
                setArticles(newestBlogArticles);
                setOriginalArticles(newestBlogArticles);
                console.log('dataNew: ', newestBlogArticles)
                setRecommended(recommendedBlogArticles);
                console.log('dataRec: ', recommendedBlogArticles)
                console.log('user', user)


            } catch (err) {
                console.log(err);
            }
        })();
    }, []);

    // Post data on button click

    const bookmark = async (id, index, status) => {
        var articles_temp = articles.map(obj => ({ ...obj }));
        articles_temp[index].bookmarked = !articles_temp[index].bookmarked;
        const payload = {
            "email": user.email,
            "article_id": id
        };
        console.log('temp', articles_temp[index]);
        setArticles(articles_temp);
        if (status) {
            try {
                const token = await getAccessTokenSilently();
                // console.log("Got new token for function: " + token)
                // Execute the api method by calling the function
                const response = await postLoadDeleteData(bookmarkUrl, payload, token);
                console.log(response);

            } catch (err) {
                console.log(err);
            }
        } else {
            try {
                const token = await getAccessTokenSilently();
                // console.log("Got new token for function: " + token)
                // Execute the api method by calling the function
                const response = await postLoadSaveData(bookmarkUrl, payload, token);
                console.log(response);

            } catch (err) {
                console.log(err);
            }
        }


    }

    function apply(action, value) {
        if (action === 'filter') {
            const filtered = originalArticles.filter(article => {
                if (value === "ALL") {
                    return true
                } else if (value === "bookmarked") {
                    return article.bookmarked;
                } else {
                    return article.tags.some(e => e.name === value)
                }
            });
            const sorted = filtered.sort((a, b) => {
                if (sort === 'newest') {
                    if (a.id > b.id) return -1
                    if (a.id < b.id) return 1
                    return 0;

                } else if (sort === 'oldest') {
                    if (a.id > b.id) return 1
                    if (a.id < b.id) return -1
                    return 0;
                } else if (sort === 'popular') {
                    if (a.reads > b.reads) return -1
                    if (a.reads < b.reads) return 1
                    return 0;
                }
            })
            setArticles(sorted);
            console.log(sorted);
        }
        if (action === 'sort') {
            const filtered = originalArticles.filter(article => {
                if ((filter === "ALL") || (filter === "")) {
                    return true
                } else if (filter === "bookmarked") {
                    return article.bookmarked;
                } else {
                    return article.tags.some(e => e.name === filter)
                }
            });
            const sorted = filtered.sort((a, b) => {
                if (value === 'newest') {
                    if (a.id > b.id) return -1
                    if (a.id < b.id) return 1
                    return 0;

                } else if (value === 'oldest') {
                    if (a.id > b.id) return 1
                    if (a.id < b.id) return -1
                    return 0;
                } else if (value === 'popular') {
                    if (a.reads > b.reads) return -1
                    if (a.reads < b.reads) return 1
                    return 0;
                }
            })
            setArticles(sorted);
            console.log(sorted);
        }

    }

    if (!articles) return <>
        <div className="pt-[28px] pl-[60px] text-center flex items-center gap-[60px] h-[344px]">
            <img src={spinner} className="animate-spin mx-auto" alt="Loading"></img>
        </div>
        <div className="relative">
            <div className="absolute top-0 right-0">
                <div className="mr-[15px] inline-block relative">
                    <img src={arrow} className="w-[12px] h-[7px] absolute right-[14px] top-[12px] pointer-events-none"></img>
                    <select className="small-select" value={filter}>
                        <option value="" disabled defaultValue hidden>Filter</option>
                        <option>Bookmarked</option>
                        <option>Savings</option>
                        <option>Investing</option>
                        <option>Insurance</option>
                        <option>Loans</option>
                        <option>Overall</option>
                    </select>
                </div>
                <div className="inline-block relative">
                    <img src={arrow} className="w-[12px] h-[7px] absolute right-[14px] top-[12px] pointer-events-none"></img>
                    <select className="small-select" value={sort}>
                        <option value="" disabled defaultValue hidden>Sort By</option>
                        <option value="newest">Newest First</option>
                        <option value="oldest">Oldest First</option>
                        <option value="popular">Most Popular</option>
                    </select>
                </div>

            </div>
            <p className="accent-5 pl-[28px] mt-[30px]">All Articles</p>
            <p className="paragraph-1-mobile pl-[28px] mt-[10px] mb-[30px]">Check back or make sure your email notifications are turned on for new articles.</p>
            <div className="pt-[28px] pl-[60px] text-left flex flex-col items-start gap-[45px]">
                <img src={spinner} className="animate-spin mx-auto" alt="Loading"></img>
            </div>
        </div>
    </>; // Todo: Add a loading component

    return (
        (user && articles && isAuthenticated) && <>
            <div className="pt-[28px] lg:pl-[60px] text-center flex items-center gap-[60px] justify-center flex-col lg:flex-row">
                {
                    recommended.slice(0, 3).map((article) => {
                        let src = baseURL.slice(0, -1) + article.image
                        return (
                            <>
                                <div className="basis-[221px] h-[316px] grow-0 shrink-0 hidden lg:block" key={article.id}>
                                    <div className="w-[200px] h-[150px] rounded-[10px] overflow-hidden">
                                        <img src={src} className="inline cursor-pointer object-fill min-h-[150px]" onClick={() => navigate(`/resources/${article.slug}`)}></img>
                                    </div>
                                    <p className="accent-6 mt-[30px] mb-[10px] cursor-pointer line-clamp-3" onClick={() => navigate(`/resources/${article.slug}`)}>{article.title}</p>
                                    <p className="accent-2 neutral-600">{article.tags[0].name.toUpperCase()}</p>
                                    <p className="paragraph-1-mobile brand-gold mt-[10px]">{new Date(article.last_updated).toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" })}</p>
                                </div>
                                <div className="flex flex-col w-full items-center lg:hidden" key={article.id + '-mobile'}>
                                    <img src={src} className="w-[233px] rounded-[10px] cursor-pointer" onClick={() => navigate(`/resources/${article.slug}`)}></img>
                                    <div className="accent-6 mt-[30px] mb-[10px] cursor-pointer" onClick={() => navigate(`/resources/${article.slug}`)}>{article.title}</div>
                                    <div className="mb-[15px]">
                                        <p className="paragraph-1-mobile brand-gold mt-[15px] inline pr-[15px] shadow-[2px_0px_#F7BD84]">{new Date(article.last_updated).toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" })}</p>
                                        <p className="paragraph-1-mobile brand-gold mt-[15px] inline pl-[15px]">{article.tags[0].name.toUpperCase()}</p>
                                    </div>
                                    <p className="paragraph-1-mobile basic-black overflow-hidden line-clamp-3">{article.content}</p>

                                </div>
                            </>
                        )
                    }
                    )
                }
            </div>
            <div className="relative">
                <div className="hidden lg:block absolute top-0 right-0">
                    <div className="mr-[15px] inline-block relative">
                        <img src={arrow} className="w-[12px] h-[7px] absolute right-[14px] top-[12px] pointer-events-none"></img>
                        <select className="small-select" value={filter} onChange={(e) => { setFilter(e.target.value); console.log(e.target.value); apply('filter', e.target.value) }}>
                            <option value="" disabled defaultValue hidden>Filter</option>
                            <option value="bookmarked">Bookmarked</option>
                            <option value="savings">Savings</option>
                            <option value="investing">Investing</option>
                            <option value="insurance">Insurance</option>
                            <option value="loans">Loans</option>
                            <option value="overall">Overall</option>
                            <option value="ALL">All Articles</option>
                        </select>
                    </div>
                    <div className="inline-block relative">
                        <img src={arrow} className="w-[12px] h-[7px] absolute right-[14px] top-[12px] pointer-events-none"></img>
                        <select className="small-select" value={sort} onChange={(e) => { setSort(e.target.value); console.log(e.target.value); apply('sort', e.target.value) }}>
                            <option value="" disabled defaultValue hidden>Sort By</option>
                            <option value="newest">Newest First</option>
                            <option value="oldest">Oldest First</option>
                            <option value="popular">Most Popular</option>
                        </select>
                    </div>

                </div>
                <p className="accent-5 pl-[28px] mt-[30px]">All Articles</p>
                <p className="paragraph-1-mobile pl-[28px] mt-[10px] mb-[30px]">Check back or make sure your email notifications are turned on for new articles.</p>
                <div className="block lg:hidden relative flex items-center justify-center">
                    <div className="mr-[15px] inline-block relative">
                        <img src={arrow} className="w-[12px] h-[7px] absolute right-[14px] top-[12px] pointer-events-none"></img>
                        <select className="small-select" value={filter} onChange={(e) => { setFilter(e.target.value); console.log(e.target.value); apply('filter', e.target.value) }}>
                            <option value="" disabled defaultValue hidden>Filter</option>
                            <option value="bookmarked">Bookmarked</option>
                            <option value="savings">Savings</option>
                            <option value="investing">Investing</option>
                            <option value="insurance">Insurance</option>
                            <option value="loans">Loans</option>
                            <option value="overall">Overall</option>
                            <option value="ALL">All Articles</option>
                        </select>
                    </div>
                    <div className="inline-block relative">
                        <img src={arrow} className="w-[12px] h-[7px] absolute right-[14px] top-[12px] pointer-events-none"></img>
                        <select className="small-select" value={sort} onChange={(e) => { setSort(e.target.value); console.log(e.target.value); apply('sort', e.target.value) }}>
                            <option value="" disabled defaultValue hidden>Sort By</option>
                            <option value="newest">Newest First</option>
                            <option value="oldest">Oldest First</option>
                            <option value="popular">Most Popular</option>
                        </select>
                    </div>

                </div>
                <div className="pt-[28px] lg:pl-[60px] text-left flex flex-col items-start gap-[45px]">
                    {
                        articles.map((article, i) => {
                            let src = baseURL.slice(0, -1) + article.image
                            return (
                                <>
                                    <div className="w-[825px] h-[150px] lg:flex hidden" key={article.id}>
                                        <div className="block shrink-0 relative">
                                            <div className="w-[200px] h-[150px] rounded-[10px] overflow-hidden">
                                                <img src={src} className="relative cursor-pointer object-fill min-h-[150px]" onClick={() => navigate(`/resources/${article.slug}`)}></img>
                                            </div>
                                            {article.bookmarked ?
                                                <img src={bookmark_on} className="w-[38px] h-[48px] absolute top-0 right-[10px] inline-block cursor-pointer" onClick={() => bookmark(article.id, i, article.bookmarked)}></img> :
                                                <img src={bookmark_off} className="w-[38px] h-[48px] absolute top-0 right-[10px] inline-block cursor-pointer" onClick={() => bookmark(article.id, i, article.bookmarked)}></img>}
                                        </div>
                                        <div className="pl-[30px] pt-[16px]">
                                            <p className="accent-6 mb-[15px] cursor-pointer" onClick={() => navigate(`/resources/${article.slug}`)}>{article.title}</p>
                                            <p className="paragraph-1-mobile basic-black overflow-hidden line-clamp-3">{article.content}</p>
                                            <p className="paragraph-1-mobile brand-gold mt-[15px] inline pr-[15px] shadow-[2px_0px_#F7BD84]">{new Date(article.last_updated).toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" })}</p>
                                            <p className="paragraph-1-mobile brand-gold mt-[15px] inline pl-[15px]">{article.tags[0].name.toUpperCase()}</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full items-center lg:hidden" key={article.id + '-mobile'}>
                                        <img src={src} className="w-[233px] rounded-[10px] cursor-pointer" onClick={() => navigate(`/resources/${article.slug}`)}></img>
                                        <div className="accent-6 mt-[30px] mb-[10px] cursor-pointer" onClick={() => navigate(`/resources/${article.slug}`)}>{article.title}</div>
                                        <div className="mb-[15px]">
                                            <p className="paragraph-1-mobile brand-gold mt-[15px] inline pr-[15px] shadow-[2px_0px_#F7BD84]">{new Date(article.last_updated).toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" })}</p>
                                            <p className="paragraph-1-mobile brand-gold mt-[15px] inline pl-[15px]">{article.tags[0].name.toUpperCase()}</p>
                                        </div>
                                        <p className="paragraph-1-mobile basic-black overflow-hidden line-clamp-3">{article.content}</p>

                                    </div>
                                </>


                            )
                        }
                        )
                    }
                </div>
            </div>
        </>

    )
}

const Resources = () => {
    const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    isAuthenticated && console.log(user);
    if (isLoading) return <div>Loading Kairos...</div>; // Todo: Add a loading component

    // Redirect to home if not authenticated
    !isAuthenticated && loginWithRedirect()

    return (
        // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
        isAuthenticated && <UserContext.Provider value={user.email}><LoadResources email={user.email} /></UserContext.Provider>
    )

}

export default Resources