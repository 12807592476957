import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useState, createContext } from "react"
import postLoadFetchData from '../utils/postLoadFetchData';
import { useNavigate } from 'react-router';
import logo from '../assets/chart-bg-placeholder.png';
const spinner = require('../assets/icons/spinner.png');

const UserContext = createContext()
//extract 
function buildBalanceData(data) {
  let formatted ={};
  data.map(element => Object.assign(formatted, element));
  const balanceData = []
  console.log('input', formatted)
  for (var property in formatted) {
    balanceData.push({
      name: property.slice(0, 3),
      uv: formatted[property],

    })
    console.log(property)
    console.log(formatted[property])
  }
  console.log('balance formatted', balanceData)
  return balanceData
}

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local'){
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

function LoadAreaChartComponent() {
  const navigate = useNavigate();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const email = useContext(UserContext);
  const [historic, setHistoric] = useState(null);
  const baseURL = apiBaseURL;
  const url = `${baseURL}connections/historic_networth?email=${email}`;

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        // console.log("Got new token for function: " + token)
        // Execute the api method by calling the function
        const response = await postLoadFetchData(url, token);
        setHistoric(response);
        console.log('historic: ', response)
        console.log('user', user)

      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  if (!historic) return <div className='w-100 bg-neutral-25 h-[300px] relative top-[-50px]'><img src={spinner} className="animate-spin mx-auto" alt="Loading"></img></div>; // Todo: Add a loading component

  if (historic.status === 500){ // Todo: Add more details about the error
    return (<div className='w-[346px] lg:absolute relative bg-white shadow-[0px_3px_12px_rgba(0,0,0,0.08)] px-[6px] py-[22px] rounded-[10px] left-[30px] lg:left-1/4 lg:bottom-2/4 lg:translate-y-2/4'>
    <p className='accent-6 text-center mb-[15px]'>An error has occurred in Plaid while retrieving financial data.</p>
    <p className='paragraph-1-mobile px-[16px] text-center'>We'll gather more details to fix this ASAP.</p>
  </div>)
  }

  if(historic === "No connected accounts were found") {
    return(<></>)
  }

  return (
    <ResponsiveContainer height={300}>
      {
        historic?.historic_networth ? 
        (historic.historic_networth.week.length === 0 ?
        <div className='w-[346px] absolute bg-white shadow-[0px_3px_12px_rgba(0,0,0,0.08)] px-[6px] py-[22px] rounded-[10px] left-[30px] lg:left-1/4 bottom-2/4 translate-y-2/4'>
          <p className='accent-6 text-center mb-[15px]'>We’re so stoked to show you your net worth over time, but we don’t quite have enough data.</p>
          <p className='paragraph-1-mobile px-[16px] text-center'>Come back in a couple of days and this graph will start to populate!</p>
        </div> :
        <AreaChart
          width={300}
          height={400}
          data={buildBalanceData(historic.historic_networth.week)}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <XAxis dataKey="name" axisLine={false} tickSize={0} tickMargin={10} />
          <YAxis tick={false} axisLine={false} />
          <Tooltip />
          <Area type="monotone" dataKey="uv" stroke="#F7BD84" fill="#F7BD84" />
        </AreaChart>)
        :
        <div className='w-[346px] absolute bg-white shadow-[0px_3px_12px_rgba(0,0,0,0.08)] px-[6px] py-[22px] rounded-[10px] left-1/4 bottom-2/4 translate-y-2/4'>
          <p className='accent-6 text-center mb-[15px]'>We couldn't find any financial accounts connected to your Kairos Profile.</p>
          <p className='paragraph-1-mobile px-[16px] text-center'>Connect as many financial accounts as you're comfortable with <span className="span-button" onClick={() => navigate("/onboarding/connect-accounts", { state: { isNotOnboarding: true } })}>here</span>.</p>
        </div>
      }
    </ResponsiveContainer>
  );

}

const AreaChartComponent = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  isAuthenticated && console.log(user);
  if (isLoading) return <div className='w-[520px] h-[300px] bg-neutral-25 inline-block  relative top-[-50px]'>Loading chart...</div>; // Todo: Add a loading component

  // Redirect to home if not authenticated
  !isAuthenticated && loginWithRedirect()

  return (
    // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
    isAuthenticated && <UserContext.Provider value={user.email}><LoadAreaChartComponent email={user.email} /></UserContext.Provider>
  )
}

export default AreaChartComponent;