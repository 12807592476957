import React from 'react';
import savings from './../assets/lineart/savings.png';
import insurance from './../assets/lineart/insurance.png';
import investment from './../assets/lineart/investment.png';
import loan from './../assets/lineart/loan.png';
import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useState, createContext } from "react"
import postLoadFetchData from '../utils/postLoadFetchData';
import postLoadDeleteData from '../utils/postLoadDeleteData';
import PlaidUpdateRemove from './PlaidUpdateRemove';
import { useNavigate } from 'react-router-dom';
const spinner = require('../assets/icons/spinner.png');
const trashcan = require('../assets/icons/trashcan.svg').default;

const UserContext = createContext()


function showAsCurrency(amount) {
  return amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
}

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local') {
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

function LoadConnectedAccounts() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const email = useContext(UserContext);
  const [allConnectedAccounts, setAllConnectedAccounts] = useState(null);
  const baseURL = apiBaseURL;
  const url = `${baseURL}connections/accounts?email=${email}`;
  const deleteurl = `${baseURL}connections/plaid_token_delete`
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        // console.log("Got new token for function: " + token)
        // Execute the api method by calling the function
        const accounts = await postLoadFetchData(url, token);
        setAllConnectedAccounts(accounts);
        console.log('account from connected accounts: ', accounts)
        console.log('user', user)
      } catch (err) {
        console.log(err)
      }
    })();
  }, []);

  // Delete Token
  const deleteToken = async (item_id) => {
    let payload = {
      "email": email,
      "item_id": item_id
    }
    console.log(payload);
    try {
      const token = await getAccessTokenSilently();
      // console.log("Got new token for function: " + token)
      // Execute the api method by calling the function
      const response = await postLoadDeleteData(deleteurl, payload, token);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  }

  // Open confirmation modal
  const [confirmationIsOpen, setconfirmationIsOpen] = React.useState(false);

  function confirmDelete() {
    setconfirmationIsOpen(true);
  }

  function closeConfirmation() {
    setconfirmationIsOpen(false);
  }


  if (!allConnectedAccounts) return <div><img src={spinner} className="animate-spin mx-auto" alt="Loading"></img></div>; // Todo: Add a loading component

  if (allConnectedAccounts.status === 500 || allConnectedAccounts.status === 400) { // Todo: Add more details about the error
    return (
      <div>
        <p className='accent-5'>Connected Accounts</p>
        <button className='text-right-button ml-[10px] mb-[45px]' onClick={() => navigate('/onboarding/connect-accounts')}>Add Another Account</button>
        <div className='text-center'>An error has occurred in Plaid while retrieving financial data.
          We'll gather more details to fix this ASAP.</div>
      </div>
    )
  }

  if (!!allConnectedAccounts.message){
    return( <>
    </>)
  }

  return (
    // loop through props.data.accounts and display each item using map
    <div>

      <p className='accent-5'>Connected Accounts</p>
      <button className='text-right-button ml-[10px] mb-[45px]' onClick={() => navigate('/onboarding/connect-accounts')}>Add Another Account</button>
      <ul className='flex flex-col gap-[48px]'>
        {
          allConnectedAccounts.length > 0 ?
            (allConnectedAccounts.map(({ account_id, name, institution_name, balances, type, item_id }) => {
              return (
                <li key={account_id} className="w-full lg:w-[881px] lg:h-[198px] flex flex-row justify-center">
                  <div className='lg:block hidden'>
                    <img src={savings} alt={type ? type.toUpperCase() : "UNKNOWN"} className={(type !== 'investment') && (type !== 'insurance') && (type !== 'loan') ? 'pr-[30px]' : 'hidden'}></img>
                    <img src={investment} alt={type ? type.toUpperCase() : "UNKNOWN"} className={type === 'investment' ? 'pr-[30px]' : 'hidden'}></img>
                    <img src={insurance} alt={type ? type.toUpperCase() : "UNKNOWN"} className={type === 'insurance' ? 'pr-[30px]' : 'hidden'}></img>
                    <img src={loan} alt={type ? type.toUpperCase() : "UNKNOWN"} className={type === 'loan' ? 'pr-[30px]' : 'hidden'}></img>
                  </div>
                  <div>
                    <div className='flex flex-row mb-[22px] lg:mb-0'>
                      <div className='block lg:hidden'>
                        <img src={savings} alt={type ? type.toUpperCase() : "UNKNOWN"} className={(type !== 'investment') && (type !== 'insurance') && (type !== 'loan') ? 'pr-[30px] w-full' : 'hidden'}></img>
                        <img src={investment} alt={type ? type.toUpperCase() : "UNKNOWN"} className={type === 'investment' ? 'pr-[30px] w-full' : 'hidden'}></img>
                        <img src={insurance} alt={type ? type.toUpperCase() : "UNKNOWN"} className={type === 'insurance' ? 'pr-[30px] w-full' : 'hidden'}></img>
                        <img src={loan} alt={type ? type.toUpperCase() : "UNKNOWN"} className={type === 'loan' ? 'pr-[30px] w-full' : 'hidden'}></img>
                      </div>
                      <div className='flex flex-col'>
                        <div className='accent-callout-to-mobile neutral-500 w-full lg:w-[731px] lg:mt-[2px] lg:mb-[15px] mb-[5px]'>{type ? type.toUpperCase() : "UNKNOWN" /* account type */}</div>
                        <div className='w-full flex flex-col lg:flex-row lg:justify-between'>
                          <h3 className='h3-bold-to-mobile inline lg:mb-[19px] mb-[5px]'>{name ? name : "No Name" /* account name */}</h3>
                          <h2 className='inline'>{showAsCurrency(balances.current) /* balance USD */}</h2>
                        </div>
                      </div>
                    </div>

                    <div className='w-full h-[0.5px] bg-neutral-500'></div>
                    <div className='pb-[6px] flex lg:flex-row flex-col lg:justify-between items-center mt-[26px] mb-[6px]'>
                      <div className='paragraph-1 lg:mb-0 mb-[20px]'>{institution_name ? institution_name : "No Institution name was provided" /* institution */}</div>
                      <div className='flex flex-row items-center'>
                        <PlaidUpdateRemove email={email} item_id={item_id} />
                        <img src={trashcan} className='h-[25px] cursor-pointer ml-[40px] hidden lg:block' alt="Delete" setconfirmationIsOpen />
                      </div>

                    </div>


                  </div>

                </li>
              )
            }))
            :
            <div>
              <div className='text-center paragraph-1 max-w-[825px] mx-auto'>You haven’t connected any accounts yet! In order to take full advantage of all Kairos has to offer,
                please connect as many as you’re comfortable with now.</div>
              <br></br><div className='text-center'><button className='gold-button' onClick={() => navigate('/onboarding/connect-accounts')}>Connect My Accounts</button></div>
            </div>
        }
      </ul>
    </div>

  );

}

const ConnectedAccounts = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  isAuthenticated && console.log(user);
  if (isLoading) return <div>Loading...</div>; // Todo: Add a loading component

  // Redirect to home if not authenticated
  !isLoading && !isAuthenticated && loginWithRedirect()

  return (
    // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
    isAuthenticated && <UserContext.Provider value={user.email}><LoadConnectedAccounts email={user.email} /></UserContext.Provider>
  )
}


export default ConnectedAccounts;