import "./footer.css"
import twitter from '../assets/whitebackgroundi417-s4u.svg'
import instagram from '../assets/instagram.svg'
import linkedin from '../assets/linkedin.svg'
import { useNavigate } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import FooterLogoutButton from "./FooterLogoutButton"
import { useState } from "react"
import jsonp from "jsonp"
import Modal from 'react-modal';
const logowhite = require('../assets/kairoslogoassetsfinalkairoslogowhite1i417-sdw9-200h.png')
const logowhitemobile = require('../assets/logomark-white.png')
const running_env = process.env.REACT_APP_RUNNING_ENV;

const domain = running_env === 'development' || running_env === 'local' ? process.env.REACT_APP_AUTH0_DOMAIN_DEV : process.env.REACT_APP_AUTH0_DOMAIN_PROD;


const Footer = () => {
  const navigate = useNavigate();
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const [emailSub, setEmailSub] = useState("");


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      background: 'white',
      'border-radius': '10px',
      padding: '0px'
    },
  };

  const onSubmit = e => {
    const url = 'https://ventures.us5.list-manage.com/subscribe/post-json?u=4f459966fb239b5b02c09f142&amp;id=939240c979&amp;f_id=0076a4ebf0';
    jsonp(`${url}&EMAIL=${encodeURIComponent(emailSub)}&tags=4218821`, { param: 'c' }, (_, data) => {
      //const { msg, result } = data
      setIsOpen(true);
      setTimeout(() => setIsOpen(false), 3000);
      setEmailSub("");
    });
  };
  // Actions for Modal
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() { setIsOpen(true) }
  function closeModal() { setIsOpen(false) }

  return (
    <div className="large-container bg-black pt-[30px] lg:pt-0 relative z-2">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Thank you"
      >
        <div className='bg-fade-gold py-[30px] px-[46px]'>
          <p className='basic-black max-w-[405px] text-center paragraph-1'>Thank you for subscribing!</p>
        </div>
      </Modal>
      <div className="accent-container lg:px-[96px] px-[58px]">
        <div className="internal-container">
          <section className="lg:my-[36px] mb-[30px]">
            <img src={logowhite} className="lg:inline hidden"></img>
            <img src={logowhitemobile} className="lg:hidden inline h-[55px] pl-[10px]"></img>
            {isAuthenticated ? <>
              <button onClick={() => navigate("/dashboard")} className={`nav-gold-button mx-[42px] ${window.location.pathname === "/main-dashboard" ? "hidden" : ""}`}>Dashboard</button>
            </> : <>
              <button className="gold-button lg:mx-[42px] ml-[69px]" onClick={() => loginWithRedirect({
                screen_hint: 'signup',
                audience: `https://${domain}/api/v2/`,
                scope: "profile", // new
              })}>Sign Up</button>
            </>}
          </section>
          <hr></hr>
          <section className="lg:mt-[66px] mt-[30px] lg:flex lg:space-x-[70px]">
            <div>
              <p className="accent-1 brand-light-grey lg:mr-[17px] lg:mb-[89px] mb-[30px] whitespace-nowrap lg:whitespace-normal">Wealth Management<br className="lg:block hidden" /><span className="inline lg:hidden"> </span>for The Rest Of Us</p>
              <div className="lg:flex gap-[22.22px] hidden">
                <a href="https://twitter.com/KairosFinancial" target="_blank"><img src={twitter} alt="twitter"></img></a>
                <a href="https://www.instagram.com/kairos.financial/" target="_blank"><img src={instagram} alt="twitter"></img></a>
                <a href="https://www.linkedin.com/company/kairos-financial/" target="_blank"><img src={linkedin} alt="twitter"></img></a>
              </div>
            </div>



            <div className="flex flex-col">
              {isAuthenticated ? <></> : <>
                <div className="lg:block hidden">
                  <input value={emailSub} className="w-[600px] bg-black basic-white paragraph-1 subscription-input" placeholder="Enter your email here to receive updates from Kairos!" onChange={(e) => setEmailSub(e.target.value)} />
                  <button className="gold-button !py-[16px]" onClick={() => onSubmit()}>Subscribe</button>
                </div>
              </>}

              <div className="lg:mt-[66px] mt-[30px] lg:flex lg:space-x-[70px]">
                <div className="flex lg:flex-col flex-row justify-between">
                  <div className="flex flex-col whitespace-nowrap lg:space-y-[10px] mb-[31px] lg:mb-0">
                    <p className="hidden lg:inline accent-2 brand-light-grey mb-[21px]">PAGES</p>
                    <button onClick={() => navigate("/")} className="text-left accent-3 brand-light-grey">Home</button>
                    <button onClick={() => navigate("/company")} className="text-left accent-3 brand-light-grey">Company</button>
                    <button onClick={() => navigate("/faq")} className="text-left accent-3 brand-light-grey">FAQ</button>
                    <button onClick={() => navigate("/articles")} className="text-left accent-3 brand-light-grey">Articles</button>
                  </div>
                  <div className="flex lg:hidden brand-light-grey flex-col justify-between">
                    <div className="flex brand-light-grey flex-col space-y-1">
                      {isAuthenticated ? <>
                        <button onClick={() => { console.log(window.location.origin + '/loggedout'); logout({ returnTo: window.location.origin + '/loggedout' }) }} className="accent-1-mobile brand-light-grey text-right">Log Out</button>
                        {/* <a className="accent-1-mobile brand-light-grey text-right" href="https://kairos-financial.getrewardful.com/signup">Become an Affiliate</a> */}
                      </> : <>
                        <button className="accent-1-mobile brand-light-grey text-right" onClick={() => loginWithRedirect()}>Log In</button>
                        <button className="accent-1-mobile brand-light-grey text-right" onClick={() => loginWithRedirect({
                          screen_hint: 'signup',
                          audience: `https://${domain}/api/v2/`,
                          scope: "profile", // new
                        })}>Sign Up</button>
                      </>}

                    </div>
                    <div className="flex gap-[22.22px] mb-[58px]">
                      <a href="https://twitter.com/KairosFinancial" target="_blank"><img src={twitter} alt="twitter"></img></a>
                      <a href="https://www.instagram.com/kairos.financial/" target="_blank"><img src={instagram} alt="twitter"></img></a>
                      <a href="https://www.linkedin.com/company/kairos-financial/" target="_blank"><img src={linkedin} alt="twitter"></img></a>
                    </div>
                  </div>
                </div>
                <div className="lg:flex hidden flex-col whitespace-nowrap space-y-[10px]">
                  <p className="accent-2 brand-light-grey mb-[21px]">COMPANY</p>
                  <a href="https://www.termsfeed.com/live/6d20504f-675c-4b7d-9de1-19087a6660af" className="text-left accent-3 brand-light-grey">Terms & Conditions</a>
                  <a href="https://www.termsfeed.com/live/35ace1c2-2014-488e-ba08-3c3a70927de3" className="text-left accent-3 brand-light-grey">Privacy Policy</a>
                </div>
                <div className="lg:flex flex-col hidden whitespace-nowrap space-y-[10px]">
                  <p className="accent-2 brand-light-grey mb-[21px]">ACCOUNT</p>
                  {isAuthenticated ? <>
                    <button onClick={() => navigate("/dashboard")} className="accent-3 brand-light-grey inline text-left">Dashboard</button>
                    <FooterLogoutButton />
                    {/* <a className="accent-3 brand-light-grey inline text-left" href="https://kairos-financial.getrewardful.com/signup">Become an Affiliate</a> */}
                  </> : <>
                    <button className="accent-3 brand-light-grey inline text-left" onClick={() => loginWithRedirect()}>Log In</button>
                    <button className="accent-3 brand-light-grey inline text-left" onClick={() => loginWithRedirect({
                      screen_hint: 'signup',
                      audience: `https://${domain}/api/v2/`,
                      scope: "profile", // new
                    })}>Sign Up</button>
                  </>}

                </div>
                <div className="lg:flex hidden flex-col whitespace-nowrap space-y-[10px]">
                  <p className="accent-2 brand-light-grey mb-[21px]">HELP</p>
                  <button onClick={() => window.Beacon('open')} className="accent-3 brand-light-grey">Contact Us</button>
                </div>
              </div>

            </div>
          </section>
          <section className="lg:hidden flex footer-bottom-mobile brand-light-grey justify-center">
            <p className="whitespace-pre">
              <a href="https://www.termsfeed.com/live/6d20504f-675c-4b7d-9de1-19087a6660af" className="text-left brand-light-grey">Terms & Conditions</a>   |   <a href="https://www.termsfeed.com/live/35ace1c2-2014-488e-ba08-3c3a70927de3" className="text-left brand-light-grey">Privacy Policy</a>
            </p>


          </section>
          <p className="copyright lg:pb-0 pb-[15px] text-center lg:text-left">(c) 2023 Bay Front Ventures Inc.</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;