
import './SavingsGoals.css';
import React, { useEffect, useState } from 'react';
import InternalFooter from './Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import postLoadSaveData from '../../utils/postLoadSaveData';
import { useContext, createContext } from 'react';
const logo = require('../../assets/logo-color.png');
const piggy_bank = require('../../assets/icons/piggy-bank-gold.svg').default;

const UserContext = createContext()

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local'){
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

const SavingsGoalsUI = () => {
  const [goal, setGoal] = useState("");

   // Get token function, urls
   const { getAccessTokenSilently, user } = useAuth0();
   const baseURL = apiBaseURL;
   const url = `${baseURL}financial_discovery/savings_object/`;
   const email = useContext(UserContext)

  // Enter key press
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      postOnClick();
    }
  };

  // Normal typing
  const handleChange = (e) => {
    setGoal(e.target.value);
  };
 
  // Post data on button click
  const postOnClick = async () => {

    if (goal!=="") {
      const payload = [{
        email: email,
        object_description: goal,
        object_type: "savings_goal"
        }]
        try {
          const token = await getAccessTokenSilently();
          // console.log("Got new token for function: " + token)
          // Execute the api method by calling the function
          const response = await postLoadSaveData(url, payload, token);
          console.log(response);
        } catch (err) {
          console.log(err);
        } finally {
        navigate("/savings-goals-success")
        }
    } else {
      navigate("/investing")
    }
    
  }

  // Post data on button click END

  const navigate = useNavigate();
  const location = useLocation();
  
  return (
    <>
      <div className='w-screen m-0'>
          <div className='flex flex-row min-h-[85vh]'>
            <nav className="hidden lg:flex flex-col flex-nowrap bg-white basis-[312px] shrink-0 grow-0">
              <img src={logo} className="w-[264.15px] h-[65px] mt-[41px] self-center" alt="KairosLogo" />
              <div className="onboarding-bar w-[30px] h-[266px] rounded-full mt-[75px] ml-[59px]">
                <div className="bar-progress w-[30px] h-[149px] rounded-full"><div className="bar-dot w-[10px] h-[10px] rounded-full top-[10px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Tell Us About Yourself</p></div></div>
                <div className="bar-dot w-[10px] h-[10px] rounded-full top-[123px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Set Your Goals</p></div>
                <div className="bar-dot w-[10px] h-[10px] rounded-full top-[240px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Connect Your Accounts</p></div>
              </div>
            </nav>
            <div className='bg-neutral-25 pb-[70px] w-[100%]'>
              <div className='mt-[50px] lg:mt-[106px] mb-[50px] lg:mb-[100px] mx-[30px] lg:mx-[109px]'>
                <img src={piggy_bank} alt='piggy_bank' className='w-[200px] h-[200px] mx-auto' />
                <h1 className='text-center'>What are you saving for?</h1>
                <p className='paragraph-1 text-center'>A house, a car, a wedding - whatever your goal is, we’ll help you save up to achieve it.
                  Please be as specific as you’d like. If your’e not currently saving for anything, just continue on.</p>
                <input id="goalInput" className='mx-auto mt-[75px] w-[619px] block' type='text' name='goal' value={goal} onChange={handleChange} onKeyDown={handleKeyPress}></input>
              </div>
            </div>
          </div>

          <div className='flex flex-col-reverse lg:flex-row justify-between align-middle py-[37px] px-[119px] top-shadow'>
            <button className='text-only-button mt-[15px] lg:mt-0' type='button' onClick={() => navigate(-1)}>Go Back</button>
            <button id='saveButton' className='gold-button' type='button' onClick={() => postOnClick()}>Next</button>
          </div>

          
        
        <InternalFooter></InternalFooter>
      </div>



    </>
  )
}

const SavingsGoals = () => {
  const {user, isAuthenticated, isLoading, loginWithRedirect} = useAuth0();
  isAuthenticated && console.log(user);
  if (isLoading) return <div>Loading...</div>; // Todo: Add a loading component
  // Redirect to home if not authenticated
  !isAuthenticated && loginWithRedirect()

  return (
    // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
    isAuthenticated && <UserContext.Provider value={user.email}><SavingsGoalsUI email={user.email} /></UserContext.Provider>
  )
}

export default SavingsGoals