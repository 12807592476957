// Function to post data to the Auth0 protected API endpoint
// Meant to be used when a page/component is mounted or is loaded

async function postLoadSaveData(url, payload, token) {
    let options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(payload)
    };

    try {

        const response = await fetch(url, options)

        if (!response.ok) {
            // console.log('this is the response: ', response.status)
            // return status code
            return response;
            // throw new Error(`Error! status: ${response.status}`);
            // throw new Error(`Error! status: ${response.status}`);
        }

        
        const result = await response.status;
        console.log(result)
        return result;

    }
    catch (err) {
        console.log(err);
    }
    
}
export default postLoadSaveData;