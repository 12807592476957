import Footer from '../../components/Footer';
import { useAuth0 } from '@auth0/auth0-react';
const laptop = require('../../assets/AppleMacbookPro15_ Silver1.png')
const one = require('../../assets/step-one.png')
const two = require('../../assets/step-two.png')
const three = require('../../assets/step-three.png')
const steps = require('../../assets/three-steps.png')
const original = require('../../assets/icons/original.svg').default;
const personalized = require('../../assets/icons/personalized.svg').default;
const oneonone = require('../../assets/icons/oneonone.svg').default;
const investment = require('../../assets/onboarding/investing.svg').default;
const savings = require('../../assets/onboarding/savings.svg').default;
const insurance = require('../../assets/onboarding/insurance.svg').default;
const loans = require('../../assets/onboarding/loans.svg').default;

const running_env = process.env.REACT_APP_RUNNING_ENV;

const domain = running_env === 'development' || running_env === 'local' ? process.env.REACT_APP_AUTH0_DOMAIN_DEV : process.env.REACT_APP_AUTH0_DOMAIN_PROD;
//const isProduction = running_env === 'development' || running_env === 'local' ? false : true;

const Features = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const domain = 'dev-wwq74ogsx2bab8kh.us.auth0.com';
  return (
    <>
      <div className='large-container bg-white'>
        <div className='main-container mx-auto text-center flex flex-col items-center'>
          <p className='neutral-400 accent-callout mt-16 lg:px-0 px-[30px]'>EVERYONE DESERVES FINANCIAL WELL-BEING</p>
          <h1 className="max-w-[1010px] lg:px-0 px-[30px]">Our mission is to guide you to the right financial solutions for your life</h1>
          <p className='paragraph-1 neutral-500 max-w-[627px] lg:px-0 px-[30px]'>We independently match you and your goals with the tools that work best for you so you can achieve clarity, confidence, and control over your money.</p>
          <img src={laptop} className="lg:max-w-[946px] max-w-full mb-[50px] lg:px-0 px-[20px]" alt="Kairos app in Laptop"></img>
          <button className='gold-button' onClick={() => loginWithRedirect({
            screen_hint: 'signup',
            audience: `https://${domain}/api/v2/`,
            scope: "profile", // new
          })}>Sign Up</button>
        </div>
      </div>
      <div className='large-container bg-white-to-gold'>
        <div className='main-container mx-auto text-center flex flex-col items-center'>
          <p className='neutral-400-to-white accent-callout'>HOW DOES IT WORK?</p>
          <h1 className="max-w-[1010px]">Our Process</h1>
          <div className='internal-container lg:flex flex-row gap-[124px] justify-center hidden'>
            <img src={one} alt='Step one'></img>
            <img src={two} alt='Step two'></img>
            <img src={three} alt='Step three'></img>
          </div>
          <img src={steps} alt='Line' className='my-[26px] lg:block hidden'></img>
          <div className='internal-container flex lg:flex-row flex-col lg:gap-[124px] gap-[38px] justify-center text-left'>
            <div>
              <img src={one} className='max-w-[52%] lg:hidden block my-[25px]' alt='Step one'></img>
              <span className='accent-6 brand-gold-to-white'>01. </span><span className='accent-6'>Fill us in</span>
              <p className='w-[280px] paragraph-1 mt-[15px]'>Tell us a bit about your goals, experience, and current financial situation (we promise it’ll take less than 10 minutes).</p>
            </div>
            <div>
              <img src={two} className='max-w-[52%] lg:hidden block my-[25px]' alt='Step two'></img>
              <span className='accent-6 brand-gold-to-white'>02. </span><span className='accent-6'>Get a customized plan</span>
              <p className='w-[280px] paragraph-1 mt-[15px]'>We’ll work a little magic to create a plan that will help you achieve financial well-being — without a guy in a suit.</p>
            </div>
            <div>
              <img src={three} className='max-w-[52%] lg:hidden block my-[25px]' alt='Step three'></img>
              <span className='accent-6 brand-gold-to-white'>03. </span><span className='accent-6'>Become your own expert</span>
              <p className='w-[280px] paragraph-1 mt-[15px]'>You’ll get access to your personal dashboard, where you can find all the resources we’ve put together to help you achieve your goals.</p>
            </div>
          </div>
          <button className='gold-button mt-[69px] hidden lg:block' onClick={() => loginWithRedirect({
            screen_hint: 'signup',
            audience: `https://${domain}/api/v2/`,
            scope: "profile", // new
          })}>Sign Up</button>
        </div>
      </div>
      <div className='text-center'>
        <button className='neutral-button mt-[25px] mb-[75px] lg:hidden' onClick={() => loginWithRedirect({
          screen_hint: 'signup',
          audience: `https://${domain}/api/v2/`,
          scope: "profile", // new
        })}>Sign Up</button>
      </div>

      <div className='large-container bg-white'>
        <p className='neutral-400 accent-callout mx-auto text-center'>WHAT ELSE YOU'LL GET</p>
        <h1 className="max-w-[1010px] mx-auto text-center mb-[60px]">Features</h1>
        <div className='internal-container bg-white text-center lg:px-0 px-[25px]'>
          <div className='text-left rounded-[20px] bg-fade-gold lg:p-[30px] p-[35px] w-full lg:w-[391px] inline-block lg:mr-[30px] lg:mb-[15px] relative lg:top-[-15px] top-[-25px]'>
            <img src={original} alt='original-icon'></img>
            <p className='accent-1 my-[30px]'>Original Blogs & Articles</p>
            <p className='paragraph-2-medium basic-white'>Whether you invest in hedgefunds or don’t know your IRA from your 401(k), we’re committed to continuously publishing content and resources to help you along your journey to financial well-being.</p>
          </div>
          <div className=' text-left inline-block lg:w-[720px] w-full'>
            <div className='rounded-[20px] bg-fade-gold lg:pb-[10px] lg:pt-[30px] lg:pl-[30px] lg:pr-[38px] lg:mb-[15px] p-[35px] mb-[25px]'>
              <div className='mb-[15px]'>
                <img src={personalized} className='inline mr-[30px]' alt='personalized-icon'></img>
                <p className='accent-1 mb-[30px] inline'>Personalized Roadmap</p>
              </div>
              <p className='paragraph-2-medium basic-white'>Tell us about your goals and financial situation and we’ll create a personalized roadmap, updated quarterly, to help you reach them.</p>
            </div>
            <div className='rounded-[20px] bg-fade-gold lg:pb-[10px] lg:pt-[30px] lg:pl-[30px] lg:pr-[38px] p-[35px] lg:mb-0 mb-[25px]'>
              <div className='mb-[15px]'>
                <img src={oneonone} className='inline mr-[30px]' alt='oneonone-icon'></img>
                <p className='accent-1 inline'>One-on-One Coaching and Support</p>
              </div>
              <p className='paragraph-2-medium basic-white'>No longer just for the ultra-rich, we provide you with the opportunity to talk to our advisors 1-on-1 any time — regardless of your income.</p>
            </div>
          </div>
          <div>
            <button className='neutral-button' onClick={() => loginWithRedirect({
              screen_hint: 'signup',
              audience: `https://${domain}/api/v2/`,
              scope: "profile", // new
            })}>Sign Up</button>
          </div>
        </div>

      </div>
      <div className='large-container bg-white mt-[80px] mb-[160px]'>
        <div className='internal-container text-center'>
          <p className='neutral-400 accent-callout mx-auto'>MORE THAN JUST STOCKS</p>
          <h1 className="max-w-[1010px] mx-auto">Holistic Financial Help</h1>
          <div className='flex lg:flex-row flex-col text-center gap-[73px] items-start justify-center'>
            <div className='w-[222px]'>
              <div className='h-[205px]'>
                <img src={investment} className='mx-auto' alt='Investment'></img>
              </div>
              <h3 className='mt-[50px] mb-[25px]'>Investment</h3>
              <p className='neutral-paragraph neutral-500'>Rome wasn’t built in a day, and your investment portfolio shouldn’t be either. We’ll help you invest better, no matter your income or experience level.</p>
            </div>
            <div className='w-[222px]'>
              <div className='h-[205px]'>
                <img src={savings} className='mx-auto max-h-[205px]' alt='Savings'></img>
              </div>
              <h3 className='mt-[50px] mb-[25px]'>Savings</h3>
              <p className='neutral-paragraph neutral-500'>Make your money work as hard as you do. Get cash back and no maintenance fees with an account that benefits your bottom line.</p>
            </div>
            <div className='w-[222px]'>
              <div className='h-[205px]'>
                <img src={insurance} className='mx-auto max-h-[205px]' alt='Insurance'></img>
              </div>
              <h3 className='mt-[50px] mb-[25px]'>Insurance</h3>
              <p className='neutral-paragraph neutral-500'>We all deserve to feel secure, and insurance is a great financial tool that can provide you peace of mind with your home, apartment, car, pet, or your life.</p>
            </div>
            <div className='w-[222px]'>
              <div className='h-[205px]'>
                <img src={loans} className='mx-auto max-h-[205px]' alt='Loans'></img>
              </div>
              <h3 className='mt-[50px] mb-[25px]'>Loans</h3>
              <p className='neutral-paragraph neutral-500'>Healthy debt can help you live a better financial life. From student loans to low-interest home mortgages, we help you make debt work for you. </p>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  )
}

export default Features
