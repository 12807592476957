import {usePlaidLink} from "react-plaid-link";
import {useEffect, useState, createContext, useContext} from 'react'
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import postLoadSaveData from "../utils/postLoadSaveData";

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local'){
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

axios.defaults.baseURL = apiBaseURL;

function PlaidUpdateRemove(props) {
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const [linkToken, setLinkToken] = useState();

  const payload = {
      email: props.email,
      item_id: props.item_id
  }

  useEffect(() => {
    async function fetch() {
      // Get auth0 token
      const token = await getAccessTokenSilently();
      // console.log("Primer: ", token)
      const response = await axios.post("connections/get_link_token_update", payload, { headers: {"Authorization" : `Bearer ${token}`} });
      // console.log("Link Token: ", response.data.link_token)
      setLinkToken(response.data.link_token);
      return () => {}
    }
  fetch();

  }, []);

  const { open, ready } = usePlaidLink({
      token: linkToken,
  
      onSuccess: (public_token, metadata) => {
        console.log("Update mode: Launch succcessful")
      },
    });

  // Check if authenticated
  if (isAuthenticated) {
    return(<button className='gold-button' onClick={() => open()} disabled={!ready}>
      {ready ? "Update or Reconnect" : "Initializing"}
  </button>)
  }
  else{
      return <button className="gold-button mx-auto" onClick={loginWithRedirect}>Login</button>;
  }
}

export default PlaidUpdateRemove