
import React, { useState, useRef, useContext, useEffect, createContext } from 'react';
import InternalFooter from './Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import postLoadFetchData from '../../utils/postLoadFetchData';
const logo = require('../../assets/onboarding/logo.svg').default;
const finances = require('../../assets/onboarding/finances.svg').default;
const savings = require('../../assets/onboarding/savings.svg').default;
const investing = require('../../assets/onboarding/investing.svg').default;
const loans = require('../../assets/onboarding/loans.svg').default;
const insurance = require('../../assets/onboarding/insurance.svg').default;
const arrow = require('../../assets/icons/arrow-fill.svg').default;
const UserContext = createContext()

const cards = [
  {
    picture: savings,
    action1: "#",
    title: "Earn more on my savings",
    body: "Want to make sure your savings are working for you? We’ll help you find the right accounts so your money can make more money. "
  },
  {
    picture: investing,
    action1: "#",
    title: "Start investing",
    body: "Think you’re ready to start investing? Or want help optimizing your portfolio? Either way, we’ll help you find the right path forward."
  },
  {
    picture: loans,
    action1: "#",
    title: "Optimize my loans",
    body: "Debt can be healthy — let us know what loans you have and we’ll help you strategize to pay them off the right way."
  },
  {
    picture: insurance,
    action1: "#",
    title: "Get better insurance",
    body: "Balance your insurance payments with the benefits and coverage you’re getting. We’ll help make sure you’ve got the right plans."
  },
  {
    picture: finances,
    action1: "#",
    title: "Review my overall personal finances",
    body: "Tell us more about your current status and we will help you optimize for your future and goals."
  }]
const LoadWelcome = () => {

  const email = useContext(UserContext);

  return (
    <>
      <div className='w-screen m-0'>
        <Fields email={email}></Fields>
        <InternalFooter></InternalFooter>
      </div>



    </>
  )
}

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local'){
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

function Fields(props) {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const email = props.email;
  const [profileData, setProfileData] = useState(null);
  const baseURL = apiBaseURL;
  const url = `${baseURL}financial_discovery/financial_profile/?email=${email}&mode=profile`;
  const urlsubmit = `${baseURL}financial_discovery/financial_summary/`;
  const navigate = useNavigate();

  function submit() {
    const payload = {
      email: profileData.user_email?profileData.user_email:"",
      general_goal: selection,
      employee_status: profileData.financial_profile.financial_summary.employee_status?profileData.financial_profile.financial_summary.employee_status:"",
      gig_work: profileData.financial_profile.financial_summary.gig_work?profileData.financial_profile.financial_summary.gig_work:"NA",
      self_assessment: profileData.financial_profile.financial_summary.self_assessment?profileData.financial_profile.financial_summary.self_assessment:"",
      owner_status: profileData.financial_profile.financial_summary.owner_status?profileData.financial_profile.financial_summary.owner_status:""
    }
    console.log(payload)
    navigate("/employment-status", { state: payload })

  };
  const ref = useRef(null);
  const [cardSelected, setCardSelected] = useState(4);
  const [selection, setSelection] = useState("Review my overall personal finances");

  const autoScroll = () => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  const location = useLocation();

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        console.log("Got new token for function: " + token)
        // Execute the api method by calling the function
        const consolidate = await postLoadFetchData(url, token);
        setProfileData(consolidate);
        setSelection(()=>{
          if (consolidate.financial_profile.financial_summary.general_goal) {
            return consolidate.financial_profile.financial_summary.general_goal;
          } else {
            return "Review my overall personal finances"
          }
        })
        setCardSelected(()=>{
          if (consolidate.financial_profile.financial_summary.general_goal) {
            return cards.findIndex(element=> element.title === consolidate.financial_profile.financial_summary.general_goal)
          } else {
            return 4;
          }
        })
        console.log('data: ', profileData)
        console.log('user', user)


      } catch (err) {
        console.log(err);
      }
    })();
  }, []);



  return (
    (user) && <>
      <form onSubmit={submit}>
        <div className='flex flex-row min-h-[85vh]'>
          <div className='bg-white pb-[70px] w-[100%]'>
            <div className='mt-[106px] mb-[50px] mx-[109px]'>
              <img src={logo} className="h-[65px] mx-auto" alt="logo" />
              <h1 className='text-center mt-[85px]'>What can we help you with today?</h1>
            </div>
            <div className='grid grid-cols-1 gap-12 mx-auto w-[832px]'>
              {cards.map((card, index) => (
                <div key={index}>
                  <input id={index} type="radio" value={card.title} name='selection' className='hidden' onChange={(e) => { setSelection(e.target.value); console.log(e.target.value) }} /*onClick={autoScroll}*/ />
                  <label htmlFor={index}>
                    <div className='onboarding-list relative' onClick={() => setCardSelected(index)} style={{ backgroundColor: cardSelected === index ? "rgba(247, 189, 132, 0.8)" : "" }}>
                      <img src={card.picture} className="h-[100%]" alt={card.title} />
                      <div className='absolute left-[220px] w-[530px] self-center'>
                        <p className='list-title'>{card.title}</p>
                        <p className='list-body mt-[25px]'>{card.body}</p>
                      </div>
                      <img src={arrow} className="w-[24px] absolute right-[29px] top-1/2" alt="arrow" />
                    </div>
                  </label>
                </div>

              ))}
            </div>


          </div>
        </div>
        <div className='flex flex-col-reverse lg:flex-row justify-between align-middle py-[37px] px-[119px] top-shadow'>
          <button className='text-only-button' onClick={() => navigate(-1)}>Go Back</button>
          <button ref={ref} className='gold-button' type='submit'>Next</button>
        </div>
      </form>

    </>
  )

}

const Welcome = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  isAuthenticated && console.log(user);

  // Redirect to home if not authenticated
  !isLoading && !isAuthenticated && loginWithRedirect()

  return (
    // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
    isAuthenticated && <UserContext.Provider value={user.email}><LoadWelcome email={user.email} /></UserContext.Provider>
  )
}

export default Welcome