import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import postLoadSaveData from "../../utils/postLoadSaveData";
import postLoadDeleteData from "../../utils/postLoadDeleteData";
import postLoadFetchData from '../../utils/postLoadFetchData';
import { useParams, useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer';
import Modal from 'react-modal';

//const bookmark_on = require('../../assets/icons/big-bookmark-on.png');
const bookmark_off = require('../../assets/icons/bookmark-off-gold.svg').default;
const bookmark_off_white = require('../../assets/icons/bookmark-off.svg').default;
// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
    apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
    apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local') {
    apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

const domain = running_env === 'development' || running_env === 'local' ? process.env.REACT_APP_AUTH0_DOMAIN_DEV : process.env.REACT_APP_AUTH0_DOMAIN_PROD;


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        background: 'white',
        'border-radius': '10px',
        padding: '0px'
    },
};


function LoadArticle() {
    const params = useParams();
    const { user, isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const [article, setArticle] = useState(null);
    const baseURL = apiBaseURL;
    const url = `${baseURL}client_extras/marketing/articles_by_slug/?slug=${params.slug}`;
    const bookmarkUrl = `${baseURL}client_extras/bookmarks/`;
    const navigate = useNavigate();

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        (async () => {
            try {
                let options = {
                    method: 'GET'
                };
                const response = await fetch(url, options);
                const currentArticle = await response.json();
                setArticle(currentArticle);
                console.log(currentArticle)
            } catch (err) {
                console.log(err);
            }
        })();
    }, []);

    // Post data on button click

    const bookmark = async (id, status) => {
        var article_temp = { ...article };
        article_temp.bookmarked = !article_temp.bookmarked;
        const payload = {
            "email": user.email,
            "article_id": id
        };
        console.log('temp', article_temp);
        setArticle(article_temp);
        if (status) {
            try {
                const token = await getAccessTokenSilently();
                // console.log("Got new token for function: " + token)
                // Execute the api method by calling the function
                const response = await postLoadDeleteData(bookmarkUrl, payload, token);
                console.log(response);

            } catch (err) {
                console.log(err);
            }
        } else {
            try {
                const token = await getAccessTokenSilently();
                // console.log("Got new token for function: " + token)
                // Execute the api method by calling the function
                const response = await postLoadSaveData(bookmarkUrl, payload, token);
                console.log(response);

            } catch (err) {
                console.log(err);
            }
        }


    }

    if (!article) return <div>Loading Resources...</div>; // Todo: Add a loading component

    return (
        (article) && <>
            <div>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Sign in to bookmark"
                >
                    <div className='bg-fade-gold py-[30px] px-[46px]'>
                        <img src={bookmark_off_white} className='h-[90px] mx-auto mb-[30px]' alt='bookmark'></img>
                        <h3 className='basic-white h3-bold mb-[15px]'>Log in to save this article.</h3>
                        <p className='basic-white max-w-[405px] text-center paragraph-1'>We’re stoked that you want to come back to this - great choice! <button className='underline font-bold' onClick={() => loginWithRedirect({
                            screen_hint: 'signup',
                            audience: `https://${domain}/api/v2/`,
                            scope: "profile", // new
                        })}>Sign up</button> or <button onClick={() => loginWithRedirect()} className='underline font-bold'>log in</button> to save it for later.</p>
                    </div>

                </Modal>
                <div className="main-container relative">
                    <button className="text-only-button ml-[25px]" onClick={() => navigate("/articles")}><span className="pl-[20px]"></span>Back To Articles</button>
                    <div onClick={openModal} className="w-[75px] h-[75px] absolute top-[42px] right-[60px] flex justify-center items-center bg-white rounded-[5px] drop-shadow-[0px_3px_12px_rgba(0,0,0,0.08)] cursor-pointer">
                        <img src={bookmark_off} className="w-[38px] h-[48px] inline-block"></img>
                    </div>
                    <div className="text-center mt-[45px] mb-[31px]">
                        <h2 className="">{article.title}</h2>
                        <h3 className="brand-gold mt-[14px]">{new Date(article.created_at).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}</h3>
                    </div>
                    <img className="w-[800px] mx-auto bg-neutral-25 rounded-[10px]" src={baseURL.slice(0, -1) + article.image} alt='image'></img>
                    <div className="text-left w-[800px] mx-auto mt-[47px] mb-[61px] whitespace-pre-wrap paragraph-1">{article.content}</div>
                </div>

            </div>
            <Footer></Footer>
        </>

    )
}

const PublicArticle = () => {
    const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    isAuthenticated && console.log(user);
    if (isLoading) return <div>Loading Kairos...</div>; // Todo: Add a loading component

    // Redirect to home if not authenticated

    return (
        // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
        <LoadArticle />
    )

}

export default PublicArticle