import Payment from './Payment'
import Success from './Success'

import {useContext, useEffect, useState, createContext} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import {loadStripe} from '@stripe/stripe-js';

import { useAuth0 } from '@auth0/auth0-react';
import postLoadFetchData from '../../utils/postLoadFetchData';

const UserContext = createContext()

const LoadStart = () => {
  const { getAccessTokenSilently } = useAuth0();
  const email = useContext(UserContext);
    // env
    const running_env = process.env.REACT_APP_RUNNING_ENV;
    var apiBaseURL = '';
    const isProduction = running_env === 'development' || running_env === 'local' ? false : true;

    if (running_env === 'development') {
    apiBaseURL = process.env.REACT_APP_API_URL_DEV;
    } else if (running_env === 'production') {
    apiBaseURL = process.env.REACT_APP_API_URL_PROD;
    } else if (running_env === 'local'){
    apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
    }

    const baseURL = apiBaseURL;
  const configUrl = `${baseURL}subscription/config/`;
  const profileUrl = `${baseURL}financial_discovery/financial_profile/?email=${email}&mode=profile`;

  const [ stripePromise, setStripePromise ] = useState(null);
  const [profileData, setProfileData] = useState(null);
  useEffect(() => {
    fetch(configUrl).then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
    (async()=>{
      try{
        const token = await getAccessTokenSilently();
        const consolidate = await postLoadFetchData(profileUrl, token);
        setProfileData(consolidate.status ? null : consolidate);
        console.log(consolidate);
      } catch(err) {
        console.log(err)
      }})()
  }, []);

  return (
    profileData && <main>
      <Payment stripePromise={stripePromise} email={email} name={`${profileData.financial_profile.personal_info.user.name} ${profileData.financial_profile.personal_info.user.lastname}`} phone={profileData.financial_profile.personal_info.user.phone_number} zip={profileData.financial_profile.personal_info.user_address.zip_code}
      city={profileData.financial_profile.personal_info.user_address.city}
      state={profileData.financial_profile.personal_info.user_address.state}
      line1={profileData.financial_profile.personal_info.user_address.street}
       />
    </main>
  );
}

const Start = (props) => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  isAuthenticated && console.log(user);

  // Redirect to home if not authenticated
  !isLoading && !isAuthenticated && loginWithRedirect()

  return (
      // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
      isAuthenticated && <UserContext.Provider value={user.email}><LoadStart email={user.email} /></UserContext.Provider>
  )
}

export default Start