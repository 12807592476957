import Footer from '../../components/Footer';
import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import postLoadSaveData from "../../utils/postLoadSaveData";
import postLoadDeleteData from "../../utils/postLoadDeleteData";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
const bookmark_on = require('../../assets/icons/bookmark-on.svg').default;
const bookmark_off = require('../../assets/icons/bookmark-off.svg').default;
const arrow = require('../../assets/down-arrow.svg').default;
const spinner = require('../../assets/icons/spinner.png');

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        background: 'white',
        'border-radius': '10px',
        padding: '0px'
    },
    overlay: { zIndex: 1000 }
};

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
    apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
    apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local') {
    apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

const domain = running_env === 'development' || running_env === 'local' ? process.env.REACT_APP_AUTH0_DOMAIN_DEV : process.env.REACT_APP_AUTH0_DOMAIN_PROD;

function LoadArticles() {

    const { user, isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();

    return (
        <>
            <div>
                <div className="main-container">
                    <div className="w-[1020px] mx-auto">
                        <h2>Top Articles</h2>
                        <p className="paragraph-1-mobile pl-[28px] mt-[10px] mb-[30px]">The top articles on Kairos Financial.</p>
                        <PublicArticles></PublicArticles>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>

    )
}

function PublicArticles() {

    const { user, isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const [articles, setArticles] = useState(null);
    const [topArticles, setTopArticles] = useState(null);
    const [originalArticles, setOriginalArticles] = useState(null);
    const [sort, setSort] = useState("");
    const [filter, setFilter] = useState("");
    const baseURL = apiBaseURL;
    const url = `${baseURL}client_extras/marketing/articles/`;
    const bookmarkUrl = `${baseURL}client_extras/bookmarks/`;
    const navigate = useNavigate();

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        (async () => {
            try {

                // console.log("Got new token for function: " + token)
                // Execute the api method by calling the function
                const url = `${baseURL}client_extras/marketing/articles/`;
                let options = {
                    method: 'GET'
                };
                const response = await fetch(url, options);
                const data = await response.json();
                console.log('response', data);
                setArticles(data.sort((a, b) => {
                    if (a.id > b.id) return -1
                    if (a.id < b.id) return 1
                    return 0;
                }).map(a => a));
                setOriginalArticles(data);
                setTopArticles(data.sort((a, b) => {
                    if (a.reads > b.reads) return -1
                    if (a.reads < b.reads) return 1
                    return 0;
                }).slice(0, 4));

            } catch (err) {
                console.log(err);
            }
        })();
    }, []);

    // Post data on button click

    const bookmark = async (id, index, status) => {
        var articles_temp = articles.map(obj => ({ ...obj }));
        articles_temp[index].bookmarked = !articles_temp[index].bookmarked;
        const payload = {
            "email": user.email,
            "article_id": id
        };
        console.log('temp', articles_temp[index]);
        setArticles(articles_temp);
        if (status) {
            try {
                const token = await getAccessTokenSilently();
                // console.log("Got new token for function: " + token)
                // Execute the api method by calling the function
                const response = await postLoadDeleteData(bookmarkUrl, payload, token);
                console.log(response);

            } catch (err) {
                console.log(err);
            }
        } else {
            try {
                const token = await getAccessTokenSilently();
                // console.log("Got new token for function: " + token)
                // Execute the api method by calling the function
                const response = await postLoadSaveData(bookmarkUrl, payload, token);
                console.log(response);

            } catch (err) {
                console.log(err);
            }
        }


    }

    function apply(action, value) {
        if (action === 'filter') {
            const filtered = originalArticles.filter(article => {
                if (value === "ALL") {
                    return true
                } else {
                    return article.tags.some(e => e.name === value)
                }
            });
            const sorted = filtered.sort((a, b) => {
                if (sort === 'newest') {
                    if (a.id > b.id) return -1
                    if (a.id < b.id) return 1
                    return 0;

                } else if (sort === 'oldest') {
                    if (a.id > b.id) return 1
                    if (a.id < b.id) return -1
                    return 0;
                } else if (sort === 'popular') {
                    if (a.reads > b.reads) return -1
                    if (a.reads < b.reads) return 1
                    return 0;
                }
            })
            setArticles(sorted);
            console.log(sorted);
        }
        if (action === 'sort') {
            const filtered = originalArticles.filter(article => {
                if ((filter === "ALL") || (filter === "")) {
                    return true
                } else {
                    return article.tags.some(e => e.name === filter)
                }
            });
            const sorted = filtered.sort((a, b) => {
                if (value === 'newest') {
                    if (a.id > b.id) return -1
                    if (a.id < b.id) return 1
                    return 0;

                } else if (value === 'oldest') {
                    if (a.id > b.id) return 1
                    if (a.id < b.id) return -1
                    return 0;
                } else if (value === 'popular') {
                    if (a.reads > b.reads) return -1
                    if (a.reads < b.reads) return 1
                    return 0;
                }
            })
            setArticles(sorted);
            console.log(sorted);
        }

    }

    if (!articles) return <>
        <div className="pt-[28px] pl-[60px] text-center flex items-center gap-[60px] h-[344px]">
            <img src={spinner} className="animate-spin mx-auto" alt="Loading"></img>
        </div>
        <div className="relative">
            <div className="absolute top-0 right-0">
                <div className="mr-[15px] inline-block relative">
                    <img src={arrow} className="w-[12px] h-[7px] absolute right-[14px] top-[12px] pointer-events-none"></img>
                    <select className="small-select" value={filter}>
                        <option value="" disabled defaultValue hidden>Filter</option>
                        <option>Savings</option>
                        <option>Investing</option>
                        <option>Insurance</option>
                        <option>Loans</option>
                        <option>Overall</option>
                    </select>
                </div>
                <div className="inline-block relative">
                    <img src={arrow} className="w-[12px] h-[7px] absolute right-[14px] top-[12px] pointer-events-none"></img>
                    <select className="small-select" value={sort} onChange={(e) => { }}>
                        <option value="" disabled defaultValue hidden>Sort By</option>
                        <option value="newest">Newest First</option>
                        <option value="oldest">Oldest First</option>
                        <option value="popular">Most Popular</option>
                    </select>
                </div>

            </div>
            <h2 className="pl-[28px] mt-[30px]">All Articles</h2>
            <p className="paragraph-1-mobile pl-[28px] mt-[10px] mb-[30px]">Check back or make sure your email notifications are turned on for new articles.</p>
            <div className="pt-[28px] pl-[60px] text-left flex flex-col items-start gap-[45px]">
                <img src={spinner} className="animate-spin mx-auto" alt="Loading"></img>
            </div>
        </div>
    </>; // Todo: Add a loading component

    return (
        (articles && topArticles) && <>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Sign in to bookmark"
            >
                <div className='bg-fade-gold py-[30px] px-[46px]'>
                    <img src={bookmark_off} className='h-[90px] mx-auto mb-[30px]' alt='bookmark'></img>
                    <h3 className='basic-white h3-bold mb-[15px]'>Log in to save this article.</h3>
                    <p className='basic-white max-w-[405px] text-center paragraph-1'>We’re stoked that you want to come back to this - great choice! <button className='underline font-bold' onClick={() => loginWithRedirect({
                        screen_hint: 'signup',
                        audience: `https://${domain}/api/v2/`,
                        scope: "profile", // new
                    })}>Sign up</button> or <button onClick={() => loginWithRedirect()} className='underline font-bold'>log in</button> to save it for later.</p>
                </div>

            </Modal>
            <div className="pt-[28px] text-center flex flex-col lg:flex-row items-center gap-[60px]">
                {
                    topArticles.map((article) => {
                        let src = baseURL.slice(0, -1) + article.image
                        return (

                            <div className="basis-[221px] h-[316px] grow-0 shrink-0" key={article.id}>
                                <div className='w-[200px] h-[150px] rounded-[10px] overflow-hidden'>
                                    <img src={src} className="inline cursor-pointer object-fill min-h-[150px]" onClick={() => navigate(`/articles/${article.slug}`)}></img>
                                </div>
                                <p className="accent-6 mt-[30px] mb-[10px] cursor-pointer line-clamp-3" onClick={() => navigate(`/articles/${article.slug}`)}>{article.title}</p>
                                <p className="accent-2 neutral-600">{article.tags[0].name.toUpperCase()}</p>
                                <p className="paragraph-1-mobile brand-gold mt-[10px]">{new Date(article.last_updated).toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" })}</p>
                            </div>
                        )
                    }
                    )
                }
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0">
                    <div className="mr-[15px] inline-block relative">
                        <img src={arrow} className="w-[12px] h-[7px] absolute right-[14px] top-[12px] pointer-events-none"></img>
                        <select className="small-select" value={filter} onChange={(e) => { setFilter(e.target.value); console.log(e.target.value); apply('filter', e.target.value) }}>
                            <option value="" disabled defaultValue hidden>Filter</option>
                            <option value="savings">Savings</option>
                            <option value="investing">Investing</option>
                            <option value="insurance">Insurance</option>
                            <option value="loans">Loans</option>
                            <option value="overall">Overall</option>
                            <option value="ALL">All Articles</option>
                        </select>
                    </div>
                    <div className="inline-block relative">
                        <img src={arrow} className="w-[12px] h-[7px] absolute right-[14px] top-[12px] pointer-events-none"></img>
                        <select className="small-select" value={sort} onChange={(e) => { setSort(e.target.value); console.log(e.target.value); apply('sort', e.target.value) }}>
                            <option value="" disabled defaultValue hidden>Sort By</option>
                            <option value="newest">Newest First</option>
                            <option value="oldest">Oldest First</option>
                            <option value="popular">Most Popular</option>
                        </select>
                    </div>

                </div>
                <h2 className="pl-[28px] mt-[30px]">All Articles</h2>
                <p className="paragraph-1-mobile mt-[10px] mb-[30px]">Check back or make sure your email notifications are turned on for new articles.</p>
                <div className="pt-[28px] text-left flex flex-col items-start gap-[45px]">
                    {
                        articles.map((article, i) => {
                            let src = baseURL.slice(0, -1) + article.image
                            return (

                                <div className="w-[825px] h-[150px] flex" key={article.id}>
                                    <div className="block shrink-0 relative">
                                        <div className='w-[200px] h-[150px] rounded-[10px] overflow-hidden'>
                                            <img src={src} className="shrink-0 relative cursor-pointer object-fill min-h-[150px]" onClick={() => navigate(`/articles/${article.slug}`)}></img>
                                        </div>
                                        {article.bookmarked ?
                                            <img src={bookmark_on} className="w-[38px] h-[48px] absolute top-0 right-[10px] inline-block cursor-pointer" onClick={openModal}></img> :
                                            <img src={bookmark_off} className="w-[38px] h-[48px] absolute top-0 right-[10px] inline-block cursor-pointer" onClick={openModal}></img>}
                                    </div>
                                    <div className="pl-[30px] pt-[16px]">
                                        <p className="accent-6 mb-[15px] cursor-pointer" onClick={() => navigate(`/articles/${article.slug}`)}>{article.title}</p>
                                        <p className="paragraph-1-mobile basic-black overflow-hidden line-clamp-3">{article.content}</p>
                                        <p className="paragraph-1-mobile brand-gold mt-[15px] inline pr-[15px] shadow-[2px_0px_#F7BD84]">{new Date(article.last_updated).toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" })}</p>
                                        <p className="paragraph-1-mobile brand-gold mt-[15px] inline pl-[15px]">{article.tags[0].name.toUpperCase()}</p>
                                    </div>
                                </div>
                            )
                        }
                        )
                    }
                </div>
            </div>
        </>

    )
}

const Articles = () => {
    const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    isAuthenticated && console.log(user);
    if (isLoading) return <div>Loading Kairos...</div>; // Todo: Add a loading component

    // Redirect to home if not authenticated

    return (
        // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
        <LoadArticles />
    )

}

export default Articles