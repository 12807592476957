import React, { useEffect, useState, useRef, createContext, useContext } from 'react';
import InternalFooter from './Footer';
import { useNavigate, useLocation } from 'react-router-dom';
import postLoadSaveData from '../../utils/postLoadSaveData';
import { useAuth0 } from '@auth0/auth0-react';
import postLoadModifyData from '../../utils/postLoadModifyData';
import postLoadFetchData from '../../utils/postLoadFetchData';
const logo = require('../../assets/logo-color.png');
const leaf = require('../../assets/icons/leaf-gold.svg').default;
const branch = require('../../assets/icons/branch-gold.svg').default;
const plant = require('../../assets/icons/plant-gold.svg').default;
const tree = require('../../assets/icons/tree-gold.svg').default;
const target = require('../../assets/icons/target-gold.svg').default;
const leaf_white = require('../../assets/icons/leaf-white.svg').default;
const branch_white = require('../../assets/icons/branch-white.svg').default;
const plant_white = require('../../assets/icons/plant-white.svg').default;
const tree_white = require('../../assets/icons/tree-white.svg').default;
const target_white = require('../../assets/icons/target-white.svg').default;
const cards = [{
  label: "No knowledge or experience",
  icon: leaf,
  icon_white:leaf_white,
  action1: "#"
},
{
  label: "Limited knowledge or experience",
  icon: branch,
  icon_white:branch_white,
  action1: "#"
},
{
  label: "I manage my own personal finance",
  icon: plant,
  icon_white:plant_white,
  action1: "#"
},
{
  label: "I am a financial professional",
  icon: tree,
  icon_white:tree_white,
  action1: "#"
},
{
  label: "Other",
  icon: target,
  icon_white:target_white,
  action1: "#"
}]
const UserContext = createContext()
const LoadFinances = () => {
  const email = useContext(UserContext);  
  return (
    <>
      <div className='w-screen m-0'>
        <Fields email={email}></Fields>
        <InternalFooter></InternalFooter>
      </div>



    </>
  )
}

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local'){
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

function Fields(props) {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const email = props.email;
  const [profileData, setProfileData] = useState(null);
  const [tracker, setTracker] = useState(false);
  const baseURL = apiBaseURL;
  const url = `${baseURL}financial_discovery/financial_profile/?email=${email}&mode=profile`;
  const urltrack = `${baseURL}financial_discovery/financial_profile/?email=${email}&mode=track_onboarding`;
  const urlsubmit = `${baseURL}financial_discovery/financial_summary/`;

  const ref = useRef(null);

  const autoScroll = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
    ref.current?.focus();
  };

  const navigate = useNavigate();
  const location = useLocation();
  const payload = location.state;
  const [cardSelected, setCardSelected] = useState(()=>{
    if (payload.self_assessment!="") {
      return cards.findIndex(element=> element.label==payload.self_assessment);
    } else {
      return null
    }
  });
  const [selection, setSelection] = useState(()=>{
    if (payload.self_assessment!="") {
      return payload.self_assessment;
    } else {
      return ""
    }
  });
  const [cardActive, setCardActive] = useState(null);


// To be used to add the Back buttons
  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        console.log("Got new token for function: " + token)
        // Execute the api method by calling the function
        const consolidate = await postLoadFetchData(url, token);
        const track = await postLoadFetchData(urltrack, token);
        setTracker(track.financial_profile?.financial_summary);
        setProfileData(consolidate);
        console.log('data: ', profileData)
        console.log('user', user)


      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  // Post data on button click
  const postOnClick = async () => {
    payload.self_assessment = selection;
    payload.email = user.email;
    console.log(payload);
    try {
      const token = await getAccessTokenSilently();
      // console.log("Got new token for function: " + token)
      // Execute the api method by calling the function
      const response = await postLoadSaveData(urlsubmit, payload, token);
      console.log(response);
    } catch (err) {
      console.log(err);
    } finally {
      navigate("/goals")
    }

  }
  const putOnClickProfile = async () => {
    payload.self_assessment = selection;
    payload.email = user.email;
    console.log(payload);
    try {
        const token = await getAccessTokenSilently();
        // console.log("Got new token for function: " + token)
        // Execute the api method by calling the function
        const response = await postLoadModifyData(urlsubmit, payload, token);
        console.log(response);
    } catch (err) {
        console.log(err);
    } finally {
      navigate("/goals", {state: profileData});
    }
}

  // Post data on button click END



  return (
    (user) && <>
      <form onSubmit={tracker?putOnClickProfile:postOnClick}>
          <div className='flex flex-row min-h-[85vh]'>
            <nav className="hidden lg:flex flex-col flex-nowrap bg-white basis-[312px] shrink-0 grow-0">
              <img src={logo} className="w-[264.15px] h-[65px] mt-[41px] self-center" alt="KairosLogo" />
              <div className="onboarding-bar w-[30px] h-[266px] rounded-full mt-[75px] ml-[59px]">
                <div className="bar-progress w-[30px] h-[149px] rounded-full"><div className="bar-dot w-[10px] h-[10px] rounded-full top-[10px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Tell Us About Yourself</p></div></div>
                <div className="bar-dot w-[10px] h-[10px] rounded-full top-[123px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Set Your Goals</p></div>
                <div className="bar-dot w-[10px] h-[10px] rounded-full top-[240px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Connect Your Accounts</p></div>
              </div>
            </nav>
            <div className='bg-neutral-25 pb-[70px] w-[100%]'>
              <div className='mt-[50px] lg:mt-[106px] mb-[50px] lg:mb-[100px] mx-[30px] lg:mx-[109px]'>
                <h1 className='text-center'>How comfortable are you with managing all aspects of your personal finance?</h1>
                <p className='paragraph-1 text-center'>Are you a seasoned pro or just starting out? We’re here to help either way.
                  Please select the (1) statement that best describes you.</p>
              </div>
              <div className='max-[429px]:grid-cols-1 grid grid-cols-2 md:grid-cols-3 lg:gap-8 mx-auto lg:w-[798px] w-full lg:px-0 px-[46px] gap-y-[25px] gap-x-[25px]'>
                {cards.map((card, index) => (
                  <div key={index} onMouseEnter={() => {setCardActive(index);console.log(cardActive)}} onMouseLeave={() => setCardActive(null)}>
                    <input id={index} type="radio" value={card.label} name='selection' className='hidden' onChange={(e) => { setSelection(e.target.value); console.log(e.target.value) }} />
                    <label htmlFor={index}>
                      <div className='onboarding-card' onClick={() => setCardSelected(index)} style={{ backgroundColor: cardSelected === index ? "rgba(247, 189, 132, 0.8)" : "" }}>
                        <img src={ ((cardSelected === index)||(cardActive === index)) ? card.icon_white : card.icon } className="w-[60px] h-[60px] lg:w-[80px] lg:h-[80px] mx-auto" alt={card.label} />
                        <p className='card-label mx-auto'>{card.label}</p>
                      </div>
                    </label>
                  </div>

                ))}
              </div>




            </div>
          </div>

          <div className='flex flex-col-reverse lg:flex-row justify-between align-middle py-[37px] px-[119px] top-shadow'>
            <button className='text-only-button mt-[15px] lg:mt-0' type='button' onClick={() => navigate(-1)}>Go Back</button>
            <button ref={ref} className='gold-button' disabled={selection === ""} type='button' onClick={() => tracker?putOnClickProfile():postOnClick()}>Next</button>
          </div>


        </form>
    </>
  )

}

const Finances = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  isAuthenticated && console.log(user);

  // Redirect to home if not authenticated
  !isLoading && !isAuthenticated && loginWithRedirect()

  return (
    // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
    isAuthenticated && <UserContext.Provider value={user.email}><LoadFinances email={user.email} /></UserContext.Provider>
  )
}

export default Finances