import React, { useState, useRef, createContext, useContext } from 'react';
import InternalFooter from './Footer';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
const logo = require('../../assets/logo-color.png');
const house = require('../../assets/icons/house-gold.svg').default;
const rent = require('../../assets/icons/building-gold.svg').default;
const target = require('../../assets/icons/target-gold.svg').default;
const house_white = require('../../assets/icons/house-white.svg').default;
const rent_white = require('../../assets/icons/building-white.svg').default;
const target_white = require('../../assets/icons/target-white.svg').default;

const cards = [{
  label: "I am a homeowner",
  icon: house,
  icon_white: house_white,
  action1: "#"
},
{
  label: "I am a renter",
  icon: rent,
  icon_white: rent_white,
  action1: "#"
},
{
  label: "Other",
  icon: target,
  icon_white: target_white,
  action1: "#"
}]
const UserContext = createContext()
const LoadProperty = () => {
  const email = useContext(UserContext);
  
  return (
    <>
      <div className='w-screen m-0'>
        <Fields email={email}></Fields>
        <InternalFooter></InternalFooter>
      </div>



    </>
  )
}

function Fields(props) {
  const { user} = useAuth0();
  const navigate = useNavigate();

  function submit() {
    payload.owner_status = selection;
    console.log(payload);
    navigate("/finances",{state:payload})
  };
  const ref = useRef(null);
  const location = useLocation();
  let payload = location.state
  const [cardSelected, setCardSelected] = useState(()=>{
    if (payload.employee_status!="") {
      return cards.findIndex(element=> element.label==payload.owner_status);
    } else {
      return null
    }
  });
  const [selection, setSelection] = useState(()=>{
    if (payload.owner_status!="") {
      return payload.owner_status;
    } else {
      return ""
    }
  });
  const [cardActive, setCardActive] = useState(null);

  const autoScroll = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
    ref.current?.focus();
  };


  return (
    (user) && <>
      <form onSubmit={submit}>
          <div className='flex flex-row min-h-[85vh]'>
            <nav className="hidden lg:flex flex-col flex-nowrap bg-white basis-[312px] shrink-0 grow-0">
              <img src={logo} className="w-[264.15px] h-[65px] mt-[41px] self-center" alt="KairosLogo" />
              <div className="onboarding-bar w-[30px] h-[266px] rounded-full mt-[75px] ml-[59px]">
                <div className="bar-progress w-[30px] h-[149px] rounded-full"><div className="bar-dot w-[10px] h-[10px] rounded-full top-[10px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Tell Us About Yourself</p></div></div>
                <div className="bar-dot w-[10px] h-[10px] rounded-full top-[123px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Set Your Goals</p></div>
                <div className="bar-dot w-[10px] h-[10px] rounded-full top-[240px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Connect Your Accounts</p></div>
              </div>
            </nav>
            <div className='bg-neutral-25 pb-[70px] w-[100%]'>
              <div className='mt-[50px] lg:mt-[106px] mb-[50px] lg:mb-[100px] mx-[30px] lg:mx-[109px]'>
                <h1 className='text-center'>Do you have any property?</h1>
                <p className='paragraph-1 text-center'>Please select the (1) statement that best describes you.</p>
              </div>
              <div className='max-[429px]:grid-cols-1 grid grid-cols-2 md:grid-cols-3 lg:gap-8 mx-auto lg:w-[798px] w-full lg:px-0 px-[46px] gap-y-[25px] gap-x-[25px]'>
                {cards.map((card, index) => (
                  <div key={index} onMouseEnter={() => {setCardActive(index);console.log(cardActive)}} onMouseLeave={() => setCardActive(null)} >
                    <input id={index} type="radio" value={card.label} name='selection' className='hidden' onChange={(e) => { setSelection(e.target.value); console.log(e.target.value) }} />
                    <label htmlFor={index}>
                      <div className='onboarding-card' onClick={() => setCardSelected(index)} style={{ backgroundColor: cardSelected === index ? "rgba(247, 189, 132, 0.8)" : "" }}>
                        <img src={ ((cardSelected === index)||(cardActive === index)) ? card.icon_white : card.icon }  className="w-[60px] h-[60px] lg:w-[80px] lg:h-[80px] mx-auto" alt={card.label} />
                        <p className='card-label mx-auto'>{card.label}</p>
                      </div>
                    </label>
                  </div>
                ))}
              </div>




            </div>
          </div>

          <div className='flex flex-col-reverse lg:flex-row justify-between align-middle py-[37px] px-[119px] top-shadow'>
            <button className='text-only-button mt-[15px] lg:mt-0' type='button' onClick={() => navigate(-1)}>Go Back</button>
            <button ref={ref} className='gold-button' disabled={selection === ""} type='submit' onClick={() => submit()}>Next</button>
          </div>

          
        </form>

    </>
  )

}


const Property = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  isAuthenticated && console.log(user);

  // Redirect to home if not authenticated
  !isLoading && !isAuthenticated && loginWithRedirect()

  return (
    // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
    isAuthenticated && <UserContext.Provider value={user.email}><LoadProperty email={user.email} /></UserContext.Provider>
  )
}

export default Property