
import React, { useState, useEffect, createContext, useContext } from 'react';
import InternalFooter from '../Onboarding/Footer';
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react';
import postLoadSaveData from '../../utils/postLoadSaveData';
import postLoadFetchData from '../../utils/postLoadFetchData';
import postLoadDeleteData from '../../utils/postLoadDeleteData';
import postLoadPostData from '../../utils/postLoadPostData';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Modal from 'react-modal';
const close = require('../../assets/common/close-white.svg').default;
const logo = require('../../assets/logo-color.png');
const dots = require('../../assets/icons/dots.svg').default;
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    background: 'white',
    'border-radius': '10px',
    padding: '0px'
  },
  overlay: {zIndex: 1000}
};
const UserContext = createContext()
const LoadBPriorities = () => {
  const email = useContext(UserContext);

  return (
    <>
      <div className='w-screen m-0'>
        <Fields email={email}></Fields>
        <InternalFooter></InternalFooter>
      </div>



    </>
  )
}

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local') {
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

function Fields(props) {
  const email = props.email;

  // Get token function, urls
  const { getAccessTokenSilently, user } = useAuth0();
  const [answers, setAnswers] = useState(null);
  const baseURL = apiBaseURL;
  const [profileData, setProfileData] = useState(null);
  const urlprofile = `${baseURL}financial_discovery/financial_profile/?email=${email}&mode=profile`
  const url = `${baseURL}client_extras/answers/`
  const urlsubmit = `${baseURL}financial_discovery/financial_goal/`;
  let data = null;
  // Example: post data on load
  useEffect(() => {
    // Start the process by getting the token
    (async () => {
      try {
        const email = await user.email;
        const token = await getAccessTokenSilently();
        const response = await postLoadPostData(url, { "question": "Rank the following in order of their importance to you." }, token);
        const consolidate = await postLoadFetchData(urlprofile, token);
        setProfileData(consolidate.status ? null : consolidate);
        console.log('PROFILE', consolidate)
        setAnswers(response.map((obj, i) => ({ ...obj, id: i + 1 })));
        updateGoalCards(response.map((obj, i) => ({ ...obj, id: i + 1 })));
        console.log('data: ', response.map((obj, i) => ({ ...obj, id: i + 1 })))
        console.log('user', user)
        // console.log("Got new token: " + token)
        // Execute the api method by calling the function
        // Uncomment the line below to post data on load
        // const response = await postLoadSaveData(url, payload, token);
      } catch (err) {
        console.log(err);
      }
    })();
    // End process
  }, [])

  const navigate = useNavigate();
  const location = useLocation();
  const [goalCards, updateGoalCards] = useState(answers); // New: changed it from useState(null) to useState(answers) to initialize the state with the data from the api call

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(goalCards);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    updateGoalCards(items);
    console.log(items)
  }

  const postOnClick = async () => {
    let payload = [];
    for (let i = 0; i < goalCards.length; i++) {
      const element = {
        email: user.email,
        priority: i + 1,
        goal_name: goalCards[i].answer
      };
      payload.push(element);
      console.log(element);
    }
    console.log(payload)
    try {
      const token = await getAccessTokenSilently();
      console.log("Got new token for function: " + token)
      // Execute the api method by calling the function
      const response = await postLoadSaveData(urlsubmit, payload, token);
      console.log(response);
    } catch (err) {
      console.log(err);
    } finally {
      navigate("/onboarding/debt")
    }

  }

  const putOnClick = async () => {
    let payload = [];
    for (let i = 0; i < goalCards.length; i++) {
      const element = {
        email: user.email,
        priority: i + 1,
        goal_name: goalCards[i].answer
      };
      payload.push(element);
      console.log(element);
    }
    console.log(payload)
    try {
      const token = await getAccessTokenSilently();
      console.log("Got new token for function: " + token)
      // Execute the api method by calling the function
      const deleted = await postLoadDeleteData(urlsubmit, { email: email }, token);
      const response = await postLoadSaveData(urlsubmit, payload, token);
      console.log(response);
    } catch (err) {
      console.log(err);
    } finally {
      navigate("/onboarding/debt")
    }

  }

  //Modal Functions START
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  // Modal Functions END

  return (answers && <><div className='flex flex-row min-h-[85vh]'>
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Sign in to bookmark"
    >
      <div className='bg-black py-[35px] px-[50px] w-[569px] h-[310px]'>
        <p className='basic-white text-center paragraph-1'>Everybody has different goals, and understanding your current priorities will help us put together a personalized plan for you to achieve your own goals.</p>
        <img src={close} onClick={closeModal} alt="Close" className='cursor-pointer w-[31px] h-[31px] absolute bottom-[64px] right-1/2 translate-x-2/4'></img>
      </div>

    </Modal>
    <nav className="hidden lg:flex flex-col flex-nowrap bg-white basis-[312px] shrink-0 grow-0">
      <img src={logo} className="w-[264.15px] h-[65px] mt-[41px] self-center" alt="KairosLogo" />
      <div className="onboarding-bar w-[30px] h-[266px] rounded-full mt-[75px] ml-[59px]">
        <div className="bar-progress w-[30px] h-[149px] rounded-full"><div className="bar-dot w-[10px] h-[10px] rounded-full top-[10px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Tell Us About Yourself</p></div></div>
        <div className="bar-dot w-[10px] h-[10px] rounded-full top-[123px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Set Your Goals</p></div>
        <div className="bar-dot w-[10px] h-[10px] rounded-full top-[240px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Connect Your Accounts</p></div>
      </div>
    </nav>
    <div className='bg-neutral-25 pb-[70px] w-[100%]'>
      <div className='mt-[25px] lg:mt-[106px] mb-[50px] lg:mb-[100px] mx-[30px] lg:mx-[109px] text-center'>
        <h1 className='text-center'>Rank your goals in order of importance.</h1>
        <p className='paragraph-1 text-center'>Click and drag your goals so they are in the order that currently represents your priorities. Don’t worry about what you think your priorities “should” be — tell us what they really are.</p>
        <br/>
        <p className='paragraph-1 text-center underline cursor-pointer inline' onClick={openModal}>Why do we ask this?</p>
      </div>

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className='flex flex-row lg:w-[500px] w-[349px] mx-auto max-w-full'>
          <div className='grid grid-cols-1 gap-6 lg:w-[100px] w-[60px]'>
            {answers.map((card, index) => (
              <p className='accent-1 my-auto'>#{index + 1}</p>

            ))}
          </div>
          <Droppable droppableId="goals">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps} className='grid grid-cols-1 gap-6 w-[400px]'>
                {goalCards.map((card, index) => ( // New: changed it from answers.map to goalCards.map as the state is now goalCards after being updated by the handleOnDragEnd function
                  <Draggable key={card.id} draggableId={card.id.toString()} index={index}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className='onboarding-drag-card w-[400px] h-[140px]'>
                        <img src={dots} className="h-[29px] lg:mr-[36px] mr-[30px]" alt="dots" />
                        <img src={apiBaseURL.slice(0, -1) + card.image} className="lg:w-[80px] lg:h-[80px] w-[60px] h-[60px] lg:mr-[36px] mr-[30px]" alt={card.answer} />
                        <p className='drag-card-label mx-auto'>{card.answer}</p>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

        </div>
      </DragDropContext>





    </div>
  </div>

    <div className='flex flex-col-reverse lg:flex-row justify-between align-middle py-[37px] px-[119px] top-shadow'>
      <button className='text-only-button mt-[15px] lg:mt-0' onClick={() => navigate(-1)}>Go Back</button>
      <button className='gold-button' onClick={() => Object.keys(profileData?.financial_profile["Rank the following in order of their importance to you."]).length > 0 ? putOnClick() : postOnClick()}>Next</button>
    </div>
  </>)
}

const BPriorities = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  isAuthenticated && console.log(user);

  // Redirect to home if not authenticated
  !isLoading && !isAuthenticated && loginWithRedirect()

  return (
    // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
    isAuthenticated && <UserContext.Provider value={user.email}><LoadBPriorities email={user.email} /></UserContext.Provider>
  )
}

export default BPriorities