import { useAuth0 } from '@auth0/auth0-react';
import { useFetch } from '../../utils/useFetch'
import React, { createContext, useContext, useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import postLoadFetchData from '../../utils/postLoadFetchData';
/* import { useFetch } from "../../utils/useFetch"; */

const UserContext = createContext()

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local'){
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

function Switch() {
  const email = useContext(UserContext);
  const navigate = useNavigate();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [financialData, setFinancialData] = useState(null);
  const location = useLocation();
  let fromOnboarding = location.state;

  const baseURL = apiBaseURL;
  const url = `${baseURL}financial_discovery/financial_profile/?email=${email}&mode=track_onboarding`;

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const urlProfile = `${baseURL}financial_discovery/financial_profile/?email=${email}&mode=profile`;
        const urlUserAddress = `${baseURL}user_profiles/user_and_address/?email=${email}`;
        // console.log("Got new token for function: " + token)
        // Execute the api method by calling the function
        const financialProfile = await postLoadFetchData(url, token);
        setFinancialData(financialProfile);
        console.log('data: ', financialData)
        console.log('user', user)

        

    } catch (err) {
        console.log(err);
    }
    })();
}, []);

// Check that state is not null before checking the values and redirecting
if (fromOnboarding) {
  navigate("/finstack")
} else if (financialData){
  if ((financialData.financial_profile.subscription_status.payment_status==='active')) {
    navigate("/main-dashboard")
  } else if (financialData.financial_profile["How comfortable are you with managing all aspects of your personal finance?"]) {
    navigate("/onboarding/payment")
  } else {
    navigate("/onboarding/personal-info")
  }
}

}

const Dashboard = () => {
  /* function submit() {
    navigate("/goals")
  }; */
  const { isAuthenticated, isLoading, user, loginWithRedirect } = useAuth0();
  isAuthenticated && console.log(user);
  if (isLoading) return <div>Loading...</div>; // Todo: Add a loading component

  // Redirect to home if not authenticated
  !isAuthenticated && loginWithRedirect()

  return (
    // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
    isAuthenticated && <UserContext.Provider value={user.email}><Switch email={user.email} /></UserContext.Provider>
  )

}

export default Dashboard