import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useState, createContext } from "react"
import postLoadFetchData from '../utils/postLoadFetchData';
const spinner = require('../assets/icons/spinner.png');

function showAsCurrency(amount) {
  return amount.toLocaleString('en-US', { 
    style: 'currency', 
    currency: 'USD' 
  });
}

const UserContext = createContext()

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local'){
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

function LoadHardEarnedCashExpenses() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const email = useContext(UserContext);
  const [expenses, setExpenses] = useState(null);
  const baseURL = apiBaseURL;
  const url = `${baseURL}connections/expenses?email=${email}`;

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        // console.log("Got new token for function: " + token)
        // Execute the api method by calling the function
        const response = await postLoadFetchData(url, token);
        setExpenses(response);
        console.log('expenses: ', response)
        console.log('user', user)

      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  if (!expenses) return <div><img src={spinner} className="animate-spin mx-auto" alt="Loading"></img></div>; // Todo: Add a loading component

  if (expenses.status === 500) { // Todo: Add more details about the error
    return(
      <div className='md:w-[465px] w-full md:h-[368px] inline-block shadow-[0px_3px_12px_rgba(0,0,0,0.08)] rounded-[20px] p-[30px] flex flex-col md:self-center'>
        <><p className='accent-5 text-center'>An error has occurred in Plaid while retrieving financial data.</p>
        <p className='paragraph-2-medium mt-[40px] w-[330px] inline-block mx-auto px-[5px] text-center'>We'll gather more details to fix this ASAP.</p></>
    </div>
    )
  }
  if (expenses?.message === "No access token found") {
    return(<></>)
  }
  return (
    // loop through props.data.accounts and display each item using map
    <div className='md:w-[465px] w-full md:h-[368px] inline-block shadow-[0px_3px_12px_rgba(0,0,0,0.08)] rounded-[20px] p-[30px] flex flex-col md:self-center'>
        {
          expenses?.total ?
          (<><p className='accent-5'>Here’s Where All Your Hard-Earned Cash Is Going:</p>
          <p className='paragraph-2-medium mt-[40px] w-[330px] inline-block mx-auto px-[5px] text-center'>You spent: <span className='h1-mobile brand-gold'>{showAsCurrency(expenses.total)}</span> on <span className='h1-mobile brand-gold'>{expenses.category}</span> last month</p></>)
          :
          <><p className='accent-5 text-center'>We don’t see any connected checking or savings accounts</p>
        <p className='paragraph-2-medium mt-[40px] w-[330px] inline-block mx-auto px-[5px] text-center'>We’d love to show you where your hard money is being spent. Connect your bank accounts below, and you’ll see all your cash in one place! </p></>
        }
    </div>
    
  );

}

const HardEarnedCashExpenses = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  isAuthenticated && console.log(user);
  if (isLoading) return <div>Loading...</div>; // Todo: Add a loading component

  // Redirect to home if not authenticated
  !isAuthenticated && loginWithRedirect()

  return (
    // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
    isAuthenticated && <UserContext.Provider value={user.email}><LoadHardEarnedCashExpenses email={user.email} /></UserContext.Provider>
  )
}

export default HardEarnedCashExpenses;