import './benefits.css';
import findUser from '../../../assets/icons/find-user-gold.svg';
import investment from '../../../assets/icons/investment-gold.svg';
import optimize from '../../../assets/icons/optimize-gold.svg';
import plant from '../../../assets/icons/plant-gold.svg';
import goals from '../../../assets/marketing/M0102.png';
import find from '../../../assets/marketing/M0103.png';
import personalized from '../../../assets/marketing/M0104.png';
import optimization from '../../../assets/marketing/M0105.png';
import us from '../../../assets/marketing/M0106.png';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
const ellipse = require('../../../assets/ellipse10i410-gych-200h.png')

const benefitCopy = [{
  heading: "Clarity, Confidence, Control",
  text: "Our vision is to empower you to gain clarity, confidence, and control over your money. We believe everyone, regardless of their income or financial circumstances, should feel clear about their financial situation and confident about their ability to build their financial health, as well as have control over their choices and ability to reach their goals.",
  button1: "Sign Up",
  action1: "#",
  button2: "Learn More",
  action2: "#",
  icon: investment,
  image: goals,
  styles: 'w-[396px]'
},
{
  heading: "Find What's Missing",
  text: "Kairos helps you clearly identify your goals and take control of all aspects of your financial situation. We show you what pieces of the puzzle you’re missing while building wealth and identify the right products to help you fill those gaps.",
  button1: "Sign Up",
  action1: "#",
  button2: "Learn More",
  action2: "#",
  icon: findUser,
  image: find,
  styles: 'w-[420px]'
},
{
  heading: "Personalized Coaching",
  text: "With just a few simple questions, our unique and proprietary AI technology is able to create a personalized plan for you, including the right stack of financial products for your needs and resources and content that will help you reach your goals faster. Not getting exactly what you need? Live chat with us and 1-on-1 calls are always available.",
  button1: "Sign Up",
  action1: "#",
  button2: "Learn More",
  action2: "#",
  icon: investment,
  image: personalized,
  styles: 'w-[357px]'
},
{
  heading: "Optimization Over Time",
  text: "Caring for your finances should look a lot like caring for your teeth — tiny actions every day with check-ups every six months. Kairos will help keep track of your daily actions so that, alongside your goals and evolving financail situation, we can help you optimize your plans every six months.",
  button1: "Sign Up",
  action1: "#",
  button2: "Learn More",
  action2: "#",
  icon: optimize,
  image: optimization,
  styles: 'w-[429px]'
},
{
  heading: "For the Rest of Us",
  text: "Kairos is the first fintech company with an income maximum, not an income minimum. If you already have a million dollars in the bank, you probably don’t need us. We aim to serve those who are not traditionally designed for by the finance industry.",
  button1: "Sign Up",
  action1: "#",
  button2: "Learn More",
  action2: "#",
  icon: plant,
  image: us,
  styles: 'w-[436px]'
}]

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';
var authDomain = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
  authDomain = process.env.REACT_APP_AUTH0_DOMAIN_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
  authDomain = process.env.REACT_APP_AUTH0_DOMAIN_PROD;
} else if (running_env === 'local') {
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
  authDomain = process.env.REACT_APP_AUTH0_DOMAIN_DEV;
}

const Benefits = () => {
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();
  const domain = authDomain
  return benefitCopy.map((benefit, index) => {
    return (
      <div className='main-container bg-white'>
        <div className='lg:w-[1015px] w-full mx-auto flex lg:flex-row flex-col justify-between'>
          <img src={benefit.image} className={`row mx-auto ${index % 2 ? 'order-first lg:mr-[100px]' : 'lg:order-last order-first lg:ml-[100px]'} max-w-[440px] my-auto`}></img>
          <div className={`lg:max-w-[575px] max-w-full flex justify-center flex-col text-center ${index % 2 ? 'lg:text-left' : 'lg:text-right'}`}>
            <img src={benefit.icon} className={`w-[60px] h-[60px] self-center ${index % 2 ? 'lg:self-start' : 'lg:self-end'}`}></img>
            <h2 className='lg:mt-[13px] mt-[25px] h2-benefits lg:mx-0 mx-[30px]'>{benefit.heading}</h2>
            <p className='paragraph-1 basic-black lg:my-[30px] mt-[25px] mb-[30px] lg:mx-0 mx-[30px]'>{benefit.text}</p>
            <div>
              <button className='gold-button mr-[30px]' onClick={() => loginWithRedirect({
                screen_hint: 'signup',
                audience: `https://${domain}/api/v2/`,
                scope: "profile", // new
              })}>Sign Up</button>
              <button className='border-gold-button' onClick={() => navigate("/features")}>Learn More</button>
            </div>
          </div>
        </div>
      </div>

    )
  })

}
export default Benefits