
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import Checkout from '../../components/stripe/Payment';
import Footer from '../../components/Footer';
import Sidebar from "./components/Sidebar"
import UserCard from "./components/UserCard"
import ActionCard from "./components/ActionCard"
import { useAuth0 } from "@auth0/auth0-react";
import target from "./../../assets/icons/target-gold.svg"
import AreaChartComponent from '../../components/AreaChartComponent';
import { useFetch } from '../../utils/useFetch';
import postLoadFetchData from '../../utils/postLoadFetchData';
import NetWorth from '../../components/NetWorth';
import ConnectedAccounts from '../../components/ConnectedAccounts';
import postLoadModifyData from '../../utils/postLoadModifyData';
import postLoadSaveData from '../../utils/postLoadSaveData';
const logo = require('../../assets/logo-color.png');
const stack = require('../../assets/icons/stack-gold.svg').default;
const piggy_bank = require('../../assets/icons/piggy-bank-gold.svg').default;
const spinner = require('../../assets/icons/spinner.png');
const investment = require('./../../assets/onboarding/investing.svg').default;
const savings = require('./../../assets/onboarding/savings.svg').default;
const insurance = require('./../../assets/onboarding/insurance.svg').default;
const loans = require('./../../assets/onboarding/loans.svg').default;
const arrow = require('./../../assets/icons/arrow-fill.svg').default;
const checkbox_false = require('../../assets/icons/profile/checkbox-false.png');
const checkbox_true = require('../../assets/icons/profile/checkbox-true-white.png');
const UserContext = createContext()

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local') {
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

function LoadDashboardData() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const email = useContext(UserContext);
  const [allConnectedAccounts, setAllConnectedAccounts] = useState(null);

  const baseURL = apiBaseURL;
  const url = `${baseURL}connections/accounts?email=${email}`;

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        // console.log("Got new token for function: " + token)
        // Execute the api method by calling the function
        const accounts = await postLoadFetchData(url, token);
        setAllConnectedAccounts(accounts);

        console.log('account from connected accounts: ', accounts)
        console.log('user', user)

      } catch (err) {
        console.log(err)
      }
    })();
  }, []);

  if (!isAuthenticated) return <div>Loading dashboard...</div>;

  return (
    (user && isAuthenticated) && <>
      <div>
        <Sidebar user={user}></Sidebar>
        <UserCard user={user} />
        <div className="app-container !px-0">
          <div className="w-full mx-auto">
            <p className='paragraph-1 lg:ml-0 ml-[35px] pl-[30px]'>Hey {!!user.given_name ? user.given_name.trim() : 'you'}, </p>
            <h2 className='h2 lg:ml-0 ml-[35px] mb-[30px] pl-[30px]'>Welcome Back!&#128075;</h2>
            <div className='relative bg-gold-20 w-[100%] h-[1250px] md:h-[900px] lg:h-[674px] left-0'>
              <div className='mt-[31px] mb-[33px] mx-auto static z-[1] flex justify-items'>
                <ProductMatches email={email}></ProductMatches>
              </div>
            </div>


            <div className='mx-[20px] mt-[45px] lg:mx-auto flex justify-center'>
              <TopArticles email={email}></TopArticles>
            </div>


{/*             <div className='mt-[69px] mb-[97px] relative mx-auto flex justify-center'>
              {
                allConnectedAccounts && allConnectedAccounts.length > 0 ?
                  <>
                    <div className='mx-auto lg:mx-[90px] lg:w-[840px] w-full flex lg:flex-row flex-col items-center justify-between my-[20px] lg:my-0'>
                      <div className='w-[320px] hidden lg:block'>
                        <NetWorth />
                      </div>
                      <div className='lg:w-[520px] w-full relative'>
                        <AreaChartComponent />
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className='w-[876px] block shadow-[0px_3px_12px_rgba(0,0,0,0.08)] bg-fade-gold rounded-[20px] px-[50px] lg:px-[110px] py-[30px] lg:py-[80px] lg:mx-auto mx-[30px]'>
                      <h3 className='text-center leading-10 h3-to-h1-mobile max-w-[644px] mx-auto'>Connect your existing financial accounts to start gaining insights right away!</h3>
                      <br></br><p className='paragraph-1-to-mobile text-center max-w-[303px] mx-auto'>Connect as many financial accounts as you're comfortable with <button className='text-white' onClick={() => navigate('/onboarding/connect-accounts')}>here</button></p>
                    </div>
                  </>
              }

            </div> */}
{/*             <div className='mt-[46px] lg:w-[388px] w-full lg:px-0 px-[21px] mb-[34px] block mx-auto text-center basic-black callout-bottom'>
              <p>Want more help on your financial journey?<p>
              </p>Book a <span className=' font-bold'>free</span> call with us today.</p>
            </div>
            <div className='block mx-auto text-center mb-[59px]'>
              <a className="gold-button" href="https://calendly.com/jw-bayfront-ventures/15min?month=2023-01" target="_blank">Book My Free Call</a>
            </div> */}
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  )

}

function ProductMatches() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const email = useContext(UserContext);
  const [productMatches, setProductMatches] = useState(null);
  const [checklist, setChecklist] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const baseURL = apiBaseURL;
  const urlproduct = `${baseURL}product_catalog/matching_products/?email=${email}`;
  const urlChecklist = `${baseURL}client_extras/checklist/?email=${email}`;
  const urlUpdate = `${baseURL}client_extras/checklist/update/`
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        console.log("Got new token for function: " + token)
        // Execute the api method by calling the function
        const products = await postLoadFetchData(urlproduct, token);
        const list = await postLoadFetchData(urlChecklist, token);
        setChecklist(list);
        setProductMatches(products);
        console.log('products: ', products)
        console.log('user', user)
        console.log('List:', list)


      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  function selectImage(tag) {
    switch (tag) {
      case "loans":
        return loans;
        break;
      case "insurance":
        return insurance;
        break;
      case "investing":
        return investment;
        break;
      case "savings":
        return savings;
        break;
    }
  }

  // Post data on button click
  const postOnClickChecklist = async (id, index, value) => {
    console.log(checklist[index].checked)
    let payload = {
      email: email,
      item: checklist[index].content,
      checked: (!checklist[index].checked).toString()
    }
    console.log(payload);
    if (value === "false") {
      document.getElementById(`false-${index}`).classList.toggle("hidden");
      document.getElementById(`true-${index}`).classList.toggle("float-left");
      document.getElementById(`true-${index}`).classList.toggle("hidden");
      document.getElementById(`false-${index}`).classList.toggle("float-left");
    } else {
      document.getElementById(`true-${index}`).classList.toggle("hidden");
      document.getElementById(`false-${index}`).classList.toggle("float-left");
      document.getElementById(`false-${index}`).classList.toggle("hidden");
      document.getElementById(`true-${index}`).classList.toggle("float-left");
    }


    try {
      const token = await getAccessTokenSilently();
      // console.log("Got new token for function: " + token)
      // Execute the api method by calling the function
      const response = await postLoadSaveData(urlUpdate, payload, token);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  }

  if (!productMatches) return <>
    <div className='mt-[45px] h-[488px] p-10'>
      <img src={spinner} className="animate-spin mx-auto" alt="Loading"></img>

    </div>

  </>;

  return (
    (user && productMatches && isAuthenticated && checklist) && <>
      <div className='flex flex-row mx-auto'>
        <div className='lg:w-[693px] flex flex-row items-start p-[30px] w-full'>
          <img src={stack} alt="stack" className='w-[38px] mt-[10px] mr-[20px]' />
          <div>
            <h3 className='h3-bold shrink-0'>Finstack® by Kairos</h3>
            <div className='paragraph-1 mt-[5px] relative'>
              <p className='cursor-pointer inline underline text-left neutral-700' onMouseEnter={() => setShowChat(true)} onMouseOut={() => setShowChat(false)}>What's my Finstack?</p>

            </div>
            {showChat ? <><div className='absolute z-[10] translate-y-[30px] px-[10px] fade-in-pop-up'>
              <div className='bubble-top-left w-[460px] basic-white bubble-text'>Your Finstack is the unique set of products that help you achieve clarity, confidence, and control over your wealth.
                <br /><br />
                Your Finstack starts as a group of product categories, each of which has associated products that we’ve hand-picked to help you achieve your goals in each of those categories. Once you sign up for the products that feel right for you (ideally one per category), your Finstack will become the stack of products that you use to manage your money and grow your wealth.</div>
            </div></> : <></>}

            {/* Mobile stack */}
            <div className='pt-[45px] h-[400px] w-[316px] lg:translate-y-[100px] block lg:hidden'>
              <div className='translate-y-[190px] translate-x-[68px] bg-white w-[193px] h-[143px] shadow-[0px_3px_12px_rgba(0,0,0,0.08)] rounded-[10px] py-[25px] px-[20px] absolute cursor-pointer overflow-clip bg-white' onClick={() => navigate("/product-matches", { state: productMatches?.hierarchy[2]?.bucket_name })}>
                <div>
                  <img src={selectImage(productMatches?.hierarchy[2]?.tag)} className="absolute w-[143px] opacity-50 top-[5px] right-[-20px]"></img>
                </div>
                <img src={arrow} alt="Arrow" className="absolute bottom-[20px] right-[15px]"></img>
                <div className='relative'>
                  <div className='accent-callout brand-gold'>{productMatches?.hierarchy[2]?.tag ? productMatches.hierarchy[2].tag.toUpperCase() : "UNKNOWN"}</div>
                  <div className='subtitle-cards max-w-[125px] mt-[10px]'>{productMatches?.hierarchy[2]?.display_name ? productMatches?.hierarchy[2]?.display_name : "UNKNOWN"}</div>
                </div>

              </div>
              <div className='translate-y-[95px] translate-x-[34px] bg-white w-[193px] h-[143px] shadow-[0px_3px_12px_rgba(0,0,0,0.08)] rounded-[10px] py-[25px] px-[20px] absolute cursor-pointer overflow-clip bg-white' onClick={() => navigate("/product-matches", { state: productMatches?.hierarchy[1]?.bucket_name })}>
                <div>
                  <img src={selectImage(productMatches?.hierarchy[1]?.tag)} className="absolute w-[143px] opacity-50 top-[5px] right-[-20px]"></img>
                </div>
                <img src={arrow} alt="Arrow" className="absolute bottom-[20px] right-[15px]"></img>
                <div className='relative'>
                  <div className='accent-callout brand-gold'>{productMatches?.hierarchy[1]?.tag ? productMatches.hierarchy[1].tag.toUpperCase() : "UNKNOWN"}</div>
                  <div className='subtitle-cards max-w-[125px] mt-[10px]'>{productMatches?.hierarchy[1]?.display_name ? productMatches?.hierarchy[1]?.display_name : "UNKNOWN"}</div>
                </div>

              </div>
              <div className='bg-white w-[193px] h-[143px] shadow-[0px_3px_12px_rgba(0,0,0,0.08)] rounded-[10px] py-[25px] px-[20px] absolute cursor-pointer overflow-clip bg-white' onClick={() => navigate("/product-matches", { state: productMatches?.hierarchy[0]?.bucket_name })}>
                <div>
                  <img src={selectImage(productMatches?.hierarchy[0]?.tag)} className="absolute z-[1] w-[143px] opacity-50 top-[5px] right-[-20px]"></img>
                </div>
                <img src={arrow} alt="Arrow" className="absolute z-[1] bottom-[20px] right-[15px]"></img>
                <div className='relative z-[2]'>
                  <div className='accent-callout brand-gold'>{productMatches?.hierarchy[0]?.tag ? productMatches.hierarchy[0].tag.toUpperCase() : "UNKNOWN"}</div>
                  <div className='subtitle-cards max-w-[125px] mt-[10px]'>{productMatches?.hierarchy[0]?.display_name ? productMatches?.hierarchy[0]?.display_name : "UNKNOWN"}</div>
                </div>

              </div>
            </div>
            {/* List */}
            <ul className='mt-[31px] relative left-[-56px] lg:left-0'>
              {checklist.slice(0, 7).map((item, index) => {
                return (
                  <>
                    <li className='paragraph-1 mb-[1.5em]' key={item.id}>
                      <img src={checkbox_false} className={`mr-[22px] cursor-pointer ${checklist[index].checked === true ? 'hidden' : 'float-left'}`} id={`false-${index}`} onClick={() => postOnClickChecklist(item.id, index, 'false')} />
                      <img src={checkbox_true} className={`mr-[22px] cursor-pointer ${checklist[index].checked === true ? 'float-left' : 'hidden'}`} id={`true-${index}`} onClick={() => postOnClickChecklist(item.id, index, 'true')} />
                      <p className='overflow-hidden break-normal max-w-full'>{item.content}</p>
                    </li>
                    {/*                   {index===2?<><li className='paragraph-1 mb-[1.5em]'>
                      {productMatches?.hierarchy[2]?.display_name}
                      </li></>:<></>} */}
                  </>
                )
              })}
            </ul>
          </div>

        </div>
        {/* Desktop stack */}
        <div className='pt-[45px] lg:h-[488px] w-[316px] pl-[30px] lg:translate-y-[100px] lg:block hidden'>
          <div className='translate-y-[190px] translate-x-[68px] bg-white w-[193px] h-[143px] shadow-[0px_3px_12px_rgba(0,0,0,0.08)] rounded-[10px] py-[25px] px-[20px] absolute cursor-pointer overflow-clip bg-white' onClick={() => navigate("/product-matches", { state: productMatches?.hierarchy[2]?.bucket_name })}>
            <div>
              <img src={selectImage(productMatches?.hierarchy[2]?.tag)} className="absolute w-[143px] opacity-50 top-[5px] right-[-20px]"></img>
            </div>
            <img src={arrow} alt="Arrow" className="absolute bottom-[20px] right-[15px]"></img>
            <div className='relative'>
              <div className='accent-callout brand-gold'>{productMatches?.hierarchy[2]?.tag ? productMatches.hierarchy[2].tag.toUpperCase() : "UNKNOWN"}</div>
              <div className='subtitle-cards max-w-[125px] mt-[10px]'>{productMatches?.hierarchy[2]?.display_name ? productMatches?.hierarchy[2]?.display_name : "UNKNOWN"}</div>
            </div>

          </div>
          <div className='translate-y-[95px] translate-x-[34px] bg-white w-[193px] h-[143px] shadow-[0px_3px_12px_rgba(0,0,0,0.08)] rounded-[10px] py-[25px] px-[20px] absolute cursor-pointer overflow-clip bg-white' onClick={() => navigate("/product-matches", { state: productMatches?.hierarchy[1]?.bucket_name })}>
            <div>
              <img src={selectImage(productMatches?.hierarchy[1]?.tag)} className="absolute w-[143px] opacity-50 top-[5px] right-[-20px]"></img>
            </div>
            <img src={arrow} alt="Arrow" className="absolute bottom-[20px] right-[15px]"></img>
            <div className='relative'>
              <div className='accent-callout brand-gold'>{productMatches?.hierarchy[1]?.tag ? productMatches.hierarchy[1].tag.toUpperCase() : "UNKNOWN"}</div>
              <div className='subtitle-cards max-w-[125px] mt-[10px]'>{productMatches?.hierarchy[1]?.display_name ? productMatches?.hierarchy[1]?.display_name : "UNKNOWN"}</div>
            </div>

          </div>
          <div className='bg-white w-[193px] h-[143px] shadow-[0px_3px_12px_rgba(0,0,0,0.08)] rounded-[10px] py-[25px] px-[20px] absolute cursor-pointer overflow-clip bg-white' onClick={() => navigate("/product-matches", { state: productMatches?.hierarchy[0]?.bucket_name })}>
            <div>
              <img src={selectImage(productMatches?.hierarchy[0]?.tag)} className="absolute z-[1] w-[143px] opacity-50 top-[5px] right-[-20px]"></img>
            </div>
            <img src={arrow} alt="Arrow" className="absolute z-[1] bottom-[20px] right-[15px]"></img>
            <div className='relative z-[2]'>
              <div className='accent-callout brand-gold'>{productMatches?.hierarchy[0]?.tag ? productMatches.hierarchy[0].tag.toUpperCase() : "UNKNOWN"}</div>
              <div className='subtitle-cards max-w-[125px] mt-[10px]'>{productMatches?.hierarchy[0]?.display_name ? productMatches?.hierarchy[0]?.display_name : "UNKNOWN"}</div>
            </div>

          </div>
        </div>
      </div>


    </>
  )

}

function TopArticles() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const email = useContext(UserContext);
  const [topArticles, setTopArticles] = useState(null);
  const baseURL = apiBaseURL;
  const urlarticles = `${baseURL}client_extras/articles/?email=${email}&mode=by_reads&sort=popular`;
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        console.log("Got new token for function: " + token)
        // Execute the api method by calling the function
        const articles = await postLoadFetchData(urlarticles, token);
        setTopArticles(articles);
        console.log('articles: ', articles)
        console.log('user', user)


      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  if (!topArticles) return <>
    <ActionCard displayCustomNeutral="hidden" title="Top Articles" width='lg:w-[960px] w-full h-[369px] overflow-clip' displayBrand="hidden" neutralLink='resources' neutralText='View All'>
      <p><img src={spinner} className="animate-spin mx-auto" alt="Loading"></img></p>
    </ActionCard>
  </>;

  return (
    (user && topArticles && isAuthenticated) && <>
      <ActionCard displayCustomNeutral="hidden" title="Top Articles" width='lg:w-[960px] w-full h-[369px] overflow-clip' displayBrand="hidden" neutralLink='resources' neutralText='View All'>
        <div className='grid lg:grid-cols-2 grid-cols-1 content-between justify-between gap-y-[51px]'>
          {
            topArticles.slice(0, 4).map((article) => {
              let src = baseURL.slice(0, -1) + article.image
              return (
                <div className='lg:w-[360px] w-full'>
                  <img src={src} className="inline w-[124px] h-full rounded-[10px] float-left mr-[15px] cursor-pointer" onClick={() => navigate(`/resources/${article.slug}`)}></img>
                  <p className="h2-mobile mb-[10px] cursor-pointer line-clamp-1" onClick={() => navigate(`/resources/${article.slug}`)}>{article.title}</p>
                  <p className="accent-mobile neutral-600 ">{article.tags[0].name.toUpperCase()}</p>
                  <p className="paragraph-1-mobile-bold brand-gold mt-[10px]">{new Date(article.last_updated).toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" })}</p>
                </div>
              )
            }
            )
          }
        </div>
      </ActionCard>
    </>
  )

}

const DashboardMain = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  isAuthenticated && console.log(user);
  if (isLoading) return <div>Loading...</div>; // Todo: Add a loading component

  // Redirect to home if not authenticated
  !isAuthenticated && loginWithRedirect()

  return (
    // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
    isAuthenticated && <UserContext.Provider value={user.email}><LoadDashboardData email={user.email} /></UserContext.Provider>
  )
}

export default DashboardMain
