import Sidebar from "./components/Sidebar"
import Headings from "./components/Headings"
import ActionCard from "./components/ActionCard"
import UserCard from "./components/UserCard"
import Footer from "../../components/Footer"
import target from "./../../assets/icons/target-gold.svg"
import { useNavigate } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useState, createContext, useEffect } from "react"
import postLoadFetchData from "../../utils/postLoadFetchData";
const grow = require('../../assets/icons/grow-gold.svg').default;
const education = require('../../assets/icons/education-gold.svg').default;
const coin = require('../../assets/icons/coin-gold.svg').default;
const house = require('../../assets/icons/house-gold.svg').default;
const books = require('../../assets/icons/books-gold.svg').default;
const accounts = require('../../assets/icons/accounts-gold.svg').default;
const optimize = require('../../assets/icons/optimize-gold.svg').default;
const retirement = require('../../assets/icons/retirement-gold.svg').default;
const insurance_person = require('../../assets/icons/insurance-person-gold.svg').default;
const people = require('../../assets/icons/people-gold.svg').default;
const debt = require('../../assets/icons/debt-gold.svg').default;
const charity = require('../../assets/icons/charity-gold.svg').default;
const insurance = require('../../assets/icons/insurance-gold.svg').default;
const other = require('../../assets/icons/target-gold.svg').default;
const grow_white = require('../../assets/icons/grow-white.svg').default;
const education_white = require('../../assets/icons/education-white.svg').default;
const coin_white = require('../../assets/icons/coin-white.svg').default;
const house_white = require('../../assets/icons/house-white.svg').default;
const books_white = require('../../assets/icons/books-white.svg').default;
const accounts_white = require('../../assets/icons/accounts-white.svg').default;
const optimize_white = require('../../assets/icons/optimize-white.svg').default;
const retirement_white = require('../../assets/icons/retirement-white.svg').default;
const insurance_person_white = require('../../assets/icons/insurance-person-white.svg').default;
const people_white = require('../../assets/icons/people-white.svg').default;
const debt_white = require('../../assets/icons/debt-white.svg').default;
const charity_white = require('../../assets/icons/charity-white.svg').default;
const insurance_white = require('../../assets/icons/insurance-white.svg').default;
const other_white = require('../../assets/icons/target-white.svg').default;
const piggy_bank = require('../../assets/icons/piggy-bank-gold.svg').default;
const spinner = require('../../assets/icons/spinner.png');
const cards = [{
    label: "Earn more on my savings",
    icon: grow,
    icon_white: grow_white,
    action1: "#"
},
{
    label: "Save for my kids' education",
    icon: education,
    icon_white: education_white,
    action1: "#"
},
{
    label: "Investment vs. savings account",
    icon: coin,
    icon_white: coin_white,
    action1: "#"
},
{
    label: "Buy a home",
    icon: house,
    icon_white: house_white,
    action1: "#"
},
{
    label: "Pay off student loans",
    icon: books,
    icon_white: books_white,
    action1: "#"
},
{
    label: "See my accounts in one place",
    icon: accounts,
    icon_white: accounts_white,
    action1: "#"
},
{
    label: "Get help investing my money",
    icon: optimize,
    icon_white: optimize_white,
    action1: "#"
},
{
    label: "Save for retirement",
    icon: retirement,
    icon_white: retirement_white,
    action1: "#"
},
{
    label: "What do I do with my old 401(k)?",
    icon: insurance_person,
    icon_white: insurance_person_white,
    action1: "#"
},
{
    label: "Trusted financial resource",
    icon: people,
    icon_white: people_white,
    action1: "#"
},
{
    label: "Pay off debt",
    icon: debt,
    icon_white: debt_white,
    action1: "#"
},
{
    label: "Give to charitable cause(s)",
    icon: charity,
    icon_white: charity_white,
    action1: "#"
},
{
    label: "Get better insurance",
    icon: insurance,
    icon_white: insurance_white,
    action1: "#"
},
{
    label: "Other",
    icon: other,
    icon_white: other_white,
    action1: "#"
}]

const UserContext = createContext()

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local'){
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

const LoadYourGoals = () => {
    const email = useContext(UserContext);
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const baseURL = apiBaseURL;
    const navigate = useNavigate();

    // Check that state is not null
    if (isAuthenticated) {

        return (
            isAuthenticated && <>
                <div>
                    <Sidebar user={user}></Sidebar>
                    <div className="app-container">
                        <div className="w-[1020px] mx-auto">
                            <UserCard user={user} />
                            <Headings title='Your Goals' icon={target} />
                            <p className="ml-[105px] mt-[35px] mr-[60px] mb-[45px] paragraph-1">
                                These are the goals you inputted during our onboarding questionnaire. <br></br>These goals can (and should!) change over time, so please adjust them as needed by
                                clicking <span className="span-button" onClick={() => navigate("/personal-info-edit")}>here</span>.
                            </p>
                            <Goals email={email}></Goals>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </>

        )
    }

}

const Goals = () => {
    const email = useContext(UserContext);
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const baseURL = apiBaseURL;
    const [isLoading, setIsLoading] = useState(true);
    const [financialData, setFinancialData] = useState();
    const navigate = useNavigate();
    // Start the process by getting the token
    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently();
                const urlProfile = `${baseURL}financial_discovery/financial_profile/?email=${email}&mode=profile`;
                // console.log("Got new token for function: " + token)
                // Execute the api method by calling the function
                const userProfile = await postLoadFetchData(urlProfile, token);
                setFinancialData(userProfile);
                console.log('data: ', financialData)
                console.log('user', user)
            } catch (err) {
                console.log(err);
            }
        })();
    }, []);

    // Check that state is not null
    if (!financialData) return <>
        <div className="flex">
            <div className="mr-[17px]">
                <ActionCard displayCustomNeutral="hidden" title='Your Big Ass Goals' width='w-[435px]' displayBrand='hidden' neutralLink='personal-info' neutralText='Edit'>
                    <p><img src={spinner} className="animate-spin mx-auto" alt="Loading"></img></p>
                </ActionCard>
                <ActionCard displayCustomNeutral="hidden" title='Savings Goals' width='w-[435px]' displayBrand='hidden' neutralLink='personal-info' neutralText='Edit' addClass='mt-[45px]'>
                    <p><img src={spinner} className="animate-spin mx-auto" alt="Loading"></img></p>
                </ActionCard>
            </div>
            <div>
                <div className="mt-[30px] px-[28px]">
                    <p className="accent-5 mb-[6px]">Other Financial Goals</p>
                    <button className='text-right-button mb-[14px]' onClick={() => navigate("/onboarding/personal-info")}>Edit</button>
                </div>
                <div className="overflow-y-scroll px-[28px] pt-[16px] max-h-[600px] min-h-[400px] min-w-[523px]">
                    <p><img src={spinner} className="animate-spin mx-auto" alt="Loading"></img></p>

                </div>
            </div>

        </div>
    </>
    if (financialData) {
        console.log('financial: ', financialData);

        return (
            isAuthenticated && <>
                <div className="flex">
                    <div className="mr-[17px]">
                        <ActionCard displayCustomNeutral="hidden" title='Your Big Ass Goals' width='w-[435px]' displayBrand='hidden' neutralLink='personal-info' neutralText='Edit'>
                            <div className='mt-[30px]'>
                                <img src={target} className='w-[60px] h-[60px] inline' alt='image'></img>
                                <p className="inline pl-[30px] paragraph-1 neutral-700"><span className="font-bold">Primary Goal: </span>{financialData.financial_profile.financial_summary.general_goal}</p>
                            </div>
                        </ActionCard>
                        <ActionCard displayCustomNeutral="hidden" title='Savings Goals' width='w-[435px]' displayBrand='hidden' neutralLink='personal-info' neutralText='Edit' addClass='mt-[45px]'>
                            { financialData.financial_profile.savings_objects.length===0?<>
                                <div className='mt-[30px]'>
                                    <img src={piggy_bank} className='w-[60px] h-[60px] inline' alt='image'></img>
                                    <p className="inline pl-[15px] paragraph-1 neutral-700">None</p>
                                </div>
                            </>:<></>}
                            { financialData.financial_profile.savings_objects.map((goal, index) => (
                                <div className='mt-[30px]' key={goal[0]}>
                                    <img src={piggy_bank} className='w-[60px] h-[60px] inline' alt='image'></img>
                                    <p className="inline pl-[15px] paragraph-1 neutral-700">{goal[0]}</p>
                                </div>
                            ))}

                        </ActionCard>
                    </div>
                    <div>
                        <div className="mt-[30px] px-[28px]">
                            <p className="accent-5 mb-[6px]">Other Financial Goals</p>
                            <button className='text-right-button mb-[14px]' onClick={() => navigate("/onboarding/personal-info")}>Edit</button>
                        </div>
                        <div className="overflow-y-scroll px-[28px] pt-[16px] max-h-[600px]">
                            {financialData.financial_profile.financial_goals.map((goal, index) => (
                                <div className='p-[30px] mb-[25px] shadow-[0px_3px_12px_rgba(0,0,0,0.08)] rounded-[8px] h-[140px] w-[450px]' key={goal[0]}>
                                    <img src={cards.find(card => (card.label === goal[1])).icon} className='w-[80px] h-[80px] inline' alt='image'></img>
                                    <p className="inline pl-[30px] accent-6">{goal[1]}</p>
                                </div>
                            ))}

                        </div>
                    </div>

                </div>
            </>

        )
    }

    // End process
    isLoading && <><Sidebar user={user}></Sidebar><div>Loading...</div></>

}

const YourGoals = () => {
    const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
    // Check if authenticated
    if (isAuthenticated) {
        // Show the launch button if Plaid Link is ready and public token was fetched
        return <UserContext.Provider value={user.email}><LoadYourGoals /></UserContext.Provider>
    }
    else {
        return <><Sidebar user={user}></Sidebar><div>Loading...</div></>;
    }
}

export default YourGoals