//import './home.css';

import postLoadSaveData from "../../utils/postLoadSaveData"
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local'){
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

//const placeholder600 = require('../../assets/rectangle2604093-f1uo-600h.png')
const VerifyEmail = () => {

  // Do something with this, like changing the link text
  const [showConfirmation, setShowConfirmation] = useState(false);

  // Get Auth0 and user data
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  isAuthenticated && console.log("ID: ", "user.sub");

  // Get URL
  const baseURL = apiBaseURL;
  const url = `${baseURL}check_service/email_verification_manual`;

  //Payload
  const payload = {
    "user_id": "user.sub"
  }

  // Post data on button click
  const sendVerificationEmail = async () => {
    console.log(payload);
    try {
      const token = await getAccessTokenSilently();
      // console.log("Got new token for function: " + token)
      // Execute the api method by calling the function
      const response = await postLoadSaveData(url, payload, token);
      console.log(response);
    } catch (err) {
      console.log(err);
    } finally {
      setShowConfirmation(true);
    }

  }

  // Post data on button click END

  // Check if logged in
  //isAuthenticated && console.log(user);
  // Show loading screen if still loading
  //if (isLoading) return <div>Loading...</div>;
  return (
    <>
    <p></p>
      <div className='large-container bg-white'>
        <div className="text-center py-4 flex flex-col items-center">

            <h1 className="big-header mt-[82px]">One more step...</h1>
            <h2 className="h2-thinner mt-[15px]">Please click the verification link in your inbox to proceed</h2>
            <p className="paragraph-4 neutral-600 max-w-[1063px] mt-[29px]">Robots are cool, but they’re not welcome here. Please check your email for a link where you can verify your information. Once you click the link in that email, you’ll be able to move forward with the onboarding flow.</p>
            <button className="text-button mt-[82px]" onClick={sendVerificationEmail()}>I Didn’t Receive an Email</button>

          

        </div>
      </div>

    </>
  )
}

export default VerifyEmail