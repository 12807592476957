import Footer from '../../components/Footer';
import { useAuth0 } from '@auth0/auth0-react';
const companies = require('../../assets/companies.svg').default
const wellsfargo = require('../../assets/logos/wells-fargo.png')
const lloyds = require('../../assets/logos/lloyds.png')
const uscmarshall = require('../../assets/logos/usc-marshall.png')
const mayoclinic = require('../../assets/logos/mayo-clinic.png')
const tala = require('../../assets/logos/tala.png')
const team = require('../../assets/our-team.png')
const jared = require('../../assets/jaredwelker.png')
const jay = require('../../assets/jaywelker.png')
const ariana = require('../../assets/arianashives.png')
const kevin = require('../../assets/kromero.png')
const ulma = require('../../assets/uescobar.png')

const Company = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <>

      <div className="main-container text-center bg-fade-gold">
        <div className="internal-container">
          <p className="accent-callout mx-auto basic-white mb-[31px]">WHO WE ARE</p>
          <h1 className='lg:px-0 px-[30px]'>We Are Building for Your Success</h1>
          <p className="paragraph-3 neutral-600 lg:w-[730px] w-full mx-auto lg:px-0 px-[30px]">We are a diverse group of veteran bankers, wealth managers, designers, and fintech operators. Our team and advisors have worked at some of the leading organizations in the world.</p>
          <img src={companies} className='mx-auto mt-[99px] mb-[29px] lg:block hidden' alt='Companies'></img>
          <div className='px-[65px] lg:hidden block'>
            <img src={lloyds} className='mx-auto py-[25px]' alt='Lloyds Logo'></img>
            <img src={mayoclinic} className='mx-auto py-[25px]' alt='Mayo Clinic Logo'></img>
            <img src={tala} className='mx-auto py-[25px] px-[25px]' alt='Tala Logo'></img>
            <img src={uscmarshall} className='mx-auto py-[25px]' alt='USC Marshall Logo'></img>
            <img src={wellsfargo} className='mx-auto brightness-200' alt='Wells Fargo Logo'></img>
          </div>
        </div>
      </div>

      <div className='large-container bg-white'>
        <div className='main-container text-center'>
          <div className='internal-container lg:pt-[160px] lg:pb-[80px] lg:px-[160px] px-[40px]'>
            <p className="accent-callout mx-auto neutral-400 mb-[31px]">MISSION / VISION /VALUES</p>
            <h1 className='lg:mb-[90px] mb-[50px]'>Who We Are</h1>
            <section className='text-left'>
              <h2 className='neutral-700 mb-[39px] h2-benefits'><span className='brand-gold'>01. </span>Manifesto</h2>
              <div className='paragraph-2'>
                <p>Everyone deserves financial well-being, but <b>managing your finances can feel overwhelming.</b></p>
                <br></br>
                <p>There are so many different options, providers, and products that promise you an easy way to manage your money.  And while we believe more choice is great, <b>it’s hard to know what’s right for you.</b></p>
                <br></br>
                <p>The incredibly rich have always had access to wealth managers and financial advisors — experts who recommend the right solutions and take care of their finances. </p>
                <br></br>
                <p><b>Isn’t it time everyone else gets the same care and support? </b></p>
                <br></br>
                <p>Kairos’ mission is to guide you to the right financial solutions for your life. </p>
                <br></br>
                <p><b>We independently match you and your goals with the tools that work best for you. </b></p>
              </div>

            </section>
            <section className='text-right lg:mt-[160px] mt-[50px]'>
              <h2 className='neutral-700 mb-[39px] h2-benefits'><span className='brand-gold'>02. </span>Vision</h2>
              <div className='paragraph-2'>
                <p>Everyone deserves financial well-being: <b>clarity</b>, <b>confidence</b>, and <b>control</b> over their money.</p>
                <br></br>
                <p>...but managing your finances can feel overwhelming.</p>
              </div>

            </section>
            <section className='text-left lg:mt-[160px] mt-[50px]'>
              <h2 className='neutral-700 mb-[39px] h2-benefits'><span className='brand-gold'>03. </span>Mission</h2>
              <div className='paragraph-2'>
                <p>Kairos’ mission is to <b>guide you</b> to the right financial solutions for your life.</p>
                <br></br>
                <p>We independently match you and your goals with the tools that work best for you.</p>
              </div>

            </section>
            <section className='text-right lg:mt-[160px] mt-[50px]'>
              <h2 className='neutral-700 mb-[39px] h2-benefits'><span className='brand-gold'>04. </span>Values</h2>
              <div className='paragraph-2'>
                <p className='mb-[15px]'><b>People as people.</b></p>
                <p className='mb-[45px]'>We treat and care for each person as a whole person. Not only as a Kairos customer, user, or team member... but as a person with a rich context and a full life of power and agency.</p>
                <p className='mb-[15px]'><b>Come as you are.</b></p>
                <p className='mb-[45px]'>We’re designing an experience so that no matter who you are or where you are in your financial journey, you can feel welcome and find solutions for your life.</p>
                <p className='mb-[15px]'><b>Listen and adapt.</b></p>
                <p className='mb-[45px]'>The world changes fast. We constantly listen for and seek new solutions that are in service of our community and mission.</p>
                <p className='mb-[15px]'><b>Independent to the core.</b></p>
                <p className='mb-[45px]'>We operate within a dynamic ecosystem. In order to give our users the best possible solutions, we need to be fiercly independent. We uphold the highest standards of integrity, honesty, and authenticity in all that we do.</p>
              </div>

            </section>
          </div>
        </div>
      </div>
      <div className='large-container bg-white'>
        <div className='internal-container text-center px-[23px] pb-[160px]'>
          <p className="accent-callout mx-auto neutral-400 mb-[31px]">THESE ARE OUR PEOPLE</p>
          <h1 className='mb-[90px]'>Team</h1>
          <img src={team} className='lg:block hidden' alt='This is our team'></img>
          <div className='flex lg:flex-row flex-col text-center pl-[15px] pr-[25px] lg:gap-[60px] gap-[50px] items-center justify-center paragraph-2'>
            <div className='w-[300px]'>
              <img src={jared} className='lg:hidden block w-[214px] h-[214px] mx-auto mb-[37px]' alt='Jared Welker'></img>
              <p><b>Jared Welker</b></p>
              <p>Co-Founder</p>
            </div>
            <div className='w-[300px]'>
              <img src={ariana} className='lg:hidden block w-[214px] h-[214px] mx-auto mb-[37px]' alt='Jared Welker'></img>
              <p><b>Ariana Shives</b></p>
              <p>Co-Founder</p>
            </div>
            <div className='w-[300px]'>
              <img src={jay} className='lg:hidden block w-[214px] h-[214px] mx-auto mb-[37px]' alt='Jared Welker'></img>
              <p><b>Jay Welker</b></p>
              <p>Co-Founder</p>
            </div>
            <div className='w-[300px]'>
              <img src={kevin} className='lg:hidden block w-[214px] h-[214px] mx-auto mb-[37px]' alt='Jared Welker'></img>
              <p><b>Kevin Romero</b></p>
              <p>Founding Engineer</p>
            </div>
            <div className='w-[300px]'>
              <img src={ulma} className='lg:hidden block w-[253px] h-[253px] mx-auto mb-[18px]' alt='Jared Welker'></img>
              <p><b>Ulma Escobar</b></p>
              <p>Front-End Engineer</p>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  )
}

export default Company