import React, { useState, useEffect, createContext, useContext } from 'react';
import InternalFooter from './Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import postLoadSaveData from '../../utils/postLoadSaveData';
import postLoadFetchData from '../../utils/postLoadFetchData';
import postLoadDeleteData from '../../utils/postLoadDeleteData';
const logo = require('../../assets/logo-color.png');
const building = require('../../assets/icons/building-gold.svg').default;
const home_insurance = require('../../assets/icons/home-insurance-gold.svg').default;
const car = require('../../assets/icons/car-gold.svg').default;
const plant = require('../../assets/icons/plant-gold.svg').default;
const empty_hourglass = require('../../assets/icons/empty-hourglass-gold.svg').default;
const life_buoy = require('../../assets/icons/life-buoy-gold.svg').default;
const building_white = require('../../assets/icons/building-white.svg').default;
const home_insurance_white = require('../../assets/icons/home-insurance-white.svg').default;
const car_white = require('../../assets/icons/car-white.svg').default;
const plant_white = require('../../assets/icons/plant-white.svg').default;
const empty_hourglass_white = require('../../assets/icons/empty-hourglass-white.svg').default;
const life_buoy_white = require('../../assets/icons/life-buoy-white.svg').default;

const cards = [{
  label: "Renter's insurance",
  icon: building,
  icon_white: building_white,
  action1: "#"
},
{
  label: "Home insurance",
  icon: home_insurance,
  icon_white: home_insurance_white,
  action1: "#"
},
{
  label: "Car insurance",
  icon: car,
  icon_white: car_white,
  action1: "#"
},
{
  label: "Life insurance",
  icon: life_buoy,
  icon_white: life_buoy_white,
  action1: "#"
},
{
  label: "Other",
  icon: plant,
  icon_white: plant_white,
  action1: "#"
},
{
  label: "I don't have any",
  icon: empty_hourglass,
  icon_white: empty_hourglass_white,
  action1: "#"
}
]
const UserContext = createContext()

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local'){
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

const LoadInsurance = () => {
  const email = useContext(UserContext);


  return (
    <>
      <div className='w-screen m-0'>

        <Fields email={email}></Fields>
        <InternalFooter></InternalFooter>
      </div>



    </>
  )
}

function Fields(props) {
  const email = props.email;
  const [profileData, setProfileData] = useState(null);
  // Get token function, urls
  const { getAccessTokenSilently, user } = useAuth0();
  const baseURL = apiBaseURL;
  const url = `${baseURL}financial_discovery/financial_profile/?email=${email}&mode=profile`;
  const urlsubmit = `${baseURL}financial_discovery/insurance/`;

  // Example: post data on load
  useEffect(() => {
    // Start the process by getting the token
    (async () => {
      try {
        const email = await user.email;
        const token = await getAccessTokenSilently();
        const consolidate = await postLoadFetchData(url, token);
        setProfileData(consolidate.status ? null : consolidate);
        setCheckedState(
          () => {
            if (consolidate) {
              console.log('test exists')
              const initial = new Array(cards.length).fill(false);
              const array = consolidate?.financial_profile.insurances;
              console.log(array)
              const indexArray=cards.map((card, index)=>{
                if (array.includes(card.label)) {
                  return true
                }
                return false
              })
              console.log(indexArray)
              return indexArray;
            } else {
              console.log('doesnt exist')
              return new Array(cards.length).fill(false)
            }
          }
        )
        console.log('data: ', consolidate)
        // console.log("Got new token: " + token)
        // Execute the api method by calling the function
        // Uncomment the line below to post data on load
        // const response = await postLoadSaveData(url, payload, token);
      } catch (err) {
        console.log(err);
      }
    })();
    // End process
  }, [])

  // Post data on button click
  const postOnClick = async () => {
    let payload = [];
    for (let i = 0; i < cards.length; i++) {
      if (checkedState[i]) {
        const element = {
          "email": user.email,
          "insurance_type": cards[i].label
        };
        payload.push(element)
      }
    }
    console.log(payload)
    try {
      const token = await getAccessTokenSilently();
      // console.log("Got new token for function: " + token)
      // Execute the api method by calling the function
      const response = await postLoadSaveData(urlsubmit, payload, token);
      console.log(response);
    } catch (err) {
      console.log(err);
    } finally {
      navigate("/financial-services")
    }

  }

  // Post data on button click END  

  const putOnClick = async () => {
    let payload = [];
    for (let i = 0; i < cards.length; i++) {
      if (checkedState[i]) {
        const element = {
          "email": user.email,
          "insurance_type": cards[i].label
        };
        payload.push(element)
      }
    }
    console.log(payload)
    try {
      const token = await getAccessTokenSilently();
      // console.log("Got new token for function: " + token)
      // Execute the api method by calling the function
      const deleted = await postLoadDeleteData(urlsubmit, {email:email}, token);
      const response = await postLoadSaveData(urlsubmit, payload, token);
      console.log(response);
    } catch (err) {
      console.log(err);
    } finally {
      navigate("/financial-services")
    }

  }


  const navigate = useNavigate();

  // Checkboxes
  const [checkedState, setCheckedState] = useState(
    new Array(cards.length).fill(false)
  );
  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };
  // Checkboxes END
  const location = useLocation();
  const [cardActive, setCardActive] = useState(null);

  return (<>
    <form onSubmit={profileData?.financial_profile.insurances?putOnClick:postOnClick}>
      <div className='flex flex-row min-h-[85vh]'>
        <nav className="hidden lg:flex flex-col flex-nowrap bg-white basis-[312px] shrink-0 grow-0">
          <img src={logo} className="w-[264.15px] h-[65px] mt-[41px] self-center" alt="KairosLogo" />
          <div className="onboarding-bar w-[30px] h-[266px] rounded-full mt-[75px] ml-[59px]">
            <div className="bar-progress w-[30px] h-[149px] rounded-full"><div className="bar-dot w-[10px] h-[10px] rounded-full top-[10px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Tell Us About Yourself</p></div></div>
            <div className="bar-dot w-[10px] h-[10px] rounded-full top-[123px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Set Your Goals</p></div>
            <div className="bar-dot w-[10px] h-[10px] rounded-full top-[240px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Connect Your Accounts</p></div>
          </div>
        </nav>
        <div className='bg-neutral-25 pb-[70px] w-[100%]'>
          <div className='mt-[50px] lg:mt-[106px] mb-[50px] lg:mb-[100px] mx-[30px] lg:mx-[109px]'>
            <h1 className='text-center'>What insurance do you need?</h1>
            <p className='paragraph-1 text-center'>We want to help you stay protected while you save. Let us know where you need insurance.
              Select all that apply.</p>
          </div>
          <div className='max-[429px]:grid-cols-1 grid grid-cols-2 md:grid-cols-3 lg:gap-8 mx-auto lg:w-[798px] w-full lg:px-0 px-[46px] gap-y-[25px] gap-x-[25px]'>
            {cards.map((card, index) => (
              <div key={index} onMouseEnter={() => { setCardActive(index); console.log(cardActive) }} onMouseLeave={() => setCardActive(null)}>
                <input id={index} className='hidden' type="checkbox" value={card.label} name={'selection-' + index} checked={checkedState[index]} onChange={() => { handleOnChange(index) }} />
                <label htmlFor={index}>
                  <div className='onboarding-card mx-auto' style={{ backgroundColor: checkedState[index] === true ? "rgba(247, 189, 132, 0.8)" : "" }}>
                    <img src={((checkedState[index] === true) || (cardActive === index)) ? card.icon_white : card.icon} className="w-[60px] h-[60px] lg:w-[80px] lg:h-[80px] mx-auto" alt={card.label} />
                    <p className='card-label mx-auto'>{card.label}</p>
                  </div>
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='flex flex-col-reverse lg:flex-row justify-between align-middle py-[37px] px-[119px] top-shadow'>
        <button className='text-only-button mt-[15px] lg:mt-0' type='button' onClick={() => navigate(-1)}>Go Back</button>
        <button className='gold-button' disabled={!checkedState.includes(true)} type='button' onClick={() => profileData?.financial_profile.insurances?putOnClick():postOnClick()}>Next</button>
      </div>
    </form>
  </>)
}

const Insurance = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  isAuthenticated && console.log(user);

  // Redirect to home if not authenticated
  !isLoading && !isAuthenticated && loginWithRedirect()

  return (
    // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
    isAuthenticated && <UserContext.Provider value={user.email}><LoadInsurance email={user.email} /></UserContext.Provider>
  )
}

export default Insurance