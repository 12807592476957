
import React, { useState, useRef, createContext, useContext, useEffect } from 'react';
import InternalFooter from './Footer';
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react';
import postLoadFetchData from '../../utils/postLoadFetchData';
const logo = require('../../assets/logo-color.png');
const grow = require('../../assets/icons/grow-gold.svg').default;
const education = require('../../assets/icons/education-gold.svg').default;
const coin = require('../../assets/icons/coin-gold.svg').default;
const house = require('../../assets/icons/house-gold.svg').default;
const books = require('../../assets/icons/books-gold.svg').default;
const accounts = require('../../assets/icons/accounts-gold.svg').default;
const optimize = require('../../assets/icons/optimize-gold.svg').default;
const retirement = require('../../assets/icons/retirement-gold.svg').default;
const insurance_person = require('../../assets/icons/insurance-person-gold.svg').default;
const people = require('../../assets/icons/people-gold.svg').default;
const debt = require('../../assets/icons/debt-gold.svg').default;
const charity = require('../../assets/icons/charity-gold.svg').default;
const insurance = require('../../assets/icons/insurance-gold.svg').default;
const target = require('../../assets/icons/target-gold.svg').default;
const empty_hourglass = require('../../assets/icons/empty-hourglass-gold.svg').default;
const grow_white = require('../../assets/icons/grow-white.svg').default;
const education_white = require('../../assets/icons/education-white.svg').default;
const coin_white = require('../../assets/icons/coin-white.svg').default;
const house_white = require('../../assets/icons/house-white.svg').default;
const books_white = require('../../assets/icons/books-white.svg').default;
const accounts_white = require('../../assets/icons/accounts-white.svg').default;
const optimize_white = require('../../assets/icons/optimize-white.svg').default;
const retirement_white = require('../../assets/icons/retirement-white.svg').default;
const insurance_person_white = require('../../assets/icons/insurance-person-white.svg').default;
const people_white = require('../../assets/icons/people-white.svg').default;
const debt_white = require('../../assets/icons/debt-white.svg').default;
const charity_white = require('../../assets/icons/charity-white.svg').default;
const insurance_white = require('../../assets/icons/insurance-white.svg').default;
const target_white = require('../../assets/icons/target-white.svg').default;
const empty_hourglass_white = require('../../assets/icons/empty-hourglass-white.svg').default;

const cards = [{
  id: 1,
  label: "Earn more on my savings",
  icon: grow,
  icon_white: grow_white,
  action1: "#"
},
{
  id: 2,
  label: "Save for my kids' education",
  icon: education,
  icon_white: education_white,
  action1: "#"
},
{
  id: 3,
  label: "Investment vs. savings account",
  icon: coin,
  icon_white: coin_white,
  action1: "#"
},
{
  id: 4,
  label: "Buy a home",
  icon: house,
  icon_white: house_white,
  action1: "#"
},
{
  id: 5,
  label: "Pay off student loans",
  icon: books,
  icon_white: books_white,
  action1: "#"
},
{
  id: 6,
  label: "See my accounts in one place",
  icon: accounts,
  icon_white: accounts_white,
  action1: "#"
},
{
  id: 7,
  label: "Get help investing my money",
  icon: optimize,
  icon_white: optimize_white,
  action1: "#"
},
{
  id: 8,
  label: "Save for retirement",
  icon: retirement,
  icon_white: retirement_white,
  action1: "#"
},
{
  id: 9,
  label: "What do I do with my old 401(k)?",
  icon: insurance_person,
  icon_white: insurance_person_white,
  action1: "#"
},
{
  id: 10,
  label: "Trusted financial resource",
  icon: people,
  icon_white: people_white,
  action1: "#"
},
{
  id: 11,
  label: "Pay off debt",
  icon: debt,
  icon_white: debt_white,
  action1: "#"
},
{
  id: 12,
  label: "Give to charitable cause(s)",
  icon: charity,
  icon_white: charity_white,
  action1: "#"
},
{
  id: 13,
  label: "Get better insurance",
  icon: insurance,
  icon_white: insurance_white,
  action1: "#"
},
{
  id: 14,
  label: "Other",
  icon: target,
  icon_white: target_white,
  action1: "#"
},
{
  id: 15,
  label: "None of the above",
  icon: empty_hourglass,
  icon_white: empty_hourglass_white,
  action1: "#"
}]
const UserContext = createContext()
const LoadGoals = () => {
  const email = useContext(UserContext);
 

  return (
    <>
      <div className='w-screen m-0'>
        <Fields email={email}></Fields>
        <InternalFooter></InternalFooter>
      </div>



    </>
  )
}

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local'){
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

function Fields(props) {
  const navigate = useNavigate();
  const ref = useRef(null);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const email = props.email;
  const [profileData, setProfileData] = useState(null);
  const baseURL = apiBaseURL;
  const url = `${baseURL}financial_discovery/financial_profile/?email=${email}&mode=profile`;

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        console.log("Got new token for function: " + token)
        // Execute the api method by calling the function
        const consolidate = await postLoadFetchData(url, token);
        setProfileData(consolidate.status?null:consolidate);
        console.log('data: ', consolidate)
        console.log('user', user)


      } catch (err) {
        console.log('Error:')
        console.log(err);
      }
    })();
  }, []);

  function submit() {
    let goals = [];
    for (let i = 0; i < cards.length; i++) {
      if (checkedState[i]) {
        goals.push(cards[i])
      }
    }
    navigate("/goals-prioritize", { state: { cards: goals } })
  };

  const handleFocus = () => {
    ref.current?.focus();
  };

  // Checkboxes
  const location = useLocation();
  let payload = location.state;
  const [checkedState, setCheckedState] = useState(
    () => {
      if (payload) {
        console.log('test exists')
        const initial = new Array(cards.length).fill(false);
        const array = payload.financial_profile.financial_goals.map(x=>x[1]);
        console.log(array)
        const indexArray=cards.map((card, index)=>{
          if (array.includes(card.label)) {
            return true
          }
          return false
        })
        console.log(indexArray)
        return indexArray;
      } else {
        console.log('doesnt exist')
        return new Array(cards.length).fill(false)
      }
    }
  );
  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };
  // Checkboxes END
  
  const [cardActive, setCardActive] = useState(null);


  return (
    (user) && <>
      <form onSubmit={submit}>
          <div className='flex flex-row min-h-[85vh]'>
            <nav className="hidden lg:flex flex-col flex-nowrap bg-white basis-[312px] shrink-0 grow-0">
              <img src={logo} className="w-[264.15px] h-[65px] mt-[41px] self-center" alt="KairosLogo" />
              <div className="onboarding-bar w-[30px] h-[266px] rounded-full mt-[75px] ml-[59px]">
                <div className="bar-progress w-[30px] h-[149px] rounded-full"><div className="bar-dot w-[10px] h-[10px] rounded-full top-[10px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Tell Us About Yourself</p></div></div>
                <div className="bar-dot w-[10px] h-[10px] rounded-full top-[123px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Set Your Goals</p></div>
                <div className="bar-dot w-[10px] h-[10px] rounded-full top-[240px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Connect Your Accounts</p></div>
              </div>
            </nav>
            <div className='bg-neutral-25 pb-[70px] w-[100%]'>
              <div className='mt-[50px] lg:mt-[106px] mb-[50px] lg:mb-[100px] mx-[30px] lg:mx-[109px]'>
                <h1 className='text-center'>What are your goals?</h1>
                <p className='paragraph-1 text-center'>Select any and all goals you’re currently working toward.</p>
              </div>
              <div className='max-[429px]:grid-cols-1 grid grid-cols-2 md:grid-cols-3 lg:gap-8 mx-auto lg:w-[798px] w-full lg:px-0 px-[46px] gap-y-[25px] gap-x-[25px]'>
                {cards.map((card, index) => (
                  <div key={index} onMouseEnter={() => {setCardActive(index);console.log(cardActive)}} onMouseLeave={() => setCardActive(null)}>
                    <input id={index} className='hidden' type="checkbox" value={card.label} name={'selection-' + index} checked={checkedState[index]} onChange={() => { handleOnChange(index) }} onClick={handleFocus}/>
                    <label htmlFor={index}>
                      <div className='onboarding-card mx-auto' style={{ backgroundColor: checkedState[index] === true ? "rgba(247, 189, 132, 0.8)" : "" }}>
                        <img src={ ((checkedState[index] === true)||(cardActive === index)) ? card.icon_white : card.icon } className="w-[60px] h-[60px] lg:w-[80px] lg:h-[80px] mx-auto" alt={card.label} />
                        <p className='card-label mx-auto'>{card.label}</p>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className='flex flex-col-reverse lg:flex-row justify-between align-middle py-[37px] px-[119px] top-shadow'>
            <button className='text-only-button mt-[15px] lg:mt-0' type='button' onClick={() => navigate(-1)}>Go Back</button>
            <button className='gold-button' disabled={!checkedState.includes(true)} type='button' onClick={() => submit()}>Next</button>
          </div>
        </form>

    </>
  )

}

const Goals = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  isAuthenticated && console.log(user);

  // Redirect to home if not authenticated
  !isLoading && !isAuthenticated && loginWithRedirect()

  return (
    // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
    isAuthenticated && <UserContext.Provider value={user.email}><LoadGoals email={user.email} /></UserContext.Provider>
  )
}

export default Goals