import React, { useEffect, useState, useRef, createContext, useContext } from 'react';
import InternalFooter from './Footer';
import { useNavigate, useLocation } from 'react-router-dom';
import postLoadSaveData from '../../utils/postLoadSaveData';
import { useAuth0 } from '@auth0/auth0-react';
import postLoadModifyData from '../../utils/postLoadModifyData';
import postLoadFetchData from '../../utils/postLoadFetchData';
import postLoadPostData from '../../utils/postLoadPostData';
import ReactSlider from 'react-slider';
import Modal from 'react-modal';
const close = require('../../assets/common/close-white.svg').default;
const logo = require('../../assets/logo-color.png');
const UserContext = createContext()
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        background: 'white',
        'border-radius': '10px',
        padding: '0px'
    },
    overlay: {zIndex: 1000}
};
const LoadBComfort = () => {
    const email = useContext(UserContext);
    return (
        <>
            <div className='w-screen m-0'>
                <Fields email={email}></Fields>
                <InternalFooter></InternalFooter>
            </div>



        </>
    )
}

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
    apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
    apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local') {
    apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

function Fields(props) {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const email = props.email;
    const [answers, setAnswers] = useState(null);
    const [tracker, setTracker] = useState(false);
    const baseURL = apiBaseURL;
    const urlprofile = `${baseURL}financial_discovery/financial_profile/?email=${email}&mode=profile`
    const url = `${baseURL}client_extras/answers/`;
    const urltrack = `${baseURL}financial_discovery/financial_profile/?email=${email}&mode=track_onboarding`;
    const urlsubmit = `${baseURL}financial_discovery/financial_card/`;

    const ref = useRef(null);

    const autoScroll = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
        ref.current?.focus();
    };

    const navigate = useNavigate();
    const location = useLocation();
    const payload = location.state;
    const [slider, setSlider] = useState(null);
    const [profileData, setProfileData] = useState(null);


    // To be used to add the Back buttons
    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently();
                console.log("Got new token for function: " + token)
                // Execute the api method by calling the function
                const response = await postLoadPostData(url, { "question": "How comfortable are you with managing all aspects of your personal finance?" }, token);
                const track = await postLoadFetchData(urltrack, token);
                const consolidate = await postLoadFetchData(urlprofile, token);
                setProfileData(consolidate.status ? null : consolidate);
                setTracker(track.financial_profile?.financial_summary);
                setAnswers(response);
                setSlider(1);
                console.log('data: ', response)
                console.log('user', user)


            } catch (err) {
                console.log(err);
            }
        })();
    }, []);

    // Post data on button click
    const postOnClick = async () => {
        let payload = {
            "email": user.email,
            "question_id": answers[0].question_id,
            "answers": [{ "answer": (slider+1).toString() }]
        }
        console.log(payload);
        try {
            const token = await getAccessTokenSilently();
            // Execute the api method by calling the function
            const response = await postLoadSaveData(urlsubmit, payload, token);
            console.log(response);
        } catch (err) {
            console.log(err);
        } finally {
            navigate("/onboarding/stacking",{state:true})
        }

    }
    const putOnClickProfile = async () => {
        let payload = {
            "email": user.email,
            "question_id": answers[0].question_id,
            "answers": [{ "answer": (slider+1).toString() }]
        }
        console.log(payload);
        try {
            const token = await getAccessTokenSilently();
            console.log("Got new token for function: " + token)
            // Execute the api method by calling the function
            const response = await postLoadModifyData(urlsubmit, payload, token);
            console.log(response);
        } catch (err) {
            console.log(err);
        } finally {
            navigate("/onboarding/stacking",{state:true})
        }
    }

    // Post data on button click END

    //Modal Functions START
    const [modalIsOpen, setIsOpen] = React.useState(false);
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    // Modal Functions END

    return (
        (user && answers) && <>
            <form onSubmit={profileData?.financial_profile["How comfortable are you with managing all aspects of your personal finance?"].length > 0 ? putOnClickProfile : postOnClick}>
                <div className='flex flex-row min-h-[85vh]'>
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Sign in to bookmark"
                    >
                        <div className='bg-black py-[35px] px-[50px] w-[400px] lg:w-[569px] h-[310px] relative'>
                            <p className='basic-white text-center paragraph-1-to-mobile'>Your familiarity with your own finances will help us recommend products at the right level for you — no matter what level that is.</p>
                            <img src={close} onClick={closeModal} alt="Close" className='cursor-pointer w-[31px] h-[31px] absolute bottom-[64px] right-1/2 translate-x-2/4'></img>
                        </div>

                    </Modal>
                    <nav className="hidden lg:flex flex-col flex-nowrap bg-white basis-[312px] shrink-0 grow-0">
                        <img src={logo} className="w-[264.15px] h-[65px] mt-[41px] self-center" alt="KairosLogo" />
                        <div className="onboarding-bar w-[30px] h-[266px] rounded-full mt-[75px] ml-[59px]">
                            <div className="bar-progress w-[30px] h-[149px] rounded-full"><div className="bar-dot w-[10px] h-[10px] rounded-full top-[10px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Tell Us About Yourself</p></div></div>
                            <div className="bar-dot w-[10px] h-[10px] rounded-full top-[123px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Set Your Goals</p></div>
                            <div className="bar-dot w-[10px] h-[10px] rounded-full top-[240px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Connect Your Accounts</p></div>
                        </div>
                    </nav>
                    <div className='bg-neutral-25 pb-[70px] w-[100%]'>
                        <div className='mt-[25px] lg:mt-[106px] mb-[50px] lg:mb-[100px] mx-[30px] lg:mx-[109px] text-center'>
                            <h1 className='text-center'>How comfortable are you with managing all aspects of your personal finance?</h1>
                            <p className='paragraph-1 text-center'>Are you a seasoned pro or just starting out? We’re here to help either way.</p>
                            <p className='paragraph-1 text-center'>Please select the (1) statement that best describes you.</p>
                            <br/>
                            <p className='paragraph-1 text-center underline cursor-pointer inline' onClick={openModal}>Why do we ask this?</p>
                        </div>
                        <div className='mx-auto lg:w-[798px] lg:p-0 w-full px-[20px] max-w-[798px]'>
                            <ReactSlider className="onboarding-horizontal-slider mx-auto"
                                marks
                                min={0}
                                max={4}
                                defaultValue={[2]}
                                thumbClassName="onboarding-thumb"
                                trackClassName="onboarding-track"
                                onChange={(newValue, thumbIndex) => setSlider(newValue)}
                                renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                            />
                            <div className='flex flex-row mx-auto paragraph-1-to-mobile justify-between mt-[45px]'>
                                <p className='max-[429px]:px-[2px] md:text-center text-left'>I budget like a<br />squirrel with no acorns</p>
                                <p className='max-[429px]:px-[2px] text-center'>I tell my money what to do, it<br />just doesn’t always listen</p>
                                <p className='max-[429px]:px-[2px] md:text-center text-right'>I taught Jerome Powell<br />everything he knows</p>
                            </div>
                        </div>




                    </div>
                </div>

                <div className='flex flex-col-reverse lg:flex-row justify-between align-middle py-[37px] px-[119px] top-shadow'>
                    <button className='text-only-button mt-[15px] lg:mt-0' type='button' onClick={() => navigate(-1)}>Go Back</button>
                    <button ref={ref} className='gold-button' disabled={slider === null} type='button' onClick={() => profileData?.financial_profile["How comfortable are you with managing all aspects of your personal finance?"].length > 0 ? putOnClickProfile() : postOnClick()}>Next</button>
                </div>


            </form>
        </>
    )

}

const BComfort = () => {
    const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    isAuthenticated && console.log(user);

    // Redirect to home if not authenticated
    !isLoading && !isAuthenticated && loginWithRedirect()

    return (
        // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
        isAuthenticated && <UserContext.Provider value={user.email}><LoadBComfort email={user.email} /></UserContext.Provider>
    )
}

export default BComfort