//const placeholder600 = require('../../assets/rectangle2604093-f1uo-600h.png')
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from 'react'

const SuscriptionCancellation = () => {
  const navigate = useNavigate();
  const { loginWithRedirect, logout } = useAuth0();

  return (
    <>
      <p></p>
      <div className='large-container bg-white'>
        <div className="text-center py-4 flex flex-col items-center">

          <h1 className="big-header mt-[97px]">Bye :(</h1>
          <h2 className="h2-thinner mt-[15px]">We’re sorry to see you go!</h2>
          <p className="paragraph-4 neutral-600 max-w-[1063px] mt-[15px]">Your subscription will remain active until the end of your current pay period.<br/>After that, you’ll lose access to Kairos but we’ll keep your goal information on file so if you ever decide to come back to Kairos you can jump right back in.</p>
          <button className="callout-brand-button mt-[57px]" onClick={() => navigate("/dashboard")}>Go Back to My Dashboard</button>
          <button className="text-button mt-[2px]" onClick={() => logout({ returnTo: window.location.origin+'/loggedout' })}>Log Out</button>

        </div>
      </div>

    </>
  )
}

export default SuscriptionCancellation