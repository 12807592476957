
import React, { useState, useEffect, createContext, useContext } from 'react';
import InternalFooter from './Footer';
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react';
import postLoadSaveData from '../../utils/postLoadSaveData';
import postLoadFetchData from '../../utils/postLoadFetchData';
import postLoadDeleteData from '../../utils/postLoadDeleteData';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const logo = require('../../assets/logo-color.png');
const grow = require('../../assets/icons/grow-gold.svg').default;
const education = require('../../assets/icons/education-gold.svg').default;
const coin = require('../../assets/icons/coin-gold.svg').default;
const house = require('../../assets/icons/house-gold.svg').default;
const books = require('../../assets/icons/books-gold.svg').default;
const accounts = require('../../assets/icons/accounts-gold.svg').default;
const optimize = require('../../assets/icons/optimize-gold.svg').default;
const retirement = require('../../assets/icons/retirement-gold.svg').default;
const insurance_person = require('../../assets/icons/insurance-person-gold.svg').default;
const people = require('../../assets/icons/people-gold.svg').default;
const debt = require('../../assets/icons/debt-gold.svg').default;
const charity = require('../../assets/icons/charity-gold.svg').default;
const insurance = require('../../assets/icons/insurance-gold.svg').default;
const target = require('../../assets/icons/target-gold.svg').default;
const empty_hourglass = require('../../assets/icons/empty-hourglass-gold.svg').default;
const dots = require('../../assets/icons/dots.svg').default;

const UserContext = createContext()
const LoadGoalsPrioritize = () => {
  const email = useContext(UserContext);
  
  return (
    <>
      <div className='w-screen m-0'>
        <Fields email={email}></Fields>
        <InternalFooter></InternalFooter>
      </div>



    </>
  )
}

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local'){
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

function Fields(props) {
  const email = props.email;

  // Get token function, urls
  const { getAccessTokenSilently, user } = useAuth0();
  const [profileData, setProfileData] = useState(null);
  const baseURL = apiBaseURL;
  const url = `${baseURL}financial_discovery/financial_profile/?email=${email}&mode=profile`
  const urlsubmit = `${baseURL}financial_discovery/financial_goal/`;
  let data = null;
  // Example: post data on load
  useEffect(() => {
    // Start the process by getting the token
    (async () => {
      try {
        const email = await user.email;
        const token = await getAccessTokenSilently();
        const consolidate = await postLoadFetchData(url, token);
        setProfileData(consolidate.status?null:consolidate);
        console.log('data: ', consolidate)
        console.log('user', user)
        // console.log("Got new token: " + token)
        // Execute the api method by calling the function
        // Uncomment the line below to post data on load
        // const response = await postLoadSaveData(url, payload, token);
      } catch (err) {
        console.log(err);
      }
    })();
    // End process
  }, [])

  const navigate = useNavigate();
  const location = useLocation();
  let cards = location.state.cards;

  const [goalCards, updateGoalCards] = useState(cards);

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(goalCards);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    updateGoalCards(items);
  }

  const postOnClick = async () => {
    let payload = [];
    for (let i = 0; i < goalCards.length; i++) {
      const element = {
        email: user.email,
        priority: i + 1,
        goal_name: goalCards[i].label
      };
      payload.push(element);
      console.log(element);
    }
    console.log(payload)
    try {
      const token = await getAccessTokenSilently();
      // console.log("Got new token for function: " + token)
      // Execute the api method by calling the function
      const response = await postLoadSaveData(urlsubmit, payload, token);
      console.log(response);
    } catch (err) {
      console.log(err);
    } finally {
      navigate("/savings-goals")
    }

  }

  const putOnClick = async () => {
    let payload = [];
    for (let i = 0; i < goalCards.length; i++) {
      const element = {
        email: user.email,
        priority: i + 1,
        goal_name: goalCards[i].label
      };
      payload.push(element);
      console.log(element);
    }
    console.log(payload)
    try {
      const token = await getAccessTokenSilently();
      // console.log("Got new token for function: " + token)
      // Execute the api method by calling the function
      const deleted = await postLoadDeleteData(urlsubmit, {email:email}, token);
      const response = await postLoadSaveData(urlsubmit, payload, token);
      console.log(response);
    } catch (err) {
      console.log(err);
    } finally {
      navigate("/savings-goals")
    }

  }

  return (<><div className='flex flex-row min-h-[85vh]'>
  <nav className="hidden lg:flex flex-col flex-nowrap bg-white basis-[312px] shrink-0 grow-0">
    <img src={logo} className="w-[264.15px] h-[65px] mt-[41px] self-center" alt="KairosLogo" />
    <div className="onboarding-bar w-[30px] h-[266px] rounded-full mt-[75px] ml-[59px]">
      <div className="bar-progress w-[30px] h-[149px] rounded-full"><div className="bar-dot w-[10px] h-[10px] rounded-full top-[10px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Tell Us About Yourself</p></div></div>
      <div className="bar-dot w-[10px] h-[10px] rounded-full top-[123px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Set Your Goals</p></div>
      <div className="bar-dot w-[10px] h-[10px] rounded-full top-[240px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Connect Your Accounts</p></div>
    </div>
  </nav>
  <div className='bg-neutral-25 pb-[70px] w-[100%]'>
    <div className='mt-[50px] lg:mt-[106px] mb-[50px] lg:mb-[100px] mx-[30px] lg:mx-[109px]'>
      <h1 className='text-center'>Rank your goals in order of importance.</h1>
      <p className='paragraph-1 text-center'>Click and drag your goals so they are in the order that currently represents your priorities. Don’t worry about what you think your priorities “should” be — tell us what they really are.</p>
    </div>
    
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <div className='flex flex-row w-[500px] mx-auto'>
        <div className='grid grid-cols-1 gap-6 w-[100px]'>
          {cards.map((card, index) => (
            <p className='accent-1 my-auto'>#{index + 1}</p>

          ))}
        </div>
        <Droppable droppableId="goals">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps} className='grid grid-cols-1 gap-6 w-[400px]'>
              {goalCards.map((card, index) => (
                <Draggable key={card.id} draggableId={card.id.toString()} index={index}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className='onboarding-drag-card w-[400px] h-[140px]'>
                      <img src={dots} className="h-[29px] mr-[36px]" alt="dots" />
                      <img src={card.icon} className="w-[80px] h-[80px] mr-[36px]" alt={card.label} />
                      <p className='drag-card-label mx-auto'>{card.label}</p>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>                    
        
      </div>
    </DragDropContext>





  </div>
</div>

<div className='flex flex-col-reverse lg:flex-row justify-between align-middle py-[37px] px-[119px] top-shadow'>
  <button className='text-only-button' onClick={() => navigate(-1)}>Go Back</button>
  <button className='gold-button' onClick={() => profileData?putOnClick():postOnClick()}>Next</button>
</div>
</>)
}

const GoalsPrioritize = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  isAuthenticated && console.log(user);

  // Redirect to home if not authenticated
  !isLoading && !isAuthenticated && loginWithRedirect()

  return (
    // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
    isAuthenticated && <UserContext.Provider value={user.email}><LoadGoalsPrioritize email={user.email} /></UserContext.Provider>
  )
}

export default GoalsPrioritize