import "./footer.css"
import twitter from '../assets/whitebackgroundi417-s4u.svg'
import instagram from '../assets/instagram.svg'
import linkedin from '../assets/linkedin.svg'
import FooterLogoutButton from "./FooterLogoutButton"
import { useNavigate } from "react-router-dom"
const logowhite = require('../assets/kairoslogoassetsfinalkairoslogowhite1i417-sdw9-200h.png')

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="large-container bg-black relative z-[10]">
      <div className="accent-container mx-auto px-[96px]">
        <section className="my-[36px]">
          <img src={logowhite} className="inline"></img>
          <button onClick={()=>navigate("/dashboard")} className={`nav-gold-button mx-[42px] ${window.location.pathname==="/main-dashboard"?"hidden":""}`}>Dashboard</button>
        </section>
        <hr></hr>
        <section className="mt-[66px] flex lg:flex-row flex-col space-x-[70px]">
          <div>
            <p className="accent-1 brand-light-grey mr-[17px] mb-[89px]">Wealth Management<br/>for The Rest Of Us</p>
            <div className="flex gap-[22.22px]">
              <a href="https://twitter.com/KairosFinancial"><img src={twitter} alt="twitter"></img></a>
              <a href="https://www.instagram.com/kairos.financial/"><img src={instagram} alt="twitter"></img></a>
              <a href="https://www.linkedin.com/company/kairos-financial/"><img src={linkedin} alt="twitter"></img></a>
            </div>
          </div>
          <div className="flex flex-col whitespace-nowrap space-y-[10px]">
            <p className="accent-2 brand-light-grey mb-[21px]">PAGES</p>
            <button onClick={() => navigate("/")} className="text-left accent-3 brand-light-grey">Home</button>
            <button onClick={() => navigate("/features")} className="text-left accent-3 brand-light-grey">About</button>
            <button onClick={() => navigate("/company")} className="text-left accent-3 brand-light-grey">Company</button>
            <button onClick={() => navigate("/faq")} className="text-left accent-3 brand-light-grey">FAQ</button>
          </div>
          <div className="flex flex-col whitespace-nowrap space-y-[10px]">
            <p className="accent-2 brand-light-grey mb-[21px]">COMPANY</p>
            <a href="#" className="accent-3 brand-light-grey">Terms & Conditions</a>
            <a href="#" className="accent-3 brand-light-grey">Privacy Policy</a>
          </div>
          <div className="flex flex-col whitespace-nowrap space-y-[10px]">
            <p className="accent-2 brand-light-grey mb-[21px]">ACCOUNT</p>
            <button onClick={()=>navigate("/dashboard")} className="text-left accent-3 brand-light-grey">Dashboard</button>
            <FooterLogoutButton />
          </div>
          <div className="flex flex-col whitespace-nowrap space-y-[10px]">
            <p className="accent-2 brand-light-grey mb-[21px]">HELP</p>
            <button onClick={() => window.Beacon('open')} className="accent-3 brand-light-grey">Contact Us</button>
          </div>
        </section>
        <p className="copyright">(c) 2023 Bay Front Ventures Inc.</p>

      </div>
    </div>
  );
}

export default Footer;