
import React, { useState, useEffect, createContext, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router';
import Footer from '../Onboarding/Footer';
import { useAuth0 } from '@auth0/auth0-react';
import LoadLink from '../../components/plaid/LoadLink';
import postLoadFetchData from '../../utils/postLoadFetchData';
const logo = require('../../assets/logo-color.png');
const logomark = require('../../assets/logomark-color-shadowed.png')

const UserContext = createContext()

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
    apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
    apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local') {
    apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

const LoadStacking = () => {
    const email = useContext(UserContext);
    const location = useLocation();
    const navigate = useNavigate();
    const { isAuthenticated, user, isLoading, error, loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const [clicked, setClicked] = useState(false);
    const baseURL = apiBaseURL;
    const url = `${baseURL}financial_discovery/financial_profile/?email=${email}&mode=track_onboarding`;
    const [profileData, setProfileData] = useState(null);
    // Example: post data on load
    useEffect(() => {
        // Start the process by getting the token
        (async () => {
            try {
                const email = await user.email;
                const token = await getAccessTokenSilently();
                const response = await postLoadFetchData(url, token);
                setProfileData(response.status ? null : response);
                console.log('data: ', response)
                // console.log("Got new token: " + token)
                // Execute the api method by calling the function
                // Uncomment the line below to post data on load
                // const response = await postLoadSaveData(url, payload, token);
            } catch (err) {
                console.log(err);
            }
        })();
        // End process
    }, [])

    return (
        <>
            <div className='w-screen m-0'>
                <div className='flex flex-row min-h-[85vh]'>
                    <nav className="hidden lg:flex flex-col flex-nowrap bg-white basis-[312px] shrink-0 grow-0">
                        <img src={logo} className="w-[264.15px] h-[65px] mt-[41px] self-center" alt="KairosLogo" />
                        <div className="onboarding-bar w-[30px] h-[266px] rounded-full mt-[75px] ml-[59px]">
                            <div className="bar-progress w-[30px] h-[266px] rounded-full"><div className="bar-dot w-[10px] h-[10px] rounded-full top-[10px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Tell Us About Yourself</p></div></div>
                            <div className="bar-dot w-[10px] h-[10px] rounded-full top-[123px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Set Your Goals</p></div>
                            <div className="bar-dot w-[10px] h-[10px] rounded-full top-[240px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Connect Your Accounts</p></div>
                        </div>
                    </nav>
                    <div className='bg-neutral-25 pb-[100px] w-[100%] min-h-[500px]'>
                        <div className='mt-[50px] lg:mt-[106px] mb-[50px] lg:mb-[100px] mx-[30px] lg:mx-[109px]'>
                            <h1 className='text-center hidden lg:block'>Now stacking...</h1>
                        </div>
                        <div className='max-w-full rounded-[20px] w-[400px] md:w-[588px] h-[526px] lg:h-[607px] block mx-auto shadow-[0px_6px_24px_rgba(0,0,0,0.08)] text-center pt-[45px] px-[20px]'>
                            <img src={logomark} className='h-[98px] w-[98px] logomark-shadow mx-auto' />

                            <h3 className='heading-1-5 mt-[30px] pb-[10px] lg:pb-0'>We're creating your first Finstack!</h3>
                            <div className='h-[0.5px] w-[300px] lg:w-[504px] bg-gold mx-auto my-[30px]'></div>
                            <div className='accent-6-to-h2-mobile basic-black mb-[19px]'>Here's what to expect:</div>
                            <p className='max-w-[415px] paragraph-1-to-mobile mx-auto neutral-600 mt-[30px] lg:mt-0'>📚  A stack of three products designed to address your most pressing financial goals <br /><br />

                                🤑  Immediate excitement and feelings of clarity, confidence, and control<br /><br />

                                🔮  Once you’ve taken advantage of the products in your first stack, come back and we’ll curate your next one</p>

                        </div>





                    </div>
                </div>

                <div className='flex flex-col-reverse lg:flex-row justify-between align-middle py-[37px] lg:px-[119px] px-[28px] top-shadow'>
                    <button className='text-only-button mt-[15px] lg:mt-0 hidden lg:inline' type='button' onClick={() => navigate(-1)}>Go Back</button>
                    <div className='flex flex-col-reverse lg:flex-row lg:gap-0 gap-[15px]'>


                        <button className='grow-gold-button mx-auto' type='button' onClick={() => navigate("/onboarding/payment", { state: true })}>Next</button>
                    </div>

                </div>


                <Footer></Footer>
            </div>

        </>
    )
}

const Stacking = () => {
    const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    isAuthenticated && console.log(user);

    // Redirect to home if not authenticated
    !isLoading && !isAuthenticated && loginWithRedirect()

    return (
        // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
        isAuthenticated && <UserContext.Provider value={user.email}><LoadStacking email={user.email} /></UserContext.Provider>
    )
}

export default Stacking