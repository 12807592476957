import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from "@auth0/auth0-react";

const running_env = process.env.REACT_APP_RUNNING_ENV;

const domain = running_env === 'development' || running_env === 'local' ? process.env.REACT_APP_AUTH0_DOMAIN_DEV : process.env.REACT_APP_AUTH0_DOMAIN_PROD;
const clientId = running_env === 'development' || running_env === 'local' ? process.env.REACT_APP_AUTH0_CLIENT_ID_DEV : process.env.REACT_APP_AUTH0_CLIENT_ID_PROD;
const audience = running_env === 'development' || running_env === 'local' ? process.env.REACT_APP_AUTH0_AUDIENCE_DEV : process.env.REACT_APP_AUTH0_AUDIENCE_PROD;
// console.log(domain, " ", clientId, " ", audience);

var redirectUri = '';

if (running_env === 'development') {
  redirectUri = process.env.REACT_APP_AUTH0_REDIRECT_URI_DEV;
} else if (running_env === 'production') {
  redirectUri = process.env.REACT_APP_AUTH0_REDIRECT_URI_PROD;
} else if (running_env === 'local'){
  redirectUri = process.env.REACT_APP_AUTH0_REDIRECT_URI_LOCAL;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Auth0Provider domain={domain}
      clientId={clientId}
      redirectUri={redirectUri} //{`https://kairos.nyc/dashboard`}
      audience={audience} //new
      scope="profile" /* new */>
      <App />
    </Auth0Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
