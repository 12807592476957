
import React, { useState, useRef, useEffect, createContext, useContext } from 'react';
import InternalFooter from './Footer';
import { useNavigate, useLocation } from 'react-router-dom';
import postLoadFetchData from '../../utils/postLoadFetchData';
import { useAuth0 } from '@auth0/auth0-react';
const logo = require('../../assets/logo-color.png');
const time = require('../../assets/icons/time.svg').default;
const clock = require('../../assets/icons/clock-gold.svg').default;
const worker = require('../../assets/icons/worker.svg').default;
const self = require('../../assets/icons/self.svg').default;
const clipboard = require('../../assets/icons/clipboard-white.svg').default;
const time_white = require('../../assets/icons/time-white.svg').default;
const clock_white = require('../../assets/icons/clock-white.svg').default;
const worker_white = require('../../assets/icons/worker-white.svg').default;
const self_white = require('../../assets/icons/self-white.svg').default;
const clipboard_white = require('../../assets/icons/clipboard-white.svg').default;
const cards = [{
  label: "I work part-time",
  icon: time,
  icon_white: time_white,
  action1: "#"
},
{
  label: "I work full-time",
  icon: clock,
  icon_white: clock_white,
  action1: "#"
},
{
  label: "I am a gig worker",
  icon: worker,
  icon_white: worker_white,
  action1: "#"
},
{
  label: "I am self-employed",
  icon: self,
  icon_white: self_white,
  action1: "#"
},
{
  label: "Other",
  icon: clipboard,
  icon_white: clipboard_white,
  action1: "#"
}]
const UserContext = createContext()


const LoadEmploymentStatus = () => {
  const email = useContext(UserContext);
  
  return (
    <>
      <div className='w-screen m-0'>
        <Fields email={email}></Fields>
        <InternalFooter></InternalFooter>
      </div>



    </>
  )
}

function Fields(props) {
  const { user} = useAuth0();
  const navigate = useNavigate();

  function submit() {
    payload.employee_status = selection;
    console.log(payload);
    if (selection === 'I am a gig worker') {
      navigate("/gig-work", { state: payload })
    } else {
      navigate("/property", { state: payload })
    }
  };
  function back() {
    payload.employee_status = selection;
    console.log(payload);
      navigate(-1, { state: payload })
  };
  const ref = useRef(null);
  const location = useLocation();
  let payload = location.state
  const [cardSelected, setCardSelected] = useState(()=>{
    if (payload.employee_status!="") {
      return cards.findIndex(element=> element.label==payload.employee_status);
    } else {
      return null
    }
  });
  const [cardActive, setCardActive] = useState(null);
  const [selection, setSelection] = useState(()=>{
    if (payload.employee_status!="") {
      return payload.employee_status;
    } else {
      return ""
    }
  });

  const autoFocus = () => {
    ref.current.focus();
  };


  return (
    (user) && <>
      <form onSubmit={submit}>
          <div className='flex flex-row min-h-[85vh]'>
            <nav className="hidden lg:flex flex-col flex-nowrap bg-white basis-[312px] shrink-0 grow-0">
              <img src={logo} className="w-[264.15px] h-[65px] mt-[41px] self-center" alt="KairosLogo" />
              <div className="onboarding-bar w-[30px] h-[266px] rounded-full mt-[75px] ml-[59px]">
                <div className="bar-progress w-[30px] h-[30px] rounded-full"><div className="bar-dot w-[10px] h-[10px] rounded-full top-[10px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Tell Us About Yourself</p></div></div>
                <div className="bar-dot w-[10px] h-[10px] rounded-full top-[123px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Set Your Goals</p></div>
                <div className="bar-dot w-[10px] h-[10px] rounded-full top-[240px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Connect Your Accounts</p></div>
              </div>
            </nav>
            <div className='bg-neutral-25 pb-[70px] w-[100%]'>
              <div className='mt-[50px] lg:mt-[106px] mb-[50px] lg:mb-[100px] mx-[30px] lg:mx-[109px]'>
                <h1 className='text-center'>What is your employment status?</h1>
                <p className='paragraph-1 text-center'>Let us know what your work life looks like right now. You’ll be able to update this any time!</p>
              </div>
              <div className='max-[429px]:grid-cols-1 grid grid-cols-2 md:grid-cols-3 lg:gap-8 mx-auto lg:w-[798px] w-full lg:px-0 px-[46px] gap-y-[25px] gap-x-[25px]'>
                {cards.map((card, index) => (
                  <div key={index} onMouseEnter={() => {setCardActive(index);console.log(cardActive)}} onMouseLeave={() => setCardActive(null)}>
                    <input id={index} type="radio" value={card.label} name='selection' className='hidden' onChange={(e) => { setSelection(e.target.value); console.log(e.target.value) }} />
                    <label htmlFor={index}>
                      <div className='onboarding-card' onClick={() => setCardSelected(index)} style={{ backgroundColor: cardSelected === index ? "rgba(247, 189, 132, 0.8)" : "" }}>
                        <img src={ ((cardSelected === index)||(cardActive === index)) ? card.icon_white : card.icon } className="w-[60px] h-[60px] lg:w-[80px] lg:h-[80px] mx-auto" alt={card.label} />
                        <p className='card-label mx-auto'>{card.label}</p>
                      </div>
                    </label>
                  </div>
                ))}
              </div>




            </div>
          </div>
          <div className='flex flex-col-reverse lg:flex-row justify-between align-middle py-[37px] px-[119px] top-shadow'>
            <button className='text-only-button' type='button' onClick={() => back()}>Go Back</button>
            <button ref={ref} className='gold-button' disabled={selection === ""} type='submit' onClick={() => submit()}>Next</button>
          </div>


        </form>

    </>
  )

}

const EmploymentStatus = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  isAuthenticated && console.log(user);

  // Redirect to home if not authenticated
  !isLoading && !isAuthenticated && loginWithRedirect()

  return (
    // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
    isAuthenticated && <UserContext.Provider value={user.email}><LoadEmploymentStatus email={user.email} /></UserContext.Provider>
  )
}

export default EmploymentStatus