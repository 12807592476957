// Function to fetch data from Auth0 protected API endpoint
// Meant to be used when a page/component is mounted or is loaded

async function postLoadFetchData(url, token) {
    let options = {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    };

    try {

        const response = await fetch(url, options)

        if (!response.ok) {
            // console.log('this is the response: ', response.status)
            // return status code
            return response;
            // throw new Error(`Error! status: ${response.status}`);
        }

        
        const result = await response.json();
        // console.log(result)
        return result;

    }
    catch (err) {
        console.log(err);
    }
    
}
export default postLoadFetchData;