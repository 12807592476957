import * as amplitude from '@amplitude/analytics-browser';
import axios from 'axios';


var apiBaseURL = '';
// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
if (running_env === 'development') {
    apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
    apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local') {
    apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

const baseURL = apiBaseURL;
const url = `${baseURL}client_extras/event_str_initializer/`;
let options = {
    method: 'GET'
};

export const fetchData = async (fetchurl) => {
    try {
        const response = await axios.get(fetchurl);
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data from the API');
    }
};
if (running_env === 'production') {
    fetchData(url).then(data=>{amplitude.init(data.string);console.log('+1 success!');})
}
