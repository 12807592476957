import Benefits from './components/Benefits';
import './home.css';
import { useAuth0 } from '@auth0/auth0-react';
import Footer from '../../components/Footer';
import FooterLogOut from '../../components/FooterLogOut';
import { useState } from 'react';
import postLoadSaveData from '../../utils/postLoadSaveData';
import { useNavigate } from 'react-router-dom';

//const placeholder600 = require('../../assets/rectangle2604093-f1uo-600h.png')
const laptop = require('../../assets/marketing/laptop.png')
const stack = require('../../assets/marketing/FinstackM0102.png')
const products = require('../../assets/marketing/FinstackM0103.png')
const analyze = require('../../assets/marketing/financial_situation.png')
const goals = require('../../assets/marketing/Group10080.png')
const accounts = require('../../assets/marketing/Group10089.png')
const graph = require('../../assets/marketing/Group10076.png')


// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';
var authDomain = '';
//const isProduction = running_env === 'development' || running_env === 'local' ? false : true;

if (running_env === 'development') {
    apiBaseURL = process.env.REACT_APP_API_URL_DEV;
    authDomain = process.env.REACT_APP_AUTH0_DOMAIN_DEV;
} else if (running_env === 'production') {
    apiBaseURL = process.env.REACT_APP_API_URL_PROD;
    authDomain = process.env.REACT_APP_AUTH0_DOMAIN_PROD;
} else if (running_env === 'local') {
    apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
    authDomain = process.env.REACT_APP_AUTH0_DOMAIN_DEV;
}

const Home = () => {

    const [accessCode, setAccessCode] = useState("");
    const [accessCodeError, setAccessCodeError] = useState("")
    const baseURL = apiBaseURL;
    const urlsubmit = `${baseURL}client_extras/validate_access_code/`;
    // Check if logged in
    const { loginWithRedirect, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const domain = authDomain
    const navigate = useNavigate();
    // Show loading screen if still loading
    //if (isLoading) return <div>Loading...</div>;

    // Post data on button click
    const postOnClick = async () => {
        setAccessCodeError("")
        let payload = {
            access_code: accessCode
        }
        console.log(payload);
        try {
            // console.log("Got new token for function: " + token)
            // Execute the api method by calling the function
            let options = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            };
            const response = await fetch(urlsubmit, options);
            console.log('response', response.json().data)
            if (response.status === 200) {
                localStorage.setItem("accessCodeIsValid", "yes");
                loginWithRedirect({
                    screen_hint: 'signup',
                    audience: `https://${domain}/api/v2/`,
                    scope: "profile",
                })
            } else {
                // Error 'code not valid'
                setAccessCodeError("Your code is not valid")
                console.log('the code is not valid')
            }
            console.log(response);
        } catch (err) {
            console.log(err);
            //Error 'there was an error'
        }

    }


    return (
        <>
            <p></p>
            <div className='large-container bg-white'>
                <div className='main-container mx-auto lg:px-[112px] bg-gold-20 !px-[20px] !lg:px-[40px]'>
                    <div className='internal-container flex flex-col text-center'>
                        <h1 className='!m-0'>Find Your Finstack<sup>&reg;</sup></h1>
                        <p className='paragraph-1 neutral-600 mt-[20px] mb-[35px]'>Managing your finances can feel overwhelming.<br></br>Find, build, and optimize the stack of products that will lead you to your financial future.</p>
                        <div>
                            <button className='gold-button' onClick={() => loginWithRedirect({
                                screen_hint: 'signup',
                                audience: `https://${domain}/api/v2/`,
                                scope: "profile", // new
                            })}>Find My Finstack &#8594;</button>
                        </div>
                        <div>
                            <img src={laptop} alt="Desktop app sample" className='w-[758px] mx-auto mt-[30px]' />
                        </div>
                    </div>
                </div>
            </div>

            <div className='large-container bg-white'>

                <div className='internal-container mx-auto lg:px-[70px] flex lg:flex-row flex-col text-left'>
                    <div className='px-[30px]'>
                        <p className='neutral-400 accent-callout mt-[45px] lg:mt-[120px]'>WEALTH MANAGEMENT FOR THE REST OF US</p>
                        <h1>Finstack<sup>&reg;</sup>  by Kairos.</h1>
                        <div className='lg:hidden lg:ml-[70px] h-[350px] w-full overflow-hidden lg:w-[500px] max-h-[550px] shrink-0 flex items-center justify-center relative'>
                            <div className='h-[600px] lg:h-[950px] rotate-[30deg] bg-neutral-20 shrink-0'>
                                <img src={stack} alt="Desktop app sample" className='object-scale-down h-[600px] lg:h-[950px]' />
                            </div>

                        </div>
                        <p className='paragraph-1 neutral-600 mt-[20px] lg:mt-[100px] mb-[35px]'><strong>Finstack</strong> is a unique set of financial products <strong>curated just for you</strong> by our proprietary AI technology. We get to know you and your current financial situation (no matter how good or bad it seems) and recommend the products that will help you <strong>build sustainable wealth</strong>.</p>
                        <div className='mb-[45px] lg:mb-[68px]'>
                            <button className='gold-button' onClick={() => loginWithRedirect({
                                screen_hint: 'signup',
                                audience: `https://${domain}/api/v2/`,
                                scope: "profile", // new
                            })}>Find Your Finstack &#8594;</button>
                        </div>
                    </div>

                    <div className='lg:ml-[70px] w-full overflow-hidden lg:w-[500px] max-h-[550px] shrink-0 hidden lg:flex items-center justify-center relative'>
                        <div className='h-[600px] lg:h-[950px] rotate-[30deg] bg-neutral-20 shrink-0'>
                            <img src={stack} alt="Desktop app sample" className='object-scale-down h-[600px] lg:h-[950px]' />
                        </div>

                    </div>

                </div>

            </div>

            <div className='large-container bg-gold-20'>

                <div className='internal-container mx-auto lg:px-[70px] flex lg:flex-row flex-col text-left'>
                    <div className='hidden w-[500px] lg:flex items-center mr-[30px] shrink-0'>
                        <img src={analyze} alt="Desktop app sample" className='w-[500px]' />
                    </div>
                    <div className='px-[30px]'>
                        <p className='neutral-400 accent-callout mb-[25px] mt-[30px] lg:mt-[75px]'>FINSTACK<sup>&reg;</sup> BUILT JUST FOR YOU</p>
                        <h1>Analyze your<br /> financial situation</h1>
                        <div className='lg:hidden w-[360px] flex items-center mx-auto shrink-0 mt-[5px]'>
                            <img src={analyze} alt="Desktop app sample" className='w-[360px]' />
                        </div>
                        <p className='paragraph-1 neutral-600 mt-[10px] lg:mt-[10px] mb-[30px]'>We need you to answer a few questions in order to find the optimal path for you. <strong>Don’t worry, we don’t judge.</strong> Based on your answers, we will analyze your current financial situation, and suggest a step-by-step plan to help you achieve your goals.</p>
                        <div className='mb-[78px]'>
                            <button className='gold-button' onClick={() => loginWithRedirect({
                                screen_hint: 'signup',
                                audience: `https://${domain}/api/v2/`,
                                scope: "profile", // new
                            })}>Start Personalized Analysis &#8594;</button>
                        </div>
                    </div>
                </div>

            </div>
            <div className='large-container bg-white'>

                <div className='internal-container mx-auto lg:px-[70px] flex lg:flex-row flex-col text-left'>

                    <div className='px-[30px]'>
                        <p className='neutral-400 accent-callout mt-[45px] lg:mt-[103px]'>PRIORITIES THAT REALLY MATTER</p>
                        <h1>Set your goals</h1>
                        <div className='lg:hidden w-[360px] flex items-center mx-auto shrink-0 mt-[5px]'>
                            <img src={goals} alt="Desktop app sample" className='w-[360px]' />
                        </div>
                        <p className='paragraph-1 neutral-600 mt-[25px] lg:mt-[25px] mb-[35px]'>We all have a vision of our ideal financial situation. Whether that’s owning a jet or simply reaching financial independence, we’re here to help set you on the right path. Kairos allows you to set your priorities, find the best available products to help you reach them, and track your progress.</p>
                        <div className='mb-[45px] lg:mb-[68px]'>
                            <button className='gold-button' onClick={() => loginWithRedirect({
                                screen_hint: 'signup',
                                audience: `https://${domain}/api/v2/`,
                                scope: "profile", // new
                            })}>Find Your Finstack &#8594;</button>
                        </div>
                    </div>
                    <div className='hidden w-[420px] lg:flex items-center mr-[80px] shrink-0'>
                        <img src={goals} alt="Desktop app sample" className='w-[420px]' />
                    </div>
                </div>

            </div>

{/*             <div className='large-container bg-gold-20'>

                <div className='internal-container mx-auto lg:px-[70px] flex lg:flex-row flex-col text-left'>
                    <div className='hidden w-[500px] lg:flex items-center mr-[50px] shrink-0'>
                        <img src={accounts} alt="Desktop app sample" className='w-[500px]' />
                    </div>
                    <div className='px-[30px]'>
                        <p className='neutral-400 accent-callout mb-[25px] mt-[30px] lg:mt-[75px]'>COMPLETE OVERVIEW OF YOUR FINANCES</p>
                        <h1>Connect all<br /> your accounts</h1>
                        <div className='lg:hidden w-[360px] flex items-center mx-auto shrink-0 mt-[5px]'>
                            <img src={accounts} alt="Desktop app sample" className='w-[360px]' />
                        </div>
                        <p className='paragraph-1 neutral-600 mt-[10px] lg:mt-[10px] mb-[30px]'>Kairos enables you to connect all your financial accounts in one dashboard. You can see your complete financial situation, identify what you are missing based on your goals, and how you’re spending your hard earned money.</p>
                        <div className='mb-[78px]'>
                            <button className='gold-button' onClick={() => loginWithRedirect({
                                screen_hint: 'signup',
                                audience: `https://${domain}/api/v2/`,
                                scope: "profile", // new
                            })}>Get Connected &#8594;</button>
                        </div>
                    </div>
                </div>

            </div> */}

{/*             <div className='large-container bg-white'>

                <div className='internal-container mx-auto lg:px-[70px] flex lg:flex-row flex-col text-left'>

                    <div className='pl-[30px] pr-[25px]'>
                        <p className='neutral-400 accent-callout mt-[45px] lg:mt-[103px] !tracking-[.22em]'>THE BEST TIME TO START WAS YESTERDAY</p>
                        <h1>Watch your net worth grow</h1>
                        <div className='lg:hidden w-[360px] flex items-center mx-auto shrink-0 mt-[5px]'>
                            <img src={graph} alt="Desktop app sample" className='w-[360px]' />
                        </div>
                        <p className='paragraph-1 neutral-600 mt-[25px] lg:mt-[25px] mb-[35px]'>The hardest thing about achieving financial freedom is consistency. Our platform enables you to track your progress to make sure you stay on the right path.</p>
                        <div className='mb-[45px] lg:mb-[68px]'>
                            <button className='gold-button' onClick={() => loginWithRedirect({
                                screen_hint: 'signup',
                                audience: `https://${domain}/api/v2/`,
                                scope: "profile", // new
                            })}>Get Started Now &#8594;</button>
                        </div>
                    </div>
                    <div className='hidden w-[530px] lg:flex items-center mr-[80px] shrink-0'>
                        <img src={graph} alt="Desktop app sample" className='w-[530px]' />
                    </div>
                </div>

            </div> */}

            <div className='large-container bg-black text-center'>
                <h1 className='basic-white mx-auto pt-[34px]'>But, that's not all...</h1>
                <p className='mt-[18px] mb-[41px] paragraph-1-to-mobile basic-white mx-auto'>With your Finstack you also get access to:</p>
                <div className='flex lg:flex-row flex-col gap-[45px] mx-auto justify-center pb-[120px] items-center'>
                    <div className='w-[373px] h-[280px] bg-white rounded-[14px] py-[52px] px-[39px]'>
                        <h3 className='!text-[20px] !font-[700]'>Educational Resources</h3>
                        <p className='mt-[33px] neutral-600 paragraph-1'>Financial literacy is a huge part of your financial well-being. We believe everyone should have access to financial education, so we curated a library of resources for you.</p>
                    </div>
{/*                     <div className='w-[373px] h-[280px] bg-white rounded-[14px] py-[52px] px-[39px]'>
                        <h3 className='!text-[20px] !font-[700]'>1-on-1 Consulting</h3>
                        <p className='mt-[33px] neutral-600 paragraph-1'>Kairos offers complimentary 1-on-1 consulting to all of our members, regardless of age, income, or financial status.</p>
                    </div> */}
                </div>

            </div>

            <div className='large-container bg-gold-20'>

                <div className='internal-container mx-auto lg:px-[70px] flex lg:flex-row flex-col text-right'>
                    <div className='hidden w-[330px] lg:flex items-center mr-[80px] shrink-0'>
                        <img src={products} alt="Desktop app sample" className='w-[330px]' />
                    </div>
                    <div className='px-[30px]'>
                        <p className='neutral-400 accent-callout mb-[25px] mt-[30px] lg:mt-[75px]'>ANOTHER FINTECH PRODUCT?</p>
                        <h1>Trusted financial resources.</h1>
                        <div className='lg:hidden w-[330px] flex items-center mx-auto shrink-0 mt-[5px]'>
                            <img src={products} alt="Desktop app sample" className='w-[330px]' />
                        </div>
                        <p className='paragraph-1 neutral-600 mt-[30px] lg:mt-[100px] mb-[30px]'>Each product we recommend goes through <strong>rigorous evaluation</strong> by our team of <strong>young professionals and experienced finance experts</strong>. Our independent due diligence process weeds out the bad guys and leaves us with a set of products that <strong>we trust with our own finances</strong> and feel good about recommending to you.</p>
                        <div className='mb-[78px]'>
                            <button className='border-grey-button' onClick={() => loginWithRedirect({
                                screen_hint: 'signup',
                                audience: `https://${domain}/api/v2/`,
                                scope: "profile", // new
                            })}>Find My Finstack &#8594;</button>
                        </div>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </>
    )
}

export default Home