import {useEffect, useState, createContext, useContext} from 'react'
import axios from "axios";
import {usePlaidLink} from "react-plaid-link";
import './button.css';
import { useNavigate } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local'){
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

axios.defaults.baseURL = apiBaseURL

function LoadLink(props) {
    const [linkToken, setLinkToken] = useState();
    const { isAuthenticated, user, isLoading, error, loginWithRedirect, getAccessTokenSilently } = useAuth0();
    console.log('Initialized button for ',props.initialProduct)
    useEffect(() => {
        async function fetch() {
            // Get auth0 token
            const token = await getAccessTokenSilently();
            // console.log("Primer: ", token)
            const response = await axios.post("/connections/create_link_token", {plaid_product: [props.initialProduct]}, { headers: {"Authorization" : `Bearer ${token}`} });
            // console.log("Link Token: ", response.data.link_token)
            setLinkToken(response.data.link_token);
            return () => {}
        }
        fetch();
    }, []);

    const { open, ready } = usePlaidLink({
        token: linkToken,

        onSuccess: (public_token, metadata) => {
            async function fetchData() {
                const token = await getAccessTokenSilently();
                let accessToken = await axios.post("/connections/set_access_token", 
                {public_token: public_token, email: user.email}, 
                { headers: {"Authorization" : `Bearer ${token}`} 
                });
                console.log("Response_last, accessToken.data: ", accessToken.data);
            }
            fetchData();
            console.log("success,public token,metadata", public_token, metadata);
            // send public_token to server
        },
    });

    // Check if authenticated
    if (isAuthenticated) {
        return(<button className='shrink-gold-button mx-auto' onClick={() => open()} disabled={!ready}>
        {ready ? "Link with Plaid" : "Initializing"}
    </button>)
    }
    else{
        return <button className="shrink-gold-button mx-auto" onClick={loginWithRedirect}>Login</button>;
    }

}

export default LoadLink