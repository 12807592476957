//const placeholder600 = require('../../assets/rectangle2604093-f1uo-600h.png')
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from 'react'

const ComingBack = () => {
  const navigate = useNavigate();
  const { loginWithRedirect, logout } = useAuth0();

  return (
    <>
      <p></p>
      <div className='large-container bg-white'>
        <div className="text-center py-4 flex flex-col items-center">

          <h1 className="big-header mt-[97px]">Hey again!</h1>
          <h2 className="h2-thinner mt-[15px]">We’re so stoked to have you back.</h2>
          <p className="paragraph-4 neutral-600 max-w-[1063px] mt-[15px]">Since we kept your onboarding flow answers on file, you have a couple of options here.
          <br></br>
          Feel free to skip right to your subscription if your goals and financial situation are the same as they were the last time you filled it out.
          <br></br>
          If anything has changed, we recommend going through the flow again to update your answers.
          <br></br>
          Having up-to-date information helps us do what we do best — guide you to the right financial solutions for your life!</p>
          <button className="callout-brand-button mt-[57px]" onClick={() => navigate("/onboarding/personal-info")}>Fill Out the Onboarding Flow</button>
          <button className="text-button mt-[2px]" onClick={() => navigate("/payment")}>Skip Right to Subscription</button>

        </div>
      </div>

    </>
  )
}

export default ComingBack