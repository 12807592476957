
import './SavingsGoals.css';
import React from 'react';
import InternalFooter from './Footer';
import { useLocation, useNavigate } from 'react-router-dom';
const logo = require('../../assets/logo-color.png');
const success = require('../../assets/icons/success.svg').default;
const SavingsGoals = () => {
  function submit() {
    navigate("/investing")
  };
  function newGoal() {
    navigate("/savings-goals")
  };
  const navigate = useNavigate();
  const location = useLocation();
  
  return (
    <>
      <div className='w-screen m-0'>
        <div className='flex flex-row min-h-[85vh]'>
          <nav className="hidden lg:flex flex-col flex-nowrap bg-white basis-[312px] shrink-0 grow-0">
            <img src={logo} className="w-[264.15px] h-[65px] mt-[41px] self-center" alt="KairosLogo" />
            <div className="onboarding-bar w-[30px] h-[266px] rounded-full mt-[75px] ml-[59px]">
              <div className="bar-progress w-[30px] h-[149px] rounded-full"><div className="bar-dot w-[10px] h-[10px] rounded-full top-[10px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Tell Us About Yourself</p></div></div>
              <div className="bar-dot w-[10px] h-[10px] rounded-full top-[123px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Set Your Goals</p></div>
              <div className="bar-dot w-[10px] h-[10px] rounded-full top-[240px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Connect Your Accounts</p></div>
            </div>
          </nav>
          <div className='bg-neutral-25 pb-[70px] w-[100%]'>
            <div className='mt-[50px] lg:mt-[106px] mb-[50px] lg:mb-[100px] mx-[30px] lg:mx-[109px]'>
              <img src={success} alt='success' className='w-[170px] h-[170px] mx-auto' />
              <h1 className='text-center'>Got it!</h1>
              <p className='paragraph-1 text-center'>We’ve added that to your profile! We can’t wait to help you achieve your savings goals.</p>
              <button className='gold-button block mx-auto mt-[55px]' type='button' onClick={()=>submit()}>Continue with Onboarding</button>
              <button className='text-button block mx-auto mt-[15px]' type='submit' onClick={()=>newGoal()}>Add Another Savings Goal</button>
          
            </div>



          </div>
        </div>

        <InternalFooter></InternalFooter>
      </div>



    </>
  )
}

export default SavingsGoals