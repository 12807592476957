import React, { useEffect, useState, useRef, createContext, useContext } from 'react';
import InternalFooter from './Footer';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import postLoadSaveData from '../../utils/postLoadSaveData';
import { useAuth0 } from '@auth0/auth0-react';
import postLoadModifyData from '../../utils/postLoadModifyData';
import postLoadFetchData from '../../utils/postLoadFetchData';
import postLoadPostData from '../../utils/postLoadPostData';
import Modal from 'react-modal';
const close = require('../../assets/common/close-white.svg').default;
const logo = require('../../assets/logo-color.png');
const UserContext = createContext()
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    background: 'white',
    'border-radius': '10px',
    padding: '0px'
  },
  overlay: { zIndex: 1000 }
};
const LoadBDebt = () => {
  const email = useContext(UserContext);
  return (
    <>
      <div className='w-screen m-0'>
        <Fields email={email}></Fields>
        <InternalFooter></InternalFooter>
      </div>



    </>
  )
}

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local') {
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

function Fields(props) {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const email = props.email;
  const [answers, setAnswers] = useState(null);
  const [tracker, setTracker] = useState(false);
  const baseURL = apiBaseURL;
  const urlprofile = `${baseURL}financial_discovery/financial_profile/?email=${email}&mode=profile`
  const url = `${baseURL}client_extras/answers/`;
  const urltrack = `${baseURL}financial_discovery/financial_profile/?email=${email}&mode=track_onboarding`;
  const urlsubmit = `${baseURL}financial_discovery/financial_card/`;

  const ref = useRef(null);

  const autoScroll = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
    ref.current?.focus();
  };

  const navigate = useNavigate();
  const location = useLocation();
  const payload = location.state;
  const [cardSelected, setCardSelected] = useState(null);
  const [selection, setSelection] = useState("");
  const [cardActive, setCardActive] = useState(null);
  const [profileData, setProfileData] = useState(null);


  // To be used to add the Back buttons 
  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        console.log("Got new token for function: " + token)
        // Execute the api method by calling the function
        const response = await postLoadPostData(url, { "question": "Do you have a retirement account?" }, token);
        const track = await postLoadFetchData(urltrack, token);
        const consolidate = await postLoadFetchData(urlprofile, token);
        setProfileData(consolidate.status ? null : consolidate);
        setTracker(track.financial_profile?.financial_summary);
        setAnswers(response);
        console.log('data: ', answers)
        console.log('user', user)
        if (consolidate?.financial_profile["Do you have a retirement account?"].length > 0) {
          setSelection(consolidate?.financial_profile["Do you have a retirement account?"][0])
          setCardSelected(response.findIndex(element => element.answer === consolidate.financial_profile["Do you have a retirement account?"][0]))
          console.log('selection', consolidate?.financial_profile["Do you have a retirement account?"][0])
          console.log('card active', response.findIndex(element => element.answer === consolidate.financial_profile["Do you have a retirement account?"][0]))
        }

      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  // Post data on button click
  const postOnClick = async () => {
    let payload = {
      "email": user.email,
      "question_id": answers[0].question_id,
      "answers": [{ "answer": selection }]
    }
    console.log(payload);
    try {
      const token = await getAccessTokenSilently();
      // Execute the api method by calling the function
      const response = await postLoadSaveData(urlsubmit, payload, token);
      console.log(response);
    } catch (err) {
      console.log(err);
    } finally {
      navigate("/onboarding/brokerage")

    }

  }
  const putOnClickProfile = async () => {
    let payload = {
      "email": user.email,
      "question_id": answers[0].question_id,
      "answers": [{ "answer": selection }]
    }
    try {
      const token = await getAccessTokenSilently();
      console.log("Got new token for function: " + token)
      // Execute the api method by calling the function
      const response = await postLoadModifyData(urlsubmit, payload, token);
      console.log(response);
    } catch (err) {
      console.log(err);
    } finally {
      navigate("/onboarding/brokerage")
    }
  }

  // Post data on button click END

  //Modal Functions START
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  // Modal Functions END

  return (
    (user && answers) && <>
      <form onSubmit={profileData?.financial_profile["Do you have a retirement account?"].length > 0 ? putOnClickProfile : postOnClick}>
        <div className='flex flex-row min-h-[85vh]'>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Sign in to bookmark"
          >
            <div className='bg-black py-[35px] px-[50px] w-[400px] lg:w-[569px] h-[310px] relative'>
              <p className='basic-white text-center paragraph-1-to-mobile'>By joining us you’ve already begun investing in yourself, but it’s important for us to know if you’re investing financially as well. Knowing whether you are or not will help us recommend the right products to you.</p>
              <img src={close} onClick={closeModal} alt="Close" className='cursor-pointer w-[31px] h-[31px] absolute bottom-[64px] right-1/2 translate-x-2/4'></img>
            </div>

          </Modal>
          <nav className="hidden lg:flex flex-col flex-nowrap bg-white basis-[312px] shrink-0 grow-0">
            <img src={logo} className="w-[264.15px] h-[65px] mt-[41px] self-center" alt="KairosLogo" />
            <div className="onboarding-bar w-[30px] h-[266px] rounded-full mt-[75px] ml-[59px]">
              <div className="bar-progress w-[30px] h-[149px] rounded-full"><div className="bar-dot w-[10px] h-[10px] rounded-full top-[10px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Tell Us About Yourself</p></div></div>
              <div className="bar-dot w-[10px] h-[10px] rounded-full top-[123px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Set Your Goals</p></div>
              <div className="bar-dot w-[10px] h-[10px] rounded-full top-[240px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Connect Your Accounts</p></div>
            </div>
          </nav>
          <div className='bg-neutral-25 pb-[70px] w-[100%]'>
            <div className='mt-[50px] lg:mt-[106px] mb-[50px] lg:mb-[100px] mx-[30px] lg:mx-[109px]'>
              <h1 className='text-center'>Do you have a retirement account?</h1>
              <p className='paragraph-1 text-center underline text-center'>
                <Link to="/articles/unlocking-the-power-of-retirement-accounts" target="_blank">What counts as a retirement account?</Link>
              </p>
              <br/>
              <div className='paragraph-1 text-center underline text-center'>
                <p className='cursor-pointer inline' onClick={openModal}>Why do we ask this?</p>
              </div>
            </div>
            <div className='grid grid-cols-2 lg:gap-8 mx-auto lg:w-[798px] w-full'>
              {answers.map((card, index) => (
                <div key={index} onMouseEnter={() => { setCardActive(index); console.log(cardActive) }} onMouseLeave={() => setCardActive(null)}>
                  <input id={index} type="radio" value={card.answer} name='selection' className='hidden' onChange={(e) => { setSelection(e.target.value); console.log(e.target.value) }} />
                  <label htmlFor={index}>
                    <div className='onboarding-card mx-auto' onClick={() => setCardSelected(index)} style={{ backgroundColor: cardSelected === index ? "rgba(247, 189, 132, 0.8)" : "" }}>
                      <img src={((cardSelected === index) || (cardActive === index)) ? apiBaseURL.slice(0, -1) + card.image_hover : apiBaseURL.slice(0, -1) + card.image} className="w-[60px] h-[60px] lg:w-[80px] lg:h-[80px] mx-auto" alt={card.answer} />
                      <p className='card-label mx-auto'>{card.answer}</p>
                    </div>
                  </label>
                </div>

              ))}
            </div>




          </div>
        </div>

        <div className='flex flex-col-reverse lg:flex-row justify-between align-middle py-[37px] px-[119px] top-shadow'>
          <button className='text-only-button mt-[15px] lg:mt-0' type='button' onClick={() => navigate(-1)}>Go Back</button>
          <button ref={ref} className='gold-button' disabled={selection === ""} type='button' onClick={() => profileData?.financial_profile["Do you have a retirement account?"].length > 0 ? putOnClickProfile() : postOnClick()}>Next</button>
        </div>


      </form>
    </>
  )

}

const BDebt = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  isAuthenticated && console.log(user);

  // Redirect to home if not authenticated
  !isLoading && !isAuthenticated && loginWithRedirect()

  return (
    // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
    isAuthenticated && <UserContext.Provider value={user.email}><LoadBDebt email={user.email} /></UserContext.Provider>
  )
}

export default BDebt