
import React, { useState, useEffect, createContext, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router';
import Footer from '../Onboarding/Footer';
import { useAuth0 } from '@auth0/auth0-react';
import LoadLink from '../../components/plaid/LoadLink';
import postLoadFetchData from '../../utils/postLoadFetchData';
const logo = require('../../assets/logo-color.png');

const UserContext = createContext()

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local'){
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

const LoadConnect = () => {
    const email = useContext(UserContext);
    const location = useLocation();
    const navigate = useNavigate();
    const { isAuthenticated, user, isLoading, error, loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const [clicked, setClicked] = useState(false);
    const baseURL = apiBaseURL;
    const url = `${baseURL}financial_discovery/financial_profile/?email=${email}&mode=track_onboarding`;
    const [profileData, setProfileData] = useState(null);
    // Example: post data on load
    useEffect(() => {
        // Start the process by getting the token
        (async () => {
            try {
                const email = await user.email;
                const token = await getAccessTokenSilently();
                const response = await postLoadFetchData(url, token);
                setProfileData(response.status ? null : response);
                console.log('data: ', response)
                // console.log("Got new token: " + token)
                // Execute the api method by calling the function
                // Uncomment the line below to post data on load
                // const response = await postLoadSaveData(url, payload, token);
            } catch (err) {
                console.log(err);
            }
        })();
        // End process
    }, [])

    return (
        <>
            <div className='w-screen m-0'>
                <div className='flex flex-row min-h-[85vh]'>
                    <nav className="hidden lg:flex flex-col flex-nowrap bg-white basis-[312px] shrink-0 grow-0">
                        <img src={logo} className="w-[264.15px] h-[65px] mt-[41px] self-center" alt="KairosLogo" />

                    </nav>
                    <div className='bg-neutral-25 pb-[70px] w-[100%] min-h-[500px]'>
                        <div className='mt-[50px] lg:mt-[106px] mb-[50px] lg:mb-[100px] mx-[30px] lg:mx-[109px]'>
                            <h1 className='text-center'>Connect your accounts</h1>
                            <p className='paragraph-1 text-center'>Connecting your accounts will help us get an understanding of what kinds of financial tools you already have as well as a rough idea of your current net worth, which will help us evaluate your priorities.</p>
                            <br></br>
                            <p className='paragraph-1 text-center'>We utilize Plaid, like many banks and financial institutions, for secure account connection and will never share your information, use it without your permission, or use it for anything other than building your unique financial stack.</p>
                        </div>
                        <div className='max-w-full rounded-[10px] w-[400px] md:w-[800px] h-[193px] p-[40px] block mx-auto shadow-[0px_6px_24px_rgba(0,0,0,0.08)] relative'>
                            <div className='w-[450px] inline-block'>
                                <h2 className='h2-connect'>Investment Accounts</h2>
                                <p className='paragraph-4-to-mobile neutral-500 max-w-[210px] md:max-w-full'>Connect your investment accounts here — 401k, IRA / Roth IRA, Brokerage account (Robinhood, Public, Fidelity, etc.)</p>
                            </div>
                            <div className='absolute inline min-[430px]:top-[50px] md:right-[30px] right-[20px] max-[429px]:bottom-[10px]' onClick={() => setClicked(true)}>
                                <LoadLink initialProduct="investments"></LoadLink>
                            </div>


                        </div>
                        <div className='max-w-full rounded-[10px] w-[400px] md:w-[800px] h-[193px] p-[40px] block mx-auto shadow-[0px_6px_24px_rgba(0,0,0,0.08)] relative mt-[50px]'>
                            <div className='w-[450px] inline-block'>
                                <h2 className='h2-connect'>Credit/Debt Accounts</h2>
                                <p className='paragraph-4-to-mobile neutral-500 max-w-[210px] md:max-w-full'>Connect your liability accounts here — credit cards, personal loans, student loans, car loans, etc.</p>
                            </div>
                            <div className='absolute inline min-[430px]:top-[50px] md:right-[38px] right-[20px] max-[429px]:bottom-[10px]' onClick={() => setClicked(true)}>
                                <LoadLink initialProduct="liabilities"></LoadLink>
                            </div>


                        </div>
                        <div className='max-w-full rounded-[10px] w-[400px] md:w-[800px] h-[193px] p-[40px] block mx-auto shadow-[0px_6px_24px_rgba(0,0,0,0.08)] relative mt-[50px]'>
                            <div className='w-[450px] inline-block'>
                                <h2 className='h2-connect'>Bank Accounts</h2>
                                <p className='paragraph-4-to-mobile neutral-500 max-w-[210px] md:max-w-full'>Connect your bank account here — Bank of America, SoFi, Wells Fargo, Chime, Chase, etc.</p>
                            </div>
                            <div className='absolute inline min-[430px]:top-[50px] md:right-[38px] right-[20px] max-[429px]:bottom-[10px]' onClick={() => setClicked(true)}>
                                <LoadLink initialProduct="transactions"></LoadLink>
                            </div>


                        </div>





                    </div>
                </div>

                <div className='flex flex-col-reverse lg:flex-row justify-between align-middle py-[37px] lg:px-[119px] px-[28px] top-shadow'>
                    <button className='text-only-button mt-[15px] lg:mt-0 hidden lg:inline' type='button' onClick={() => navigate(-1)}>Go Back</button>
                    <div className='flex flex-col-reverse lg:flex-row lg:gap-0 gap-[15px]'>
                        <button className='grow-gold-button mx-auto' disabled={!clicked} type='button' onClick={() => navigate("/accounts")}>Done</button>
                    </div>

                </div>


                <Footer></Footer>
            </div>

        </>
    )
}

const Connect = () => {
    const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    isAuthenticated && console.log(user);

    // Redirect to home if not authenticated
    !isLoading && !isAuthenticated && loginWithRedirect()

    return (
        // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
        isAuthenticated && <UserContext.Provider value={user.email}><LoadConnect email={user.email} /></UserContext.Provider>
    )
}

export default Connect