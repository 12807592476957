import React, { createContext, useContext, useState, useEffect } from 'react';
import Sidebar from "./components/Sidebar"
import Footer from "../../components/Footer"
import { useAuth0 } from "@auth0/auth0-react";
import postLoadSaveData from "../../utils/postLoadSaveData";
import postLoadDeleteData from "../../utils/postLoadDeleteData";
import postLoadFetchData from '../../utils/postLoadFetchData';
import { useParams, useNavigate } from 'react-router-dom';

const bookmark_on = require('../../assets/icons/bookmark-on.svg').default;
const bookmark_off = require('../../assets/icons/bookmark-off-gold.svg').default;
const UserContext = createContext()

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local'){
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

function LoadArticle() {
    const params = useParams();
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const email = useContext(UserContext);
    const [article, setArticle] = useState(null);
    const baseURL = apiBaseURL;
    const url = `${baseURL}client_extras/articles/?mode=by_slug&slug=${params.slug}&email=${email}`;
    const bookmarkUrl = `${baseURL}client_extras/bookmarks/`;
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently();
                // console.log("Got new token for function: " + token)
                // Execute the api method by calling the function
                const currentArticle = await postLoadFetchData(url, token);
                setArticle(currentArticle);
                console.log(currentArticle)
            } catch (err) {
                console.log(err);
            }
        })();
    }, []);

    // Post data on button click

    const bookmark = async (id,status) => {
        var article_temp = {...article};
        article_temp.bookmarked = !article_temp.bookmarked;
        const payload = {
            "email": user.email,
            "article_id": id
        };
        console.log('temp',article_temp);
        setArticle(article_temp);
        if (status) {
            try {
                const token = await getAccessTokenSilently();
                // console.log("Got new token for function: " + token)
                // Execute the api method by calling the function
                const response = await postLoadDeleteData(bookmarkUrl, payload, token);
                console.log(response);
    
            } catch (err) {
                console.log(err);
            }
        } else {
            try {
                const token = await getAccessTokenSilently();
                // console.log("Got new token for function: " + token)
                // Execute the api method by calling the function
                const response = await postLoadSaveData(bookmarkUrl, payload, token);
                console.log(response);
    
            } catch (err) {
                console.log(err);
            }
        }
        

    }

    if (!article) return <div>Loading Resources...</div>; // Todo: Add a loading component

    return (
        (user && article && isAuthenticated) && <>
        <div>
            <Sidebar user={user}></Sidebar>
            <div className="app-container relative">
                <button className="text-only-button ml-[25px]" onClick={() => navigate("/resources")}><span className="pl-[20px]"></span>Back To Resources</button>
                <div onClick={()=>bookmark(article.id,article.bookmarked)} className="w-[75px] h-[75px] absolute top-[42px] right-[60px] flex justify-center items-center bg-white rounded-[5px] drop-shadow-[0px_3px_12px_rgba(0,0,0,0.08)] cursor-pointer">
                    {article.bookmarked?<img src={bookmark_on} className="w-[38px] h-[48px] inline-block"></img>:<img src={bookmark_off} className="w-[38px] h-[48px] inline-block"></img>}
                    </div>
                <div className="text-center mt-[45px] mb-[31px]">
                    <h2 className="">{article.title}</h2>
                    <h3 className="brand-gold mt-[14px]">{new Date(article.created_at).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}</h3>
                </div>
                <img className="w-[800px] mx-auto bg-neutral-25 rounded-[10px]" src={baseURL.slice(0, -1) + article.image} alt='image'></img>
                <div className="text-left w-[800px] mx-auto mt-[47px] mb-[61px] whitespace-pre-wrap paragraph-1">{article.content}</div>
            </div>
            
        </div>
        <Footer></Footer>
    </>

    )
}

const Article = () => {
    const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    isAuthenticated && console.log(user);
    if (isLoading) return <div>Loading Article...</div>; // Todo: Add a loading component

    // Redirect to home if not authenticated
    !isAuthenticated && loginWithRedirect()

    return (
        // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
        isAuthenticated && <UserContext.Provider value={user.email}><LoadArticle email={user.email} /></UserContext.Provider>
    )

}

export default Article