import React from 'react';
import { useNavigate } from 'react-router';
import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useState, createContext } from "react"
import postLoadFetchData from '../utils/postLoadFetchData';
const spinner = require('../assets/icons/spinner.png');

const UserContext = createContext()

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local'){
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

function LoadFixMissing() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const email = useContext(UserContext);
  const [recommendations, setRecommendations] = useState(null);
  const [allConnectedAccounts, setAllConnectedAccounts] = useState(null);
  const baseURL = apiBaseURL;
  const url = `${baseURL}connections/actions?email=${email}`;
  const urlaccounts = `${baseURL}connections/accounts?email=${email}`;
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        // console.log("Got new token for function: " + token)
        // Execute the api method by calling the function
        const accounts = await postLoadFetchData(url, token);
        const allAccounts = await postLoadFetchData(urlaccounts, token);
        setAllConnectedAccounts(allAccounts);
        setRecommendations(accounts);
        console.log('recommendations: ', accounts)
        console.log('user', user)

      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  if (!recommendations || !allConnectedAccounts) return <div><img src={spinner} className="animate-spin mx-auto" alt="Loading"></img></div>; // Todo: Add a loading component

  if (!!allConnectedAccounts.message){
    return( <>

    </>)
  }

  return (
    // loop through props.data.accounts and display each item using map
    (allConnectedAccounts && recommendations) && <div className='md:w-[465px] w-full lg:ml-[30px] inline-block shadow-[0px_3px_12px_rgba(0,0,0,0.08)] bg-fade-gold rounded-[20px] p-[30px] md:self-start'>
        <p className='accent-5'>Let's Fix Whats Missing</p>
        <p className='paragraph-1'>Based on your goals, these are the types of accounts you're missing. Connect them now or head to your <span className='underline basic-white font-semibold cursor-pointer' onClick={() => navigate("/finstack")}>Finstack</span> page for recommendations</p>
        
        <ul>
            {
                recommendations.recommended_actions.map((action, id) => {
                    return (
                        <li key={id}>
                            <h1 className='h1-mobile text-center basic-white'>{action /*account type*/}</h1>
                        </li>
                    )
                })
              }   
        </ul>
    </div>
    
  );

}

const FixMissing = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  isAuthenticated && console.log(user);
  if (isLoading) return <div>Loading...</div>; // Todo: Add a loading component

  // Redirect to home if not authenticated
  !isAuthenticated && loginWithRedirect()

  return (
    // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
    isAuthenticated && <UserContext.Provider value={user.email}><LoadFixMissing email={user.email} /></UserContext.Provider>
  )
}

export default FixMissing;