import { useNavigate } from "react-router-dom";

/* To use this component:
- Intended to be used when card needs a title at the left, and links/content below it                       
- place children inside [<ActionCard>CHILDREN</ActionCard>]
- must add a [width="w-[WIDTH]"]
- Use [title='TITLE'] to add in a title on your card
- Neutral link shows leftmost and Brand link shows at the right if both are present
  > to hide each one respectively, use [displayBrand='hidden'] or [displayNeutral='hidden']
  > to add navigate links use [neutralLink] and [brandLink]
  > to specify text for the links use [neutralText] and [brandText]
- To modify things other than padding of the container, shadow, width and the border-radius, add classes using [addClass='CLASSES']
 */
const ActionCard = ({ children, ...props }) => {
    const navigate = useNavigate();
    return (
        <>
            <div className={`py-[30px] pl-[30px] pr-[25px] shadow-[0px_3px_12px_rgba(0,0,0,0.08)] rounded-[20px] ${props.width} ${props.addClass||''}`}>
                <p className="accent-5">{props.title}</p>
                <button className={`text-right-button ml-[10px] ${props.displayNeutral||''}`} onClick={() => navigate("/"+props.neutralLink||'')}>{props.neutralText||''}</button>
                <button className={`text-right-button ml-[10px] ${props.displayCustomNeutral||''}`} onClick={props.onClickEventNeutral}>{props.customNeutralText||''}</button>
                <button className={`text-brand-right-button ml-[16px] ${props.displayBrand||''}`} onClick={() => navigate("/"+props.brandLink||'')}>{props.brandText||''}</button>
                <div className={`${props.internalMargin||'mt-[30px]'}`}>
                    {children}
                </div>
            </div>
        </>
    )
}

export default ActionCard