import {
  PaymentElement,
  LinkAuthenticationElement
} from '@stripe/react-stripe-js'
import { useState } from 'react'
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
const check = require('../../assets/success.svg').default;


export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/dashboard`,
        payment_method_data: {
          billing_details: {
            name: props.name,
            email: props.email,
            phone: props.phone,
            address: {
              country: "US",
              postal_code: props.zip,
              city: props.city,
              line1: props.line1,
              state: props.line1,
              line2: ''
            }
          }
        }       
      },
      redirect: 'if_required'
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if(error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
        setConfirmation(false);
      } else {
        setMessage("An unexpected error occured.");
        setConfirmation(false);
      }
  
    } else {
      setConfirmation(true);
      setTimeout(() => {
        navigate("/dashboard", { state: true })
      }, "5000");
    }
    
    setIsLoading(false);
  }

  return (<>
    <form id="payment-form" onSubmit={handleSubmit} className={`${confirmation?'hidden':'block'}`}>
      <LinkAuthenticationElement id="link-authentication-element"
      // Access the email value like so:
      // onChange={(event) => {
      //  setEmail(event.value.email);
      // }}
      //
      // Prefill the email field like so:
      options={{defaultValues: {email: props.email}}}
      />
      <PaymentElement id="payment-element" options={{fields: {billingDetails:{name:'never',email:'never',phone:'never',address:'never'}}}} />
      {/* Show any error or success messages */}
      {message && <div id="payment-message" className='py-[10px] px-[5px] status-error-500'>{message}</div>}
      <div className='flex items-center'>
        <button disabled={isLoading || !stripe || !elements} id="submit" className='gold-button mx-auto mt-[20px]'>
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
          </span>
        </button>
      </div>

      
    </form>
    <div className={`${confirmation?'block':'hidden'} text-center`}>
      <img src={check} alt="Checkmark" className='w-[80px] animate-bounce mx-auto my-[30px]'/>
      <h3 className='accent-1 brand-gold'>Your payment was successful!</h3>
      <p className='paragraph-1 mx-auto neutral-500 mt-[20px] mb-[30px]'>See you on the other side:)</p>
    </div>
    </>
  )
}