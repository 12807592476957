import { useNavigate } from "react-router-dom";
import person from "./../../../assets/icons/person-gold.svg";
import postLoadFetchData from "../../../utils/postLoadFetchData";
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";
const arrow = require('../../../assets/icons/profile-arrow.svg').default;

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local'){
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

const UserCard = (props) => {
    const navigate = useNavigate();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [kairosUser, setKairosUser] = useState(null);
    const baseURL = apiBaseURL;
    const urluser = `${baseURL}user_profiles/user_and_address/?email=${props.user.email}`;

    useEffect(() => {
        (async () => {
          try {
            const token = await getAccessTokenSilently();
            // console.log("Got new token for function: " + token)
            // Execute the api method by calling the function
            const dbKairosUser = await postLoadFetchData(urluser, token);
            setKairosUser(dbKairosUser);
          } catch (err) {
            console.log(err);
          }
        })();
    }, []);

    return (
        (props.user && isAuthenticated && kairosUser) && <>
            <div className='p-[15px] shadow-[0px_3px_12px_rgba(0,0,0,0.08)] rounded-[15px] h-[72px] max-w-[350px] absolute top-[42px] right-[30px] overflow-hidden cursor-pointer hidden lg:flex' onClick={() => navigate("/profile")}>
                <img src={!!props.user.picture?props.user.picture:person} alt='Profile Picture' className='w-[42px] h-[42px] rounded-full'></img>
                <div className="inline-block ml-[15px] max-w-[224px] whitespace-nowrap overflow-hidden py-[9px]">
                <p className="paragraph-1">{props.user.name === props.user.email ? kairosUser.user.name + " " + kairosUser.user.lastname : props.user.name}</p>
                </div>
                <img src={arrow} className='inline my-auto ml-[25.55px] mr-[7.29px]' alt="go"></img>
            </div>
        </>
    )
}

export default UserCard