import "./footer.css"
import twitter from '../../assets/whitebackgroundi417-s4u.svg'
import instagram from '../../assets/instagram.svg'
import linkedin from '../../assets/linkedin.svg'
import { useNavigate } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
const logowhite = require('../../assets/kairoslogoassetsfinalkairoslogowhite1i417-sdw9-200h.png')
const logowhitemobile = require('../../assets/logomark-white.png')
const running_env = process.env.REACT_APP_RUNNING_ENV;

const domain = running_env === 'development' || running_env === 'local' ? process.env.REACT_APP_AUTH0_DOMAIN_DEV : process.env.REACT_APP_AUTH0_DOMAIN_PROD;



const Footer = () => {
  const navigate = useNavigate();
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
  return (
    <div className="large-container bg-black pt-[30px] lg:pt-0">
      <div className="accent-container lg:px-[96px] px-[58px]">
        <div className="internal-container">
          <section className="lg:my-[36px] mb-[30px]">
            <img src={logowhite} className="lg:inline hidden"></img>
            <img src={logowhitemobile} className="lg:hidden inline h-[55px] pl-[10px]"></img>
          </section>
          <hr></hr>
          <section className="lg:mt-[66px] mt-[30px] lg:flex lg:space-x-[70px]">
            <div>
              <p className="accent-1 brand-light-grey lg:mr-[17px] lg:mb-[89px] mb-[30px] whitespace-nowrap lg:whitespace-normal">Wealth Management<br className="lg:block hidden"/><span className="inline lg:hidden"> </span>for The Rest Of Us</p>
              <div className="lg:flex gap-[22.22px] hidden">
                <a href="https://twitter.com/KairosFinancial"><img src={twitter} alt="twitter"></img></a>
                <a href="https://www.instagram.com/kairos.financial/"><img src={instagram} alt="twitter"></img></a>
                <a href="https://www.linkedin.com/company/kairos-financial/"><img src={linkedin} alt="twitter"></img></a>
              </div>
            </div>
            <div className="flex lg:flex-col flex-row justify-between">
              <div className="flex flex-col whitespace-nowrap lg:space-y-[10px] mb-[31px] lg:mb-0">
                <p className="hidden lg:inline accent-2 brand-light-grey mb-[21px]">PAGES</p>
                <button onClick={() => navigate("/")} className="text-left accent-3 brand-light-grey">Home</button>
                <button onClick={() => navigate("/features")} className="text-left accent-3 brand-light-grey">About</button>
                <button onClick={() => navigate("/company")} className="text-left accent-3 brand-light-grey">Company</button>
                <button onClick={() => navigate("/faq")} className="text-left accent-3 brand-light-grey">FAQ</button>
                <button onClick={() => navigate("/articles")} className="text-left accent-3 brand-light-grey">Articles</button>
              </div>
              <div className="flex lg:hidden brand-light-grey flex-col justify-between">
                <div className="flex brand-light-grey flex-col space-y-1">
                  <button className="accent-1-mobile brand-light-grey text-right" onClick={() => loginWithRedirect()}>Log In</button>
                  <button className="accent-1-mobile brand-light-grey text-right" onClick={() => loginWithRedirect({
                    screen_hint: 'signup',
                    audience: `https://${domain}/api/v2/`,
                    scope: "profile", // new
                  })}>Sign Up</button>
                </div>
                <div className="flex gap-[22.22px] mb-[58px]">
                  <a href="https://twitter.com/KairosFinancial"><img src={twitter} alt="twitter"></img></a>
                  <a href="https://www.instagram.com/kairos.financial/"><img src={instagram} alt="twitter"></img></a>
                  <a href="https://www.linkedin.com/company/kairos-financial/"><img src={linkedin} alt="twitter"></img></a>
                </div>
              </div>
            </div>

            <div className="lg:flex hidden flex-col whitespace-nowrap space-y-[10px]">
              <p className="accent-2 brand-light-grey mb-[21px]">COMPANY</p>
              <a href="#" className="text-left accent-3 brand-light-grey">Terms & Conditions</a>
              <a href="#" className="text-left accent-3 brand-light-grey">Privacy Policy</a>
            </div>
            <div className="lg:flex flex-col hidden whitespace-nowrap space-y-[10px]">
              <p className="accent-2 brand-light-grey mb-[21px]">ACCOUNT</p>
              <button className="accent-3 brand-light-grey inline text-left" onClick={() => loginWithRedirect()}>Log In</button>
              <button className="accent-3 brand-light-grey inline text-left" onClick={() => loginWithRedirect({
                screen_hint: 'signup',
                audience: `https://${domain}/api/v2/`,
                scope: "profile", // new
              })}>Sign Up</button>
            </div>
            <div className="lg:flex hidden flex-col whitespace-nowrap space-y-[10px]">
              <p className="accent-2 brand-light-grey mb-[21px]">HELP</p>
              <button onClick={() => window.Beacon('open')} className="accent-3 brand-light-grey">Contact Us</button>
            </div>
          </section>
          <section className="lg:hidden flex footer-bottom-mobile brand-light-grey justify-center">
            <p className="whitespace-pre">
              <a href="https://www.termsfeed.com/live/6d20504f-675c-4b7d-9de1-19087a6660af" className="text-left brand-light-grey">Terms & Conditions</a>   |   <a href="https://www.termsfeed.com/live/35ace1c2-2014-488e-ba08-3c3a70927de3" className="text-left brand-light-grey">Privacy Policy</a>
            </p>


          </section>
          <p className="copyright lg:pb-0 pb-[15px] text-center lg:text-left">(c) 2023 Bay Front Ventures Inc.</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;