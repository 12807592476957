import { useState } from 'react';
import Footer from '../../components/Footer';
import { useAuth0 } from '@auth0/auth0-react';

const questions = [{
  title: 'How does Kairos help me?',
  content: `Imagine Cupid with a finance degree (suit & briefcase not necessary, but encouraged) - except we actually take it a step further!

Kairos first plays matchmaker by asking questions, analyzing the accounts you connect, and matching you with financial products to help you achieve your goals! Afterward, (we take it a step further, remember?) we track your goal progress and update our recommendations as needed - like a permanent, financial wingman! And although love is blind, finance is not, so we create a dashboard for you to see your finances in one place, as well as multiple resources for your knowledge to grow alongside your wallet! Move over, Cupid!`
},
{
  title: 'Who is Kairos for?',
  content: `You! If you’re asking yourself if Kairos is right for you, there’s a good chance it is. Kairos has anyone who wants to step their money game up in mind, so try it out! You get three months on us to see if it works for you, so what’s there to lose?`
},
{
  title: 'How is Kairos different from other financial services?',
  content: `With so many financial services to choose from already, we saw more value in organizing those services for users rather than creating a new one. So, that’s exactly what we did! While we have faith in the services that help you take care of your money (that’s why we recommend them), Kairos helps you organize your money and your mind.`
},
{
  title: 'Why should I use Kairos?',
  content: `Put simply, we do what everyone else offers, but way easier. No meetings, no suits, no slicked back hair, just you and your money. All in one place, we help you gain clarity, confidence, and control with your finances. Financial well-being and the foundation to build your wealth are at your fingertips with Kairos.`
},
{
  title: 'What happens after I sign up?',
  content: `After sign-up, pat yourself on the back! You just took the first step to financial wellness. Here, we’ll get to know more about you, your goals, and your current financial situation. You’ll then be able to connect your financial accounts to give us a clearer picture of your net worth and financial health (no judgment; we’ve probably been in worse shape ourselves)!

From here, we’ll take you to your dashboard, which includes your overall situation, what we think you’re missing, your personalized product matches, and educational resources to grow your mind with your money!`
},
{
  title: 'Is there an app?',
  content: `Almost! For now, we support mobile use of our website & our app is in development! Don’t worry, it will be out faster than you can say worcestershire (correctly)!`
},
{
  title: 'Does Kairos have a connection/partnership with the services provided? ',
  content: `We do not have a partnership with the products we match you with. We have a selection process that would make Shark Tank look like a kiddie pool and we do not receive any compensation for recommending any of the products. We recommend them because we truly believe they are best for you.`
},
{
  title: 'What happens to my financial data if I unsubscribe from Kairos?',
  content: `If you decide to unsubscribe from Kairos (unlikely, but possible), your accounts will be disconnected, but we’ll still keep the goals and question data you gave us like an ex keeps a hoodie, hoping you’ll come back. The only difference is, we’ll get rid of it if you ask! Just email hello@kairos.financial or use the chat feature on our website and ask us to wipe away your data!`
}]
const plus = require('../../assets/plus.png')
const minus = require('../../assets/minus.png')
const FAQ = () => {
  const { isAuthenticated } = useAuth0();
  const [collapsed, setCollapsed] = useState(
    new Array(questions.length).fill(false)
  );
  const handleOnChange = (position) => {
    const updateCollapsed = collapsed.map((item, index) =>
      index === position ? !item : item
    );
    setCollapsed(updateCollapsed);
  };
  return (
    <>
      <div className="large-container bg-white">
        <div className="main-container text-center">
          <p className="accent-callout mx-auto neutral-400 mb-[31px] mx-auto">FIRE AWAY</p>
          <h1 className='mb-[90px] mx-auto'>FAQs</h1>
          <div className="internal-container text-left lg:px-[140px] px-[15px]">
            <div className="shadow-lg rounded-[10px]">
              {questions.map((question, index) => (
                <div>
                  <div className="h-[130px] lg:px-[85px] px-[25px] flex items-center relative">
                    <p className="accent-3 pr-[40px] lg:pr-0">{question.title}</p>
                    <img src={collapsed[index] === true ? minus : plus} className='cursor-pointer absolute lg:right-[85px] right-[25px]' onClick={() => { handleOnChange(index) }}></img>
                  </div>
                  <p className={`paragraph-1 whitespace-pre-wrap text-justify ${collapsed[index] === true ? 'show-faq' : 'hide-faq'}`}>{question.content}</p>
                  <hr className={`hr-2 mx-auto ${questions.length === index + 1 ? 'hidden' : ''}`}></hr>
                </div>


              ))}

            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  )
}

export default FAQ