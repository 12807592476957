import React, { useState, useEffect, createContext, useContext } from 'react';
import InternalFooter from './Footer';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react';
import postLoadSaveData from '../../utils/postLoadSaveData';
import postLoadFetchData from '../../utils/postLoadFetchData';
import postLoadModifyData from '../../utils/postLoadModifyData';
import postLoadPostData from '../../utils/postLoadPostData';
import Modal from 'react-modal';
const close = require('../../assets/common/close-white.svg').default;
const logo = require('../../assets/logo-color.png');
const UserContext = createContext()
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    background: 'white',
    'border-radius': '10px',
    padding: '0px'
  },
  overlay: {zIndex: 1000}
};
const LoadBExistingInvestments = () => {
  const email = useContext(UserContext);

  return (
    <>
      <div className='w-screen m-0'>
        <Fields email={email}></Fields>
        <InternalFooter></InternalFooter>
      </div>
    </>
  )
}

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local') {
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

function Fields(props) {
  const email = props.email;
  const [answers, setAnswers] = useState(null);
  const [profileData, setProfileData] = useState(null);
  // Get token function, urls
  const { getAccessTokenSilently, user } = useAuth0();
  const baseURL = apiBaseURL;
  const urlprofile = `${baseURL}financial_discovery/financial_profile/?email=${email}&mode=profile`
  const url = `${baseURL}client_extras/answers/`;
  const urlsubmit = `${baseURL}financial_discovery/financial_card/`;

  // Example: post data on load
  useEffect(() => {
    // Start the process by getting the token
    (async () => {
      try {
        const email = await user.email;
        const token = await getAccessTokenSilently();
        const response = await postLoadPostData(url, { "question": "What are you investing in?" }, token);
        const consolidate = await postLoadFetchData(urlprofile, token);
        setProfileData(consolidate.status ? null : consolidate);
        setAnswers(response);
        setCheckedState(
          () => {
            if (consolidate?.financial_profile["What are you investing in?"].length > 0) {
              const array = consolidate?.financial_profile["What are you investing in?"];
              console.log(array)
              const indexArray = response.map((card, index) => {
                if (array.includes(card.answer)) {
                  return true
                }
                return false
              })
              console.log(indexArray)
              return indexArray;
            } else {
              return new Array(response.length).fill(false)
            }


          }
        )
        console.log('data: ', response)
        // console.log("Got new token: " + token)
        // Execute the api method by calling the function
        // Uncomment the line below to post data on load
        // const response = await postLoadSaveData(url, payload, token);
      } catch (err) {
        console.log(err);
      }
    })();
    // End process
  }, [])

  // Post data on button click
  const postOnClick = async () => {
    let newAnswers = [];
    for (let i = 0; i < answers.length; i++) {
      if (checkedState[i]) {
        const element = {
          "answer": answers[i].answer
        };
        newAnswers.push(element)
      }
    }
    let payload = {
      "email": user.email,
      "question_id": answers[0].question_id,
      "answers": newAnswers
    }
    console.log(payload)
    try {
      const token = await getAccessTokenSilently();
      // Execute the api method by calling the function
      const response = await postLoadSaveData(urlsubmit, payload, token);
      console.log(response);
    } catch (err) {
      console.log(err);
    } finally {
      navigate("/onboarding/property")
    }

  }

  const putOnClickProfile = async () => {
    let newAnswers = [];
    for (let i = 0; i < answers.length; i++) {
      if (checkedState[i]) {
        const element = {
          "answer": answers[i].answer
        };
        newAnswers.push(element)
      }
    }
    let payload = {
      "email": user.email,
      "question_id": answers[0].question_id,
      "answers": newAnswers
    }
    console.log(payload)
    try {
      const token = await getAccessTokenSilently();
      console.log("Got new token for function: " + token)
      // Execute the api method by calling the function
      const response = await postLoadModifyData(urlsubmit, payload, token);
      console.log(response)
    } catch (err) {
      console.log(err);
    } finally {
      navigate("/onboarding/property")
    }

  }

  // Post data on button click END  

  const navigate = useNavigate();
  // Checkboxes
  const [checkedState, setCheckedState] = useState(null);
  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };
  // Checkboxes END
  const location = useLocation();
  const [cardActive, setCardActive] = useState(null);

  //Modal Functions START
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  // Modal Functions END

  return (answers && <>
    <form onSubmit={profileData?.financial_profile["What are you investing in?"].length > 0 ? putOnClickProfile : postOnClick}>
      <div className='flex flex-row min-h-[85vh]'>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Sign in to bookmark"
        >
          <div className='bg-black py-[35px] px-[50px] w-[400px] lg:w-[569px] h-[310px] relative'>
            <p className='basic-white text-center paragraph-1-to-mobile'>All investments are great, but they can serve different purposes. Knowing what type of investments you have will help us show you what to do in order to maximize your return.</p>
            <img src={close} onClick={closeModal} alt="Close" className='cursor-pointer w-[31px] h-[31px] absolute bottom-[64px] right-1/2 translate-x-2/4'></img>
          </div>

        </Modal>
        <nav className="hidden lg:flex flex-col flex-nowrap bg-white basis-[312px] shrink-0 grow-0">
          <img src={logo} className="w-[264.15px] h-[65px] mt-[41px] self-center" alt="KairosLogo" />
          <div className="onboarding-bar w-[30px] h-[266px] rounded-full mt-[75px] ml-[59px]">
            <div className="bar-progress w-[30px] h-[149px] rounded-full"><div className="bar-dot w-[10px] h-[10px] rounded-full top-[10px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Tell Us About Yourself</p></div></div>
            <div className="bar-dot w-[10px] h-[10px] rounded-full top-[123px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Set Your Goals</p></div>
            <div className="bar-dot w-[10px] h-[10px] rounded-full top-[240px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Connect Your Accounts</p></div>
          </div>
        </nav>
        <div className='bg-neutral-25 pb-[70px] w-[100%]'>
          <div className='mt-[50px] lg:mt-[106px] mb-[50px] lg:mb-[100px] mx-[30px] lg:mx-[109px]'>
            <h1 className='text-center'>What are you investing in?</h1>
            <p className='paragraph-1 text-center'>Not sure? <span className='underline cursor-pointer'><Link to="/articles/exploring-three-common-types-of-investment" target="_blank">Here’s some more info on some of the most common types of investment accounts.</Link></span></p>
            <br />
            <div className='paragraph-1 text-center underline text-center'>
                <p className='cursor-pointer inline' onClick={openModal}>Why do we ask this?</p>
              </div>
          </div>
          <div className='max-[429px]:grid-cols-1 grid grid-cols-2 md:grid-cols-3 lg:gap-8 mx-auto lg:w-[798px] w-full lg:px-0 px-[46px] gap-y-[25px] gap-x-[25px]'>
            {answers.map((card, index) => (
              <div key={index} onMouseEnter={() => { setCardActive(index); console.log(cardActive) }} onMouseLeave={() => setCardActive(null)}>
                <input id={index} className='hidden' type="checkbox" value={card.answer} name={'selection-' + index} checked={checkedState[index]} onChange={() => { handleOnChange(index) }} />
                <label htmlFor={index}>
                  <div className='onboarding-card mx-auto' style={{ backgroundColor: checkedState[index] === true ? "rgba(247, 189, 132, 0.8)" : "" }}>
                    <img src={((checkedState[index] === true) || (cardActive === index)) ? apiBaseURL.slice(0, -1) + card.image_hover : apiBaseURL.slice(0, -1) + card.image} className="w-[60px] h-[60px] lg:w-[80px] lg:h-[80px] mx-auto" alt={card.answer} />
                    <p className='card-label mx-auto'>{card.answer}</p>
                  </div>
                </label>
              </div>
            ))}
          </div>




        </div>
      </div>

      <div className='flex flex-col-reverse lg:flex-row justify-between align-middle py-[37px] px-[119px] top-shadow'>
        <button className='text-only-button mt-[15px] lg:mt-0' type='button' onClick={() => navigate(-1)}>Go Back</button>
        <button className='gold-button' disabled={!checkedState.includes(true)} type='button' onClick={() => profileData?.financial_profile["What are you investing in?"].length > 0 ? putOnClickProfile() : postOnClick()}>Next</button>
      </div>
    </form>
  </>)
}

const BExistingInvestments = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  isAuthenticated && console.log(user);

  // Redirect to home if not authenticated
  !isLoading && !isAuthenticated && loginWithRedirect()

  return (
    // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
    isAuthenticated && <UserContext.Provider value={user.email}><LoadBExistingInvestments email={user.email} /></UserContext.Provider>
  )
}

export default BExistingInvestments