import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound";
import CancelSignUp from "./pages/CancelSignUp";
import SuscriptionCancellation from "./pages/SuscriptionCancellation";
import LoggingOut from "./pages/LoggingOut"
import { APersonalInfo, Welcome, EmploymentStatus, Property, Finances, Goals, GoalsPrioritize, Investing, Loans, Insurance, FinancialServices, SavingsGoals, SavingsGoalsSuccess, GigWork, Connect } from "./pages/Onboarding/";
import { BPersonalInfo, BLifeInsurance, BPriorities, B529, BDebt, BExistingDebt, BInvesting, BExistingInvestments, BProperty, BHomeInsurance, BRentersInsurance, BSavings, BHYS, BEmployment, BGigWork, BComfort, BBrokerage, BRetirement, Stacking } from "./pages/NewOnboarding";
import { VerifyEmail, Features, Company, FAQ, Logout, Articles, PublicArticle } from "./pages/Marketing/";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import ScrollToTop from "./ScrollToTop";
import Dashboard from "./pages/Home/Dashboard";
import { Accounts, Advising, DashboardMain, Guides, Help, ProductMatches, Profile, Resources, YourGoals, Article, Hierarchy, Finstack } from "./pages/Main/";
import Payment from "./components/stripe/Payment";
import Success from "./components/stripe/Success";
import LoadLink from "./components/plaid/LoadLink";
import "./protect.css"
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ComingBack from "./pages/ComingBack";
import Expired from "./pages/Expired";
import '@ionic/react/css/core.css';
import { setupIonicReact } from '@ionic/react';
import { initAmplitude } from "./utils/amplitude";
import Start from "./components/stripe/Start";

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
/* var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local') {
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
} */

function Login () {
  // const navigate = useNavigate();
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  // User Login info
  const database = [
    {
      username: "unidentified_guest",
      password: "kairos1546982"
    },
    {
      username: "SageView",
      password: "Jamborre92662"
    },
    {
      username: "Rodney",
      password: "Pennsylvania"
    } // new user
  ];

  const errors = {
    uname: "invalid username",
    pass: "invalid password"
  };

  const handleSubmit = (event) => {
    //Prevent page reload
    event.preventDefault();

    var { uname, pass } = document.forms[0];

    // Find user login info
    const userData = database.find((user) => user.username === uname.value);

    // Compare user info
    if (userData) {
      if (userData.password !== pass.value) {
        // Invalid password
        setErrorMessages({ name: "pass", message: errors.pass });
      } else {
        localStorage.setItem("logged_user", JSON.stringify(uname.value));
        window.location.href = "/";
      }
    } else {
      // Username not found
      setErrorMessages({ name: "uname", message: errors.uname });
    }
  };

  return (
    <>
      <div className='large-container bg-white p-[60px]'>
        <div className='main-container mx-auto flex flex-row place-content-between'>
          <div className='w-[586px]'>
            <p className='neutral-400 accent-callout mt-16'>GENERATIONAL WEALTH STARTS HERE</p>
            <h1>Wealth Management for the Rest of Us</h1>
            <p className='paragraph-1 neutral-500 mb-16'>This is a restricted area</p>
            <div className="form">
              <form onSubmit={handleSubmit}>
                <div className="input-container">
                  <input type="text" name="uname" required />
                  {renderErrorMessage("uname")}
                </div>
                <div className="input-container">
                  <input type="password" name="pass" required />
                  {renderErrorMessage("pass")}
                </div>
                <div className="button-container">
                  <input type="submit" className='gold-button' />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
// END LOGIN

function App() {
  //initAmplitude();
  const [userSession, setUserSession] = useState(
    JSON.parse(localStorage.getItem('logged_user'))
  );
  console.log("User: ", userSession)

  if (!userSession&&(running_env==='development')){
    return (
      <Login />
    )
  }

  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<><Navbar></Navbar><Home></Home></>} />
          <Route path="/home" element={<><Navbar></Navbar><Home></Home></>} />
          <Route path="/features" element={<><Navbar></Navbar><Features></Features></>} />
          <Route path="/company" element={<><Navbar></Navbar><Company></Company></>} />
          <Route path="/faq" element={<><Navbar></Navbar><FAQ></FAQ></>} />
          <Route path="/articles" element={<><Navbar></Navbar><Articles></Articles></>} />
          <Route path="/articles/:slug" element={<><PublicArticle></PublicArticle></>} />
          <Route path="/verifyemail" element={<><Navbar></Navbar><VerifyEmail></VerifyEmail></>} />
          <Route path="/logout" element={<><Navbar></Navbar><Logout></Logout></>} />
          <Route path="/personal-info" element={<><APersonalInfo></APersonalInfo></>} />
          <Route path="/employment-status" element={<><EmploymentStatus></EmploymentStatus></>} />
          <Route path="/property" element={<><Property></Property></>} />
          <Route path="/finances" element={<><Finances></Finances></>} />
          <Route path="/goals" element={<><Goals></Goals></>} />
          <Route path="/goals-prioritize" element={<><GoalsPrioritize></GoalsPrioritize></>} />
          <Route path="/investing" element={<><Investing></Investing></>} />
          <Route path="/loans" element={<><Loans></Loans></>} />
          <Route path="/insurance" element={<><Insurance></Insurance></>} />
          <Route path="/financial-services" element={<><FinancialServices></FinancialServices></>} />
          <Route path="/savings-goals" element={<><SavingsGoals></SavingsGoals></>} />
          <Route path="/savings-goals-success" element={<><SavingsGoalsSuccess></SavingsGoalsSuccess></>} />
          <Route path="/welcome" element={<><Welcome></Welcome></>} />
          <Route path="/gig-work" element={<><GigWork></GigWork></>} />
          <Route path="/dashboard" element={<><Dashboard></Dashboard></>} />
          {/* <Route path="/accounts" element={<><Accounts></Accounts></>} /> */}
          {/* <Route path="/1on1" element={<><Advising></Advising></>} /> */}
          <Route path="/guides" element={<><Guides></Guides></>} />
          <Route path="/help" element={<><Help></Help></>} />
          <Route path="/resources/:slug" element={<><Article></Article></>} />
          <Route path="/hierarchy" element={<><Finstack></Finstack></>} />
          <Route path="/product-matches" element={<><ProductMatches></ProductMatches></>} />
          <Route path="/finstack" element={<><Finstack></Finstack></>} />
          <Route path="/resources" element={<><Resources></Resources></>} />
          <Route path="/your-goals" element={<><YourGoals></YourGoals></>} />
          <Route path="/main-dashboard" element={<><DashboardMain></DashboardMain></>} />
          <Route path="/profile" element={<><Profile></Profile></>} />
          <Route path="/onboarding/payment" element={<><Start></Start></>} />
          <Route path="/connect-test" element={<><LoadLink></LoadLink></>} />
          <Route path="/onboarding/connect-accounts" element={<><Connect></Connect></>} />
          <Route path="*" element={<><Navbar></Navbar><NotFound></NotFound></>} />
          <Route path="/loggedout" element={<><Navbar></Navbar><LoggingOut></LoggingOut></>} />
          <Route path="/canceled" element={<><Navbar></Navbar><CancelSignUp ></CancelSignUp ></>} />
          <Route path="/good-bye" element={<><Navbar></Navbar><SuscriptionCancellation ></SuscriptionCancellation ></>} />
          <Route path="/coming-back" element={<><Navbar></Navbar><ComingBack></ComingBack></>} />
          <Route path="/expired" element={<><Navbar></Navbar><Expired></Expired></>} />
          {/* NEW ONBOARDING */}
          <Route path="/onboarding/personal-info" element={<><BPersonalInfo></BPersonalInfo></>}/>
          <Route path="/onboarding/529-plan" element={<><B529></B529></>}/>
          <Route path="/onboarding/life-insurance" element={<><BLifeInsurance></BLifeInsurance></>}/>
          <Route path="/onboarding/priorities" element={<><BPriorities></BPriorities></>}/>
          <Route path="/onboarding/debt" element={<><BDebt></BDebt></>}/>
          <Route path="/onboarding/existing-debt" element={<><BExistingDebt></BExistingDebt></>}/>
          <Route path="/onboarding/investing" element={<><BInvesting></BInvesting></>}/>
          {/* <Route path="/onboarding/existing-investments" element={<><BExistingInvestments></BExistingInvestments></>}/> */}
          <Route path="/onboarding/brokerage" element={<><BBrokerage></BBrokerage></>}/>
          <Route path="/onboarding/retirement" element={<><BRetirement></BRetirement></>}/>
          <Route path="/onboarding/property" element={<><BProperty></BProperty></>}/>
          <Route path="/onboarding/home-insurance" element={<><BHomeInsurance></BHomeInsurance></>}/>
          <Route path="/onboarding/rent-insurance" element={<><BRentersInsurance></BRentersInsurance></>}/>
          <Route path="/onboarding/3-months-savings" element={<><BSavings></BSavings></>}/>
          <Route path="/onboarding/high-yield-savings" element={<><BHYS></BHYS></>}/>
          <Route path="/onboarding/employment" element={<><BEmployment></BEmployment></>}/>
          <Route path="/onboarding/gig-work" element={<><BGigWork></BGigWork></>}/>
          <Route path="/onboarding/finance-experience" element={<><BComfort></BComfort></>}/>
          <Route path="/onboarding/stacking" element={<><Stacking></Stacking></>}/>
        </Routes>
      </ScrollToTop>
    </Router>
  );
};
export default App;
