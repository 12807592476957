
import './APersonalInfo.css';
import React, { useState, useEffect, createContext, useContext } from 'react';
import InternalFooter from './Footer';
import { useLocation, useNavigate } from 'react-router-dom'
import postLoadSaveData from '../../utils/postLoadSaveData';
import postLoadFetchData from '../../utils/postLoadFetchData';
import { useAuth0 } from '@auth0/auth0-react';
import arrow from '../../assets/select-arrow.svg';
import postLoadModifyData from '../../utils/postLoadModifyData';
const logo = require('../../assets/logo-color.png')
const UserContext = createContext()

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
  apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
  apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local') {
  apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

const LoadAPersonalInfo = (props) => {

  const email = useContext(UserContext);


  // Post data on button click END

  return (

    <>
      <div className='w-screen m-0'>
        <Fields email={email}></Fields>
        <InternalFooter></InternalFooter>
      </div>
    </>
  )
}

function Fields(props) {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const email = props.email;
  const [profileData, setProfileData] = useState(null);
  const baseURL = apiBaseURL;
  const url = `${baseURL}user_profiles/user_and_address/?email=${email}`;
  const urlsubmit = `${baseURL}user_profiles/user_and_address/`;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateofBirth] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [kidCount, setKidCount] = useState(0);
  const [errorDate, setErrorDate] = useState('hidden');
  const [errorPhone, setErrorPhone] = useState('hidden');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        console.log("Got new token for function: " + token)
        // Execute the api method by calling the function
        const consolidate = await postLoadFetchData(url, token);
        setProfileData(consolidate.status ? null : consolidate);
        console.log('data: ', profileData)
        console.log('user', user)
        setFirstName(consolidate.user.name)
        setLastName(consolidate.user.lastname)
        setDateofBirth(() => {
          const response = consolidate.user.birth_date;
          const date = response.split('-');
          const newDate = date[1] + "/" + date[2] + "/" + date[0];
          return newDate;
        })
        setStreetAddress(consolidate.user_address.street)
        setCity(consolidate.user_address.city)
        setState(consolidate.user_address.state)
        setZip(consolidate.user_address.zip_code)
        setPhoneNumber(consolidate.user.phone_number)
        setKidCount(consolidate.user.kids)


      } catch (err) {
        console.log('Error:')
        console.log(err);
      }
    })();
  }, []);

  // Post data on button click
  const postOnClick = async () => {
    if (!(/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/.test(dateOfBirth))) {
      console.log('error')
      setErrorDate('text-error')
      return
    } else {
      setErrorDate('hidden')
    }
    const numbers = phoneNumber.replace('(', '').replace(')', '').replace('-', '');
    if (numbers.length !== 10) {
      console.log('phone error')
      setErrorPhone('text-error')
      return
    } else {
      setErrorPhone('hidden')
    }
    const date = dateOfBirth.split('/');
    const newDate = date[2] + "-" + date[0] + "-" + date[1];
    let payload = {
      name: firstName,
      lastname: lastName,
      email: email,
      birth_date: newDate,
      phone_number: phoneNumber,
      kids: kidCount,
      street: streetAddress,
      city: city,
      state: state,
      country: "US",
      zip_code: zip
    }
    console.log(payload);
    try {
      const token = await getAccessTokenSilently();
      // console.log("Got new token for function: " + token)
      // Execute the api method by calling the function
      const response = await postLoadSaveData(urlsubmit, payload, token);
      console.log(response);
    } catch (err) {
      console.log(err);
    } finally {
      navigate("/welcome");
    }

  }

  const putOnClickProfile = async () => {
    if (!(/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/.test(dateOfBirth))) {
      console.log('error',dateOfBirth)
      setErrorDate('text-error')
      return
    } else {
      setErrorDate('hidden')
    }
    const numbers = phoneNumber.replace('(', '').replace(')', '').replace('-', '');
    if (numbers.length !== 10) {
      console.log('phone error')
      setErrorPhone('text-error')
      return
    } else {
      setErrorPhone('hidden')
    }
    const date = dateOfBirth.split('/');
    const newDate = date[2] + "-" + date[0] + "-" + date[1];
    let payload = {
      "name": firstName,
      "lastname": lastName,
      "email": email,
      "birth_date": newDate,
      "phone_number": phoneNumber,
      "kids": kidCount,
      "street": streetAddress,
      "city": city,
      "state": state,
      "country": profileData.user_address.country,
      "zip_code": zip,
      "userID": profileData.user_address.userID,
    }
    console.log(payload);
    try {
      const token = await getAccessTokenSilently();
      // console.log("Got new token for function: " + token)
      // Execute the api method by calling the function
      const response = await postLoadModifyData(urlsubmit, payload, token);
      console.log(response);
    } catch (err) {
      console.log(err);
    } finally {
      navigate("/welcome");
    }
  }

  // Post data on button click END



  return (
    (user) && <>
      <form onSubmit={profileData ? putOnClickProfile : postOnClick}>
        <div className='flex flex-row min-h-[85vh]'>
          <nav className="hidden lg:flex flex-col flex-nowrap bg-white basis-[312px] shrink-0 grow-0">
            <img src={logo} className="w-[264.15px] h-[65px] mt-[41px] self-center" alt="KairosLogo" />
            <div className="onboarding-bar w-[30px] h-[266px] rounded-full mt-[75px] ml-[59px]">
              <div className="bar-progress w-[30px] h-[30px] rounded-full"><div className="bar-dot w-[10px] h-[10px] rounded-full top-[10px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Tell Us About Yourself</p></div></div>
              <div className="bar-dot w-[10px] h-[10px] rounded-full top-[123px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Set Your Goals</p></div>
              <div className="bar-dot w-[10px] h-[10px] rounded-full top-[240px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Connect Your Accounts</p></div>
            </div>
          </nav>
          <div className='bg-neutral-25 pb-[70px] w-[100%]'>
            <div className='mt-[50px] lg:mt-[106px] mb-[50px] lg:mb-[100px] mx-[30px] lg:mx-[109px]'>
              <h1 className='text-center'>Personal information</h1>
              <p className='paragraph-1 text-center'>Before we head to your dashboard, we need a few final pieces of information from you.
                Kairos will never share your personal information without your permission. Please enter it here for personal use and to help our advisors get to know you better.</p>
            </div>

            <div className='mx-auto w-[619px] flex flex-row flex-wrap justify-between'>
              <input className='w-[295px]' type='text' value={firstName} name='firstName' placeholder='First Name*' onChange={(e) => setFirstName(e.target.value)} required></input>
              <input className='w-[295px]' type='text' value={lastName} name='lastName' placeholder='Last Name*' onChange={(e) => setLastName(e.target.value)} required></input>
              <small className={errorDate}>Enter a valid date</small>
              <input className='w-[619px]' type='text' value={dateOfBirth} name='dateOfBirth' placeholder='Date of Birth* (MM/DD/YYYY)' pattern='^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$' onChange={(e) => {
                let string = e.target.value
                if ((string.length == 2) && (dateOfBirth.slice(-1) !== '/')) {
                  console.log(string)
                  string = string + "/";
                }
                if ((string.length == 5) && (dateOfBirth.slice(-1) !== '/')) {
                  console.log(string)
                  string = string + "/";
                }
                setDateofBirth(string);
              }} required></input>
              <input className='w-[619px]' type='text' value={streetAddress} name='streetAddress' placeholder='Street Address*' onChange={(e) => setStreetAddress(e.target.value)} required></input>
              <input className='w-[308px]' type='text' value={city} name='city' placeholder='City*' onChange={(e) => setCity(e.target.value)} required></input>
              <div className='w-[124px] relative mb-[25px]'>
                <select className='w-[124px]' type='text' value={state} name='state' placeholder='State*' onChange={(e) => setState(e.target.value)} required>
                  <option value="" disabled defaultValue hidden>State*</option>
                  <option value="AL">AL</option>
                  <option value="AK">AK</option>
                  <option value="AZ">AZ</option>
                  <option value="AR">AR</option>
                  <option value="CA">CA</option>
                  <option value="CO">CO</option>
                  <option value="CT">CT</option>
                  <option value="DE">DE</option>
                  <option value="DC">DC</option>
                  <option value="FL">FL</option>
                  <option value="GA">GA</option>
                  <option value="HI">HI</option>
                  <option value="ID">ID</option>
                  <option value="IL">IL</option>
                  <option value="IN">IN</option>
                  <option value="IA">IA</option>
                  <option value="KS">KS</option>
                  <option value="KY">KY</option>
                  <option value="LA">LA</option>
                  <option value="ME">ME</option>
                  <option value="MD">MD</option>
                  <option value="MA">MA</option>
                  <option value="MI">MI</option>
                  <option value="MN">MN</option>
                  <option value="MS">MS</option>
                  <option value="MO">MO</option>
                  <option value="MT">MT</option>
                  <option value="NE">NE</option>
                  <option value="NV">NV</option>
                  <option value="NH">NH</option>
                  <option value="NJ">NJ</option>
                  <option value="NM">NM</option>
                  <option value="NY">NY</option>
                  <option value="NC">NC</option>
                  <option value="ND">ND</option>
                  <option value="OH">OH</option>
                  <option value="OK">OK</option>
                  <option value="OR">OR</option>
                  <option value="PA">PA</option>
                  <option value="RI">RI</option>
                  <option value="SC">SC</option>
                  <option value="SD">SD</option>
                  <option value="TN">TN</option>
                  <option value="TX">TX</option>
                  <option value="UT">UT</option>
                  <option value="VT">VT</option>
                  <option value="VA">VA</option>
                  <option value="WA">WA</option>
                  <option value="WV">WV</option>
                  <option value="WI">WI</option>
                  <option value="WY">WY</option>
                </select>
                <img src={arrow} className="passthrough"></img>
              </div>
              <input className='w-[151px]' type='text' value={zip} name='zip' placeholder='Zip Code*' onChange={(e) => setZip(e.target.value)} required></input>
              <small className={errorPhone}>Enter a valid 10 digit phone number</small>
              <input className='w-[619px]' type='text' value={phoneNumber} name='phoneNumber' placeholder='Phone Number*' onChange={(e) => setPhoneNumber(e.target.value)}
                onKeyDown={(event) => {
                  console.log(event.key)
                  var validkeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'ArrowRight', 'ArrowLeft', 'Backspace', 'Enter', '(', ')', '-'];
                  if (validkeys.indexOf(event.key) < 0) {
                    event.preventDefault();
                  }
                }} required></input>
            </div>
            <div className='mx-auto w-[619px] flex flex-row justify-between align-middle'>
              <p className='special-text inline align-middle'>Kids</p>
              <div>
                <button type='button' disabled={!(kidCount >= 1)} className='function-button inline' onClick={() => setKidCount(kidCount - 1)}>-</button>
                <p className='mx-[37px] counter inline'>{kidCount}</p>
                <button type='button' className='function-button inline' onClick={() => setKidCount(kidCount + 1)}>+</button>
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col-reverse lg:flex-row justify-between align-middle py-[37px] px-[119px] top-shadow'>
          <button className='invisible text-only-button'>Go Back</button>
          <button disabled={(firstName === "") || (lastName === "") || (dateOfBirth === "") || (streetAddress === "") || (city === "") || (zip === "") || (phoneNumber === "")} className='gold-button' type="button" onClick={() => profileData ? putOnClickProfile() : postOnClick()}>Next</button>
        </div>
      </form >



    </>
  )

}

const APersonalInfo = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  isAuthenticated && console.log(user);

  // Redirect to home if not authenticated
  !isLoading && !isAuthenticated && loginWithRedirect()

  return (
    // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
    isAuthenticated && <UserContext.Provider value={user.email}><LoadAPersonalInfo email={user.email} /></UserContext.Provider>
  )
}

export default APersonalInfo