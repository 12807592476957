import Sidebar from "./components/Sidebar"
import Headings from "./components/Headings"
import UserCard from "./components/UserCard"
import Footer from "../../components/Footer"
import idea from "./../../assets/icons/idea-gold.svg"
import { useAuth0 } from "@auth0/auth0-react";
import { oembed } from "@loomhq/loom-embed"
import { useEffect, useState } from "react"
//"https://www.loom.com/share/941cac92d41347c19a6931882ce9babf"

function Video(props) {
    return (<>
        <iframe src={props.videoUrl + "?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen className={props.iframeClass}></iframe>
    </>)
}

const Guides = () => {
    const [embed, setEmbed] = useState(null)
    const [html, setHtml] = useState(null)
    useEffect(() => {
        const getOembed = async () => {
            const options = { width: "282px", height: "212px", gifThumbnail: "false" }
            const data = await oembed("https://www.loom.com/share/941cac92d41347c19a6931882ce9babf", options)
            setEmbed(data)
            setHtml(data.html)
            console.log(data);
        }
        getOembed();
    }, [])

    function Video(props) {
        const style = {
            position: "absolute",
            top: "0",
            left: "0",
            width: ""
        }
        return (<>
            <iframe src={props.videoUrl + "?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen className={props.iframeClass}></iframe>
        </>)
    }

    const { user, isAuthenticated } = useAuth0();
    return (
        isAuthenticated && <>
            <div>
                <Sidebar user={user}></Sidebar>
                <UserCard user={user} />
                <div className="app-container">
                    <div className="w-[1020px] mx-auto">
                        <Headings title='Your Guide to Kairos' icon={idea} />
                        <p className="ml-[105px] mt-[35px] mr-[60px] mb-[45px] paragraph-1">
                            We’ve put together the following videos to help you maximize Kairos’ services and benefits.
                            <br></br>
                            Please feel free to use these at any time and don’t hesitate to contact us with any additional questions!
                        </p>
                        <div className="flex flex-col gap-[56px] mb-[56px]">
                            <div>
                                <p className="accent-5 mb-[30px]">Introduction</p>
                                <div className="pl-[30px] grid grid-cols-3 gap-[18px]">

                                    <div className="w-[282px] shrink-0">
                                        <div className="overflow-hidden rounded-[10px] inline-block"><Video iframeClass="guidesIframe" videoUrl={"https://www.loom.com/embed/73574d34fed548868cbf5701cfaeb9a2"} ></Video></div>
                                        <div className="paragraph-1 !font-medium neutral-700 text-center line-clamp-3">Welcome to Kairos</div>
                                    </div>
                                    <div className="w-[282px] shrink-0">
                                        <div className="overflow-hidden rounded-[10px] inline-block"><Video iframeClass="guidesIframe" videoUrl={"https://www.loom.com/embed/e951be791650429fa21e91266a2ac265"} ></Video></div>
                                        <div className="paragraph-1 !font-medium neutral-700 text-center line-clamp-3">Who We Are</div>
                                    </div>
                                    <div className="w-[282px] shrink-0">
                                        <div className="overflow-hidden rounded-[10px] inline-block"><Video iframeClass="guidesIframe" videoUrl={"https://www.loom.com/embed/9f4590873f85458099abf92de0d4bb12"} ></Video></div>
                                        <div className="paragraph-1 !font-medium neutral-700 text-center line-clamp-3">Why Kairos?</div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p className="accent-5 mb-[30px]">Using Your Dashboard</p>
                                <div className="pl-[30px] grid grid-cols-3 gap-[18px]">
                                    <div className="w-[282px] shrink-0">
                                        <div className="overflow-hidden rounded-[10px] inline-block"><Video iframeClass="guidesIframe" videoUrl={"https://www.loom.com/embed/5e109780bd7d4189adcb3c6bba84eb65?sid=ccfd1b02-0d4e-49f7-a712-c38e35c00d6a"} ></Video></div>
                                        <div className="paragraph-1 !font-medium neutral-700 text-center line-clamp-3">A Tour of Your Dashboard</div>
                                    </div>
                                    <div className="w-[282px] shrink-0">
                                        <div className="overflow-hidden rounded-[10px] inline-block"><Video iframeClass="guidesIframe" videoUrl={"https://www.loom.com/embed/626abac6f9be41b496fc5cd647832bcd?sid=c240c444-bcf7-4bcf-bfa5-212955711e72"} ></Video></div>
                                        <div className="paragraph-1 !font-medium neutral-700 text-center line-clamp-3">Your Finstack and Product Matches</div>
                                    </div>
                                    <div className="w-[282px] shrink-0">
                                        <div className="overflow-hidden rounded-[10px] inline-block"><Video iframeClass="guidesIframe" videoUrl={"https://www.loom.com/embed/192ed17edb84426c8152ad09e26c1c20?sid=ecf1720d-1a4b-49cc-aaf4-3d4d7c031284"} ></Video></div>
                                        <div className="paragraph-1 !font-medium neutral-700 text-center line-clamp-3">Updating Your Financial Information</div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p className="accent-5 mb-[30px]">About Your Finstack</p>
                                <div className="pl-[30px] grid grid-cols-3 gap-[18px]">
                                    <div className="w-[282px] shrink-0">
                                        <div className="overflow-hidden rounded-[10px] inline-block"><Video iframeClass="guidesIframe" videoUrl={"https://www.loom.com/embed/168f09853bcc418980ac465fe51fdabd?sid=6b6e0f6e-011d-48ee-9b3f-e4063ad5fc44"} ></Video></div>
                                        <div className="paragraph-1 !font-medium neutral-700 text-center line-clamp-3">What is my Finstack?</div>
                                    </div>
                                    <div className="w-[282px] shrink-0">
                                        <div className="overflow-hidden rounded-[10px] inline-block"><Video iframeClass="guidesIframe" videoUrl={"https://www.loom.com/embed/ca9735aae74b438798c49f0ab232a370?sid=175363be-07c4-4744-9fe2-2033b14c2aa3"} ></Video></div>
                                        <div className="paragraph-1 !font-medium neutral-700 text-center line-clamp-3">What Do I Do With My Product Matches?</div>
                                    </div>
                                    {/* <div className="overflow-hidden rounded-[10px] inline-block"><Video iframeClass="guidesIframe" videoUrl={"https://www.loom.com/embed/73574d34fed548868cbf5701cfaeb9a2"} ></Video></div> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>

    )
}

export default Guides