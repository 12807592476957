import { useAuth0 } from '@auth0/auth0-react';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import postLoadFetchData from '../../../utils/postLoadFetchData';

const logo = require('../../../assets/logos/simple.svg').default;
const home = require('../../../assets/icons/sidebar/Home.svg').default;
const goals = require('../../../assets/icons/sidebar/Goal.svg').default;
const sheets = require('../../../assets/icons/sidebar/Sheets.svg').default;
const cards = require('../../../assets/icons/sidebar/BankCards.svg').default;
const books = require('../../../assets/icons/sidebar/Books.svg').default;
const map = require('../../../assets/icons/sidebar/Map.svg').default;
const meeting = require('../../../assets/icons/sidebar/FemaleMeeting.svg').default;
const help = require('../../../assets/icons/sidebar/Help.svg').default;
const hierarchy = require('../../../assets/icons/sidebar/Hierarchy.svg').default;
const stack = require('../../../assets/icons/sidebar/Stack.svg').default;
const person = require('../../../assets/icons/sidebar/Person.svg').default;
const menu = require('../../../assets/common/menu.svg').default;
const close = require('../../../assets/common/close.svg').default;
const logofull = require('../../../assets/logo-color.png');

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
    apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
    apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local') {
    apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

const Sidebar = (props) => {
    const navigate = useNavigate();
    const { logout } = useAuth0();
    const [collapse, setCollapse] = useState(false);
    const [navbar, setNavbar] = useState(false);

    return (
        <>
            <div className="bg-black w-[260px] h-[800px] py-[25px] sticky top-0 left-0 basic-white float-left shadow-under hidden lg:block">
                <img src={logo} alt='Kairos' className='px-[25px]'></img>
                <ul className='align-middle mt-[87px]'>
                    <li className='h-[54px] py-[11px] px-[25px]'>
                        <img src={home} className='inline mr-[15px] h-[20px] w-[20px]' alt='Home'></img>
                        <button className="sidebar-link accent-4" onClick={() => navigate("/main-dashboard")}>Dashboard</button>
                    </li>
                    <div onMouseLeave={() => { setCollapse(false) }} onMouseEnter={() => { setCollapse(true) }}>
                        <li className='h-[54px] py-[11px] px-[25px]'>
                            <img src={stack} className='inline mr-[15px]' alt='Finstack'></img>
                            <button className="sidebar-link accent-4" onClick={() => navigate("/finstack")}>Finstack</button>
                        </li>
                        <div className={collapse ? 'block' : 'hidden'}>
                            <ProductMatches email={props.user.email}></ProductMatches>
                        </div>
                    </div>
{/*                     <li className='h-[54px] py-[11px] px-[25px]'>
                        <img src={cards} className='inline mr-[15px]' alt='Accounts'></img>
                        <button className="sidebar-link accent-4" onClick={() => navigate("/accounts")}>Accounts</button>
                    </li> */}
                    <li className='h-[54px] py-[11px] px-[25px]'>
                        <img src={books} className='inline mr-[15px]' alt='Resources'></img>
                        <button className="sidebar-link accent-4" onClick={() => navigate("/resources")}>Resources</button>
                    </li>
                    <li className='h-[54px] py-[11px] px-[25px]'>
                        <img src={map} className='inline mr-[15px]' alt='Guides'></img>
                        <button className="sidebar-link accent-4" onClick={() => navigate("/guides")}>Guides</button>
                    </li>
{/*                     <li className='h-[54px] py-[11px] px-[25px]'>
                        <img src={meeting} className='inline mr-[15px]' alt='1-on-1 Consulting'></img>
                        <button className="sidebar-link accent-4" onClick={() => navigate("/1on1")}>1-on-1 Consulting</button>
                    </li> */}
                </ul>
                <button className='absolute bottom-[25px] sidebar-logout px-[25px]' onClick={() => { console.log(window.location.origin + '/loggedout'); logout({ returnTo: window.location.origin + '/loggedout' }) }}>Log Out</button>
            </div>
            <div>
                <nav className="main-header w-full lg:hidden">
                    <div className="justify-between lg:max-w-7xl lg:items-center lg:flex lg:px-8 mx-auto">
                        <div className="relative z-40 shadow-[0_4px_6px_0px_rgba(0,0,0,0.04)] lg:shadow-none">
                            <div className="flex items-center justify-between py-[10px] pr-[30px] pl-[20px] lg:py-0 lg:block">
                                <img src={logofull} className="to-link lg:h-[76px] h-[33px]" alt="KairosLogo" onClick={() => {navigate("/main-dashboard");setNavbar(!navbar)}} />

                                <div className="flex flex-row space-x-[22px]">
                                    <div className="lg:hidden">
                                        <button
                                            className="p-2 text-gray-700 rounded-md outline-none"
                                            onClick={() => setNavbar(!navbar)}
                                        >
                                            {navbar ? (
                                                <img src={close} className="w-6 h-6 text-white" alt="Close"></img>
                                            ) : (
                                                <img src={menu} className="w-6 h-6 text-white" alt="Menu"></img>
                                            )}
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="relative z-0">
                            <div
                                className={`flex-1 justify-self-center pb-3 lg:block lg:pb-0 ${navbar ? "block bg-black" : "hidden"
                                    }`}
                            >
                                <ul className="nav-links basic-white justify-items-center">
                                    <li className='black-menu-item'>
                                        <img src={home} className='inline mr-[15px] h-[20px] w-[20px] self-center' alt='Home'></img>
                                        <button className="sidebar-link accent-4 basic-white" onClick={() => {navigate("/main-dashboard");setNavbar(!navbar)}}>Dashboard</button>
                                    </li>
                                    <li className='black-menu-item'>
                                        <img src={person} className='inline mr-[15px] h-[20px] w-[20px] self-center' alt='Profile'></img>
                                        <button className="sidebar-link accent-4 basic-white" onClick={() => {navigate("/profile");setNavbar(!navbar)}}>Profile</button>
                                    </li>
                                    <li className='black-menu-item'>
                                        <div className='text-left' onClick={()=>setNavbar(!navbar)}>
                                            <div>
                                                <img src={stack} className='inline mr-[15px]' alt='Stack'></img>
                                                <button className="sidebar-link accent-4 basic-white text-left" onClick={() => {navigate("/finstack");}}>Finstack</button>
                                            </div>
                                            <ProductMatches email={props.user.email}></ProductMatches>
                                        </div>

                                    </li>

{/*                                     <li className='black-menu-item'>
                                        <img src={cards} className='inline mr-[15px] self-center' alt='Accounts'></img>
                                        <button className="sidebar-link accent-4 basic-white" onClick={() => {navigate("/accounts");setNavbar(!navbar)}}>Accounts</button>
                                    </li> */}
                                    <li className='black-menu-item'>
                                        <img src={books} className='inline mr-[15px] self-center' alt='Resources'></img>
                                        <button className="sidebar-link accent-4 basic-white" onClick={() => {navigate("/resources");setNavbar(!navbar)}}>Resources</button>
                                    </li>
                                    <li className='black-menu-item'>
                                        <img src={map} className='inline mr-[15px] self-center' alt='Guides'></img>
                                        <button className="sidebar-link accent-4 basic-white" onClick={() => {navigate("/guides");setNavbar(!navbar)}}>Guides</button>
                                    </li>
{/*                                     <li className='black-menu-item'>
                                        <img src={meeting} className='inline mr-[15px] self-center' alt='1-on-1 Consulting'></img>
                                        <button className="sidebar-link accent-4 basic-white" onClick={() => {navigate("/1on1");setNavbar(!navbar)}}>1-on-1 Consulting</button>
                                    </li> */}
                                    <li className='justify-self-end'>
                                        <button className='sidebar-logout px-[25px] pt-[18px]' onClick={() => { console.log(window.location.origin + '/loggedout');setNavbar(!navbar); logout({ returnTo: window.location.origin + '/loggedout' }) }}>Log Out</button>
                                    </li>
                                </ul>

                            </div>
                        </div>
                        <div className="hidden space-x-2 lg:inline-block">
                            <button className="py-2 link-button mr-6">Log In</button>
                            <button className="px-4 py-2 nav-gold-button">Sign Up</button>

                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}

function ProductMatches(props) {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [productMatches, setProductMatches] = useState(null);
    const baseURL = apiBaseURL;
    const urlproduct = `${baseURL}product_catalog/matching_products/?email=${props.email}`;
    const navigate = useNavigate();
    console.log('PROPS', props)

    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently();
                console.log("Got new token for function: " + token)
                // Execute the api method by calling the function
                const products = await postLoadFetchData(urlproduct, token);
                setProductMatches(products);
                console.log('products: ', products)
                console.log('user', user)


            } catch (err) {
                console.log(err);
            }
        })();
    }, []);

    if (!productMatches || (!!productMatches.status)) return <>
    </>;

    return (
        (user && productMatches && isAuthenticated) && <>
            <div className='hidden lg:block'>
                <li className='h-[54px] pl-[58px] py-[11px] bg-sidebar-effect'>
                    <button className="sidebar-link accent-1-mobile" onClick={() => navigate("/product-matches", { state: productMatches?.hierarchy[0]?.bucket_name })}>{productMatches?.hierarchy[0]?.display_name ? productMatches?.hierarchy[0]?.display_name : "UNKNOWN"}</button>
                </li>
                <li className='h-[54px] pl-[58px] py-[11px] bg-sidebar-effect'>
                    <button className="sidebar-link accent-1-mobile" onClick={() => navigate("/product-matches", { state: productMatches?.hierarchy[1]?.bucket_name })}>{productMatches?.hierarchy[1]?.display_name ? productMatches?.hierarchy[1]?.display_name : "UNKNOWN"}</button>
                </li>
                <li className='h-[54px] pl-[58px] py-[11px] bg-sidebar-effect'>
                    <button className="sidebar-link accent-1-mobile" onClick={() => navigate("/product-matches", { state: productMatches?.hierarchy[2]?.bucket_name })}>{productMatches?.hierarchy[2]?.display_name ? productMatches?.hierarchy[2]?.display_name : "UNKNOWN"}</button>
                </li>
            </div>
            <div className='flex flex-col lg:hidden items-start pl-[35px]'>
                <li className='py-[10px]'>
                    <button className="sidebar-link accent-1-mobile basic-white" onClick={() => {navigate("/product-matches", { state: productMatches?.hierarchy[0]?.bucket_name })}}>{productMatches?.hierarchy[0]?.display_name ? productMatches?.hierarchy[0]?.display_name : "UNKNOWN"}</button>
                </li>
                <li className='py-[10px]'>
                    <button className="sidebar-link accent-1-mobile basic-white" onClick={() => {navigate("/product-matches", { state: productMatches?.hierarchy[1]?.bucket_name })}}>{productMatches?.hierarchy[1]?.display_name ? productMatches?.hierarchy[1]?.display_name : "UNKNOWN"}</button>
                </li>
                <li className='py-[10px]'>
                    <button className="sidebar-link accent-1-mobile basic-white" onClick={() => {navigate("/product-matches", { state: productMatches?.hierarchy[2]?.bucket_name })}}>{productMatches?.hierarchy[2]?.display_name ? productMatches?.hierarchy[2]?.display_name : "UNKNOWN"}</button>
                </li>
            </div>
        </>
    )

}

export default Sidebar