import Sidebar from "./components/Sidebar"
import Headings from "./components/Headings"
import UserCard from "./components/UserCard"
import Footer from "../../components/Footer"
/*START lineart images*/
import hierarchy from "./../../assets/icons/hierarchy-gold.svg"
/*END lineart images*/
import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useState, createContext } from "react"
import postLoadFetchData from "../../utils/postLoadFetchData"
import { oembed } from "@loomhq/loom-embed"
import { Navigate, useNavigate } from "react-router-dom"
const spinner = require('../../assets/icons/spinner.png');
const investment = require('./../../assets/onboarding/investing.svg').default;
const savings = require('./../../assets/onboarding/savings.svg').default;
const insurance = require('./../../assets/onboarding/insurance.svg').default;
const loans = require('./../../assets/onboarding/loans.svg').default;
const arrow = require('./../../assets/icons/arrow-fill.svg').default;

const UserContext = createContext()

// env
const running_env = process.env.REACT_APP_RUNNING_ENV;
var apiBaseURL = '';

if (running_env === 'development') {
    apiBaseURL = process.env.REACT_APP_API_URL_DEV;
} else if (running_env === 'production') {
    apiBaseURL = process.env.REACT_APP_API_URL_PROD;
} else if (running_env === 'local') {
    apiBaseURL = process.env.REACT_APP_API_URL_LOCAL;
}

function selectImage(tag) {
    switch (tag) {
        case "loans":
            return loans;
            break;
        case "insurance":
            return insurance;
            break;
        case "investing":
            return investment;
            break;
        case "savings":
            return savings;
            break;
    }
}

function LoadHierarchy() {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const email = useContext(UserContext);

    if (!isAuthenticated) return <div>Loading product matches...</div>; // Todo: Add a loading component

    return (
        (user && isAuthenticated) && <>
            <div>
                <Sidebar user={user}></Sidebar>
                <UserCard user={user} />
                <div className="app-container">
                    <div className="w-full lg:w-[1020px] mx-auto">
                        <Headings title='Financial Hierarchy' icon={hierarchy} />
                        <p className="ml-[105px] mt-[35px] mr-[60px] mb-[93px] paragraph-1">
                            This is what you came for! Based on the goals and information you inputted during onboarding, these are the types of products we recommend in order to help improve your financial wellness. Click on each product type to discover the existing fintech products we recommend.
                        </p>
                        <div className="triangle absolute mt-[6px]"></div>
                        <Recommendations email={email}></Recommendations>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    )

}

function Recommendations() {
    const [embed, setEmbed] = useState(null)
    const [html, setHtml] = useState(null)
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const email = useContext(UserContext);
    const [productMatches, setProductMatches] = useState(null);
    const baseURL = apiBaseURL;
    const urlproduct = `${baseURL}product_catalog/matching_products/?email=${email}`;
    const navigate = useNavigate();
    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently();
                // console.log("Got new token for function: " + token)
                // Execute the api method by calling the function
                const products = await postLoadFetchData(urlproduct, token);
                setProductMatches(products);
                console.log('data: ', products);
                console.log('user', user);

            } catch (err) {
                console.log(err);
            }
        })();
    }, []);

    if (!productMatches) return <div><img src={spinner} className="animate-spin mx-auto" alt="Loading"></img></div>; // Todo: Add a loading component

    return (
        (user && productMatches && isAuthenticated) && <>
            <div className="relative flex-col content-center max-w-[853px] mx-auto">
                <div className="flex flex-row justify-center lg:mb-[180px] mb-[60px]">
                    <div className="w-[366px] relative">
                        <div className="w-full rounded-[20px] bg-white relative bottom-0 shadow-[0px_3px_12px_rgba(0,0,0,0.08)] text-left cursor-pointer z-[3] py-[30px] pl-[30px]" onClick={() => navigate("/product-matches", { state: productMatches?.hierarchy[0]?.bucket_name })}>
                            <img src={selectImage(productMatches?.hierarchy[0]?.tag)} className="absolute z-[1] w-[224px] opacity-50 top-[5px] right-[18px]"></img>
                            <img src={arrow} alt="Arrow" className="absolute z-[1] bottom-[30px] right-[15px]"></img>
                            <div className="relative z-[2]">
                                <div className='accent-callout brand-gold mb-[8px] mt-[37px]'>{productMatches?.hierarchy[0]?.tag ? productMatches.hierarchy[0].tag.toUpperCase() : "UNKNOWN"}</div>
                                <div className="bucket-name midnight text-left">
                                    {productMatches?.hierarchy[0]?.display_name ? productMatches?.hierarchy[0]?.display_name : "UNKNOWN"}
                                </div>
                                <div className="neutral-600 paragraph-1-mobile max-w-[300px] mt-[16px] max-w-[285px]">{productMatches?.hierarchy[0]?.description ? productMatches.hierarchy[0].description : "UNKNOWN"}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:flex flex-row justify-between hidden">
                    <div className="w-[366px] relative">
                        <div className="w-full rounded-[20px] bg-white relative bottom-0 shadow-[0px_3px_12px_rgba(0,0,0,0.08)] text-left cursor-pointer z-[3] py-[30px] pl-[30px]" onClick={() => navigate("/product-matches", { state: productMatches?.hierarchy[1]?.bucket_name })}>
                            <img src={selectImage(productMatches?.hierarchy[1]?.tag)} className="absolute z-[1] w-[224px] opacity-50 top-[5px] right-[18px]"></img>
                            <img src={arrow} alt="Arrow" className="absolute z-[1] bottom-[30px] right-[15px]"></img>
                            <div className="relative z-[2]">
                                <div className='accent-callout brand-gold mb-[8px] mt-[37px]'>{productMatches?.hierarchy[1]?.tag ? productMatches.hierarchy[1].tag.toUpperCase() : "UNKNOWN"}</div>
                                <div className="bucket-name midnight text-left">
                                    {productMatches?.hierarchy[1]?.display_name ? productMatches?.hierarchy[1]?.display_name : "UNKNOWN"}
                                </div>
                                <div className="neutral-600 paragraph-1-mobile max-w-[300px] mt-[16px] max-w-[285px]">{productMatches?.hierarchy[1]?.description ? productMatches.hierarchy[1].description : "UNKNOWN"}</div>
                            </div>
                        </div>
                    </div>
                    <div className="w-[366px] relative">
                        <div className="w-full rounded-[20px] bg-white relative bottom-0 shadow-[0px_3px_12px_rgba(0,0,0,0.08)] text-left cursor-pointer z-[3] py-[30px] pl-[30px]" onClick={() => navigate("/product-matches", { state: productMatches?.hierarchy[2]?.bucket_name })}>
                            <img src={selectImage(productMatches?.hierarchy[2]?.tag)} className="absolute z-[1] w-[224px] opacity-50 top-[5px] right-[18px]"></img>
                            <img src={arrow} alt="Arrow" className="absolute z-[1] bottom-[30px] right-[15px]"></img>
                            <div className="relative z-[2]">
                                <div className='accent-callout brand-gold mb-[8px] mt-[37px]'>{productMatches?.hierarchy[2]?.tag ? productMatches.hierarchy[2].tag.toUpperCase() : "UNKNOWN"}</div>
                                <div className="bucket-name midnight text-left">
                                    {productMatches?.hierarchy[2]?.display_name ? productMatches?.hierarchy[2]?.display_name : "UNKNOWN"}
                                </div>
                                <div className="neutral-600 paragraph-1-mobile max-w-[300px] mt-[16px] max-w-[285px]">{productMatches?.hierarchy[2]?.description ? productMatches.hierarchy[2].description : "UNKNOWN"}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row lg:gap-[61px] gap-[14px] lg:hidden justify-center'>
                    <div className='w-[193px] h-[143px] shadow-[0px_3px_12px_rgba(0,0,0,0.08)] rounded-[10px] py-[25px] px-[20px] relative cursor-pointer overflow-clip bg-white' onClick={() => navigate("/product-matches", { state: productMatches?.hierarchy[1]?.bucket_name })}>
                        <img src={selectImage(productMatches?.hierarchy[1]?.tag)} className="absolute z-[1] w-[143px] opacity-50 top-[5px] right-[-20px]"></img>
                        <img src={arrow} alt="Arrow" className="absolute z-[1] bottom-[20px] right-[15px]"></img>
                        <div className='relative z-[2]'>
                            <div className='accent-callout brand-gold'>{productMatches?.hierarchy[1]?.tag ? productMatches.hierarchy[1].tag.toUpperCase() : "UNKNOWN"}</div>
                            <div className='subtitle-cards max-w-[125px] mt-[10px]'>{productMatches?.hierarchy[1]?.display_name ? productMatches?.hierarchy[1]?.display_name : "UNKNOWN"}</div>
                        </div>

                    </div>
                    <div className='w-[193px] h-[143px] shadow-[0px_3px_12px_rgba(0,0,0,0.08)] rounded-[10px] py-[25px] px-[20px] relative cursor-pointer overflow-clip bg-white' onClick={() => navigate("/product-matches", { state: productMatches?.hierarchy[2]?.bucket_name })}>
                        <img src={selectImage(productMatches?.hierarchy[2]?.tag)} className="absolute z-[1] w-[143px] opacity-50 top-[5px] right-[-20px]"></img>
                        <img src={arrow} alt="Arrow" className="absolute z-[1] bottom-[20px] right-[15px]"></img>
                        <div className='relative z-[2]'>
                            <div className='accent-callout brand-gold'>{productMatches?.hierarchy[2]?.tag ? productMatches.hierarchy[2].tag.toUpperCase() : "UNKNOWN"}</div>
                            <div className='subtitle-cards max-w-[125px] mt-[10px]'>{productMatches?.hierarchy[2]?.display_name ? productMatches?.hierarchy[2]?.display_name : "UNKNOWN"}</div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )

}

const Hierarchy = () => {
    const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    isAuthenticated && console.log(user);
    if (isLoading) return <div>Loading...</div>; // Todo: Add a loading component

    // Redirect to home if not authenticated
    !isAuthenticated && loginWithRedirect()

    return (
        // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
        isAuthenticated && <UserContext.Provider value={user.email}><LoadHierarchy email={user.email} /></UserContext.Provider>
    )
}


export default Hierarchy