import React, { createContext, useContext, useState } from 'react';
import InternalFooter from './Footer';
import { useNavigate, useLocation } from 'react-router-dom';
import postLoadSaveData from '../../utils/postLoadSaveData';
import { useAuth0 } from '@auth0/auth0-react';
const logo = require('../../assets/logo-color.png');
const carpool = require('../../assets/icons/carpool-gold.svg').default;
const food_delivery = require('../../assets/icons/food-delivery-gold.svg').default;
const task = require('../../assets/icons/task-gold.svg').default;
const fixing = require('../../assets/icons/fixing-gold.svg').default;
const package_box = require('../../assets/icons/package-gold.svg').default;
const food = require('../../assets/icons/food-gold.svg').default;
const dog_walking = require('../../assets/icons/dog-walking-gold.svg').default;
const hand_key = require('../../assets/icons/hand-key-gold.svg').default;
const clipboard = require('../../assets/icons/clipboard.svg').default;
const carpool_white = require('../../assets/icons/carpool-white.svg').default;
const food_delivery_white = require('../../assets/icons/food-delivery-white.svg').default;
const task_white = require('../../assets/icons/task-white.svg').default;
const fixing_white = require('../../assets/icons/fixing-white.svg').default;
const package_box_white = require('../../assets/icons/package-white.svg').default;
const food_white = require('../../assets/icons/food-white.svg').default;
const dog_walking_white = require('../../assets/icons/dog-walking-white.svg').default;
const hand_key_white = require('../../assets/icons/hand-key-white.svg').default;
const clipboard_white = require('../../assets/icons/clipboard-white.svg').default;


const cards = [{
  label: "Uber/Lyft",
  icon: carpool,
  icon_white: carpool_white,
  action1: "#"
},
{
  label: "Uber Eats/ DoorDash/ GrubHub",
  icon: food_delivery,
  icon_white: food_delivery_white,
  action1: "#"
},
{
  label: "Task Rabbit",
  icon: task,
  icon_white: task_white,
  action1: "#"
},
{
  label: "Thumbtack",
  icon: fixing,
  icon_white: fixing_white,
  action1: "#"
},
{
  label: "Amazon",
  icon: package_box,
  icon_white: package_box_white,
  action1: "#"
},
{
  label: "Instacart",
  icon: food,
  icon_white: food_white,
  action1: "#"
},
{
  label: "Raver/Wag",
  icon: dog_walking,
  icon_white: dog_walking_white,
  action1: "#"
},
{
  label: "Turo",
  icon: hand_key,
  icon_white: hand_key_white,
  action1: "#"
},
{
  label: "Other",
  icon: clipboard,
  icon_white: clipboard_white,
  action1: "#"
}
]
const UserContext = createContext()
const LoadGigWork = () => {
  const email = useContext(UserContext);
  return (
    <>
      <div className='w-screen m-0'>
        <Fields email={email}></Fields>
        <InternalFooter></InternalFooter>
      </div>



    </>
  )
}

function Fields(props) {
  const { user } = useAuth0();
  const navigate = useNavigate();

  function submit() {
    let elements = [];
    for (let i = 0; i < cards.length; i++) {
      if (checkedState[i]) {
        elements.push(cards[i].label)
      }
    }
    payload.gig_work = elements.toString();
    console.log(payload)

    navigate("/property", { state: payload })
  };

  // Post data on button click END  

  const location = useLocation();
  let payload = location.state;

  // Checkboxes
  const [checkedState, setCheckedState] = useState(
    () => {
      if ((payload.gig_work != "NA")) {
        console.log('test exists')
        const initial = new Array(cards.length).fill(false);
        const array = payload.gig_work.split(',');
        console.log(array)
        const indexArray=cards.map((card, index)=>{
          if (array.includes(card.label)) {
            return true
          }
          return false
        })
        console.log(indexArray)
        return indexArray;
      } else {
        console.log('doesnt exist')
        return new Array(cards.length).fill(false)
      }
    }
  );
  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };
  // Checkboxes END

  const [cardActive, setCardActive] = useState(null);


  return (
    (user) && <>
      <form onSubmit={submit}>
        <div className='flex flex-row min-h-[85vh]'>
          <nav className="hidden lg:flex flex-col flex-nowrap bg-white basis-[312px] shrink-0 grow-0">
            <img src={logo} className="w-[264.15px] h-[65px] mt-[41px] self-center" alt="KairosLogo" />
            <div className="onboarding-bar w-[30px] h-[266px] rounded-full mt-[75px] ml-[59px]">
              <div className="bar-progress w-[30px] h-[30px] rounded-full"><div className="bar-dot w-[10px] h-[10px] rounded-full top-[10px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Tell Us About Yourself</p></div></div>
              <div className="bar-dot w-[10px] h-[10px] rounded-full top-[123px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Set Your Goals</p></div>
              <div className="bar-dot w-[10px] h-[10px] rounded-full top-[240px] left-[10px]"><p className='bar-text top-[-10px] left-[37px]'>Connect Your Accounts</p></div>
            </div>
          </nav>
          <div className='bg-neutral-25 pb-[70px] w-[100%]'>
            <div className='mt-[50px] lg:mt-[106px] mb-[50px] lg:mb-[100px] mx-[30px] lg:mx-[109px]'>
              <h1 className='text-center'>What gig work do you do?</h1>
              <p className='paragraph-1 text-center'>How are you making moolah right now? You’ll be able to update this any time things change.</p>
            </div>
            <div className='max-[429px]:grid-cols-1 grid grid-cols-2 md:grid-cols-3 lg:gap-8 mx-auto lg:w-[798px] w-full lg:px-0 px-[46px] gap-y-[25px] gap-x-[25px]'>
              {cards.map((card, index) => (
                <div key={index} onMouseEnter={() => { setCardActive(index); console.log(cardActive) }} onMouseLeave={() => setCardActive(null)}>
                  <input id={index} className='hidden' type="checkbox" value={card.label} name={'selection-' + index} checked={checkedState[index]} onChange={() => { handleOnChange(index) }} />
                  <label htmlFor={index}>
                    <div className='onboarding-card mx-auto' style={{ backgroundColor: checkedState[index] === true ? "rgba(247, 189, 132, 0.8)" : "" }}>
                      <img src={((checkedState[index] === true) || (cardActive === index)) ? card.icon_white : card.icon} className="w-[60px] h-[60px] lg:w-[80px] lg:h-[80px] mx-auto" alt={card.label} />
                      <p className='card-label mx-auto'>{card.label}</p>
                    </div>
                  </label>
                </div>

              ))}
            </div>




          </div>
        </div>

        <div className='flex flex-col-reverse lg:flex-row justify-between align-middle py-[37px] px-[119px] top-shadow'>
          <button className='text-only-button mt-[15px] lg:mt-0' type='button' onClick={() => navigate(-1)}>Go Back</button>
          <button className='gold-button' disabled={!checkedState.includes(true)} type='button' onClick={() => submit()}>Next</button>
        </div>
      </form>

    </>
  )

}


const GigWork = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  isAuthenticated && console.log(user);

  // Redirect to home if not authenticated
  !isLoading && !isAuthenticated && loginWithRedirect()

  return (
    // Check if user is authenticated. If so, mount the Switch component that redirects to the proper onboarding page
    isAuthenticated && <UserContext.Provider value={user.email}><LoadGigWork email={user.email} /></UserContext.Provider>
  )
}

export default GigWork